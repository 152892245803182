// import PartialModel from '@libs/lila-model';

// export default class TextblockModel extends PartialModel {

//   headline: string = '';

//   subline: string = '';

//   intro: string = '';

//   text: string[] = [];

// }

import Models, { ModelDeclaration, ModelFunctions } from '@libs/Models.class';


export default interface Textblock {
  headline: string
  subline: string
  intro: string
  text: string[]
}

const declaration: ModelDeclaration<Textblock> = {
  headline: { type: 'string' },
  subline: { type: 'string' },
  intro: { type: 'string' },
  text: { type: 'array', contains: { type: 'string' } },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};

Models.register('textblock', declaration, functions);