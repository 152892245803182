// import PartialModel from '@libs/lila-model';
// import SelectedFileModel from './SelectedFile.model';
// import VideoSourceModel from './VideoSource.model';

// export default class VideoPartialModel extends PartialModel {

//     src: string = '';

//     alt: string = '';

//     source1 = VideoSourceModel;

//     source2 = VideoSourceModel;

//     source3 = VideoSourceModel;

//     attributes: string[] = [];

//     file = SelectedFileModel;

//     poster: string = '';

// }

import Models, { ModelDeclaration } from '@libs/Models.class';
import VideoSource from './VideoSource.model';
import SelectedFile from './SelectedFile.model';

export default interface VideoPartial {
  src: string
  alt: string
  poster: string
  attributes: string[]
  source1: VideoSource
  source2: VideoSource
  source3: VideoSource
  file: SelectedFile
}

const declaration: ModelDeclaration<VideoPartial> = {
  src: { type: 'string' },
  alt: { type: 'string' },
  poster: { type: 'string' },
  source1: { model: 'video-source' },
  source2: { model: 'video-source' },
  source3: { model: 'video-source' },
  file: { model: 'selected-file' },
  attributes: { type: 'array', contains: { type: 'string' } },
};

Models.register('video-partial', declaration);