// import PartialModel from '@libs/lila-model';
// import TextblockModel from '@models/Textblock.model';
// import LinkPartialModel from './Link.model';

// export default class PricesElementModel extends PartialModel {

//   textblock = TextblockModel;

//   facts: string[] = [];

//   price: string = '';

//   interval: string = '';

//   variant: string[] = [];

//   callToAction = LinkPartialModel;

//   more = LinkPartialModel;

// }

import Models, { ModelDeclaration } from '@libs/Models.class';
import GenericModel from '@interfaces/GenericModel.interface';
import Link from './Link.model';
import Textblock from './Textblock.model';


export default interface PricesElement extends GenericModel {
  price: string
  interval: string
  facts: string[]
  variant: string[]
  textblock: Textblock
  callToAction: Link
  more: Link

}

const declaration: ModelDeclaration = {
  price: { type: 'string' },
  interval: { type: 'string' },
  textblock: { model: 'textblock' },
  callToAction: { model: 'link' },
  more: { model: 'link' },

  facts: { type: 'array', contains: { type: 'string' } },
  variant: { type: 'array', contains: { type: 'string' } },
};

Models.register('prices-element', declaration);
