// import modules from '@apps/editor/editor-modules';
// import ModuleModel from '@models/Module.model';

// export default class PlaceholderModuleModel extends ModuleModel {

//   type: keyof typeof modules = 'placeholder-module';

//   uuid = true;

//   hint = '';

//   hintData = '';

// }

import Models, { ModelDeclarationExtended, ModelFunctions, ModelOptions } from '@libs/Models.class';
import Module from '@models/Module.model';

export default interface PlaceholderModule extends Module {
  hint: string
  hintData: string
}

const declaration: ModelDeclarationExtended<PlaceholderModule, Module> = {
  hint: { type: 'string' },
  hintData: { type: 'string' },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('placeholder-module', declaration, functions, options);