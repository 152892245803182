<template>
  <div id="app" :class="[$enviroment]">
    <!-- <transition mode="out-in" name="content"> -->
    <router-view :key="`module-${$route.params.link}`" />
    <!-- </transition> -->

    <portal-target name="overlay" multiple />
    <portal-target name="util" multiple />
    <loading-partial v-if="loading" />
  </div>
</template>

<script lang="ts">
import { ExtComponent, Component, Prop } from '@libs/lila-component';

@Component
export default class appComponentPartial extends ExtComponent {

  get loading() {

    return this.$store.state.Navigation.status === 'loading';

  }

}
</script>
