export default {
  'app-media': 'Medien',
  'app-media-no-preview-filetype': 'Keine Vorschau für diesen Dateityp möglich.',
  'app-media-permissions-read': 'Medien anzeigen',
  'app-media-permissions-create': 'Medien hinzufügen',
  'app-media-permissions-edit': 'Medien bearbeiten',
  'app-media-permissions-remove': 'Medien entfernen',
  'app-media-permissions-history': 'Historie anzeigen',
  'app-media-file-linked': 'Diese Datei basiert auf',
  'app-media-view-in-app': 'In Medien anzeigen',
  'app-media-old-version': 'Der Datensatz ist neuer als die verlinkte Datei.',
  'app-media-more-version': 'Es existieren weitere Dateien (%s) zu diesem Datensatz',
  'app-media-base-file-description': 'Die nachfolgenden Dateien basieren auf diesem Datensatz',
};
