// import PartialModel from '@libs/lila-model';
// import SelectedFileModel from '@models/SelectedFile.model';

// export default class PictureSourceModel extends PartialModel {

//   media: string = '';

//   src: string = '';

//   file = SelectedFileModel;

// }

import Models, { ModelDeclaration, ModelFunctions } from '@libs/Models.class';
import SelectedFile from './SelectedFile.model';

export default interface PictureSource {
  media: string
  src: string
  file: SelectedFile
}

const declaration: ModelDeclaration<PictureSource> = {
  media: { type: 'string' },
  src: { type: 'string' },
  file: { model: 'selected-file' },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};

Models.register('picture-source', declaration, functions);