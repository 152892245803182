// import PartialModel from '@libs/lila-model';
// import AccordionElementModel from './AccordionElement.model';

// export default class AccordionModel extends PartialModel {

//   openOnStart: string = '';

//   multiOpen: boolean = false;

//   disableControls: boolean = false;

//   elements = [AccordionElementModel];

// }
import Models, { ModelDeclaration, ModelFunctions } from '@libs/Models.class';
import AccordionElement from './AccordionElement.model';

export default interface Accordion {
  openOnStart: string
  multiOpen: boolean
  disableControls: boolean
  elements: AccordionElement[]
}

const declaration: ModelDeclaration<Accordion> = {
  openOnStart: { type: 'string' },
  multiOpen: { type: 'boolean', default: false },
  disableControls: { type: 'boolean', default: false },
  elements: { type: 'array', contains: { model: 'accordion-element' } },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};

Models.register('accordion', declaration, functions);