import GenericStore from '@interfaces/GenericStore.interface';
import MainStore, { state } from '@store/main.store';
import StudioSDK from '@libs/StudioSDK';
import { DataObject, Editor } from '@lilaquadrat/studio/lib/interfaces';
import hardCopy from '@mixins/hardCopy';
import AppLearnMainState from '../interfaces/main-state.interface';

const AppLearnStore: GenericStore<AppLearnMainState> = {

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
    };

  },

  namespaced: true,
  strict: true,

  state: {
    single: null,
    home: null,
    data: null,
    cache: null,
    footer: null,
    analyzedInput: {
      cache: '',
      search: '',
      words: [],
    },
  },

  mutations: {

    data(store, data: DataObject<Editor>) {

      store.data = data;

    },

    single(store, data: Editor) {

      store.single = data;

    },

    home(store, data: Editor) {

      store.home = data;

    },

    footer(store, data: Editor) {

      store.footer = data;

    },

    cache(store, data: { search: string, site?: number }) {

      store.cache = data;

    },

    searchCache(store, search: string) {

      store.searchCache = search;

    },

    analyzedInput(store, input: AppLearnMainState['analyzedInput']) {

      store.analyzedInput = input;

    },

  },

  actions: {
    /**
     * This action will be called, when the app will be activated
     */
    activate() {

      return Promise.resolve(true);

    },

    single(store, data: { link: string }) {

      const sdk = new StudioSDK('learn-app', AppLearnStore.sdkOptions());

      return sdk.content.fetch('lernen', data.link);


    },

    home(store) {

      const sdk = new StudioSDK('learn-app', AppLearnStore.sdkOptions());

      if (store.state.home) return Promise.resolve();

      return sdk.content.fetch('lernen', 'searchHome')
        .then((response) => store.commit('home', response.data));


    },

    footer(store) {

      const sdk = new StudioSDK('learn-app', AppLearnStore.sdkOptions());

      if (store.state.footer) return Promise.resolve();

      return sdk.content.fetch('lernen', 'searchFooter')
        .then((response) => store.commit('footer', response.data));


    },

    async search(store, data: { search: string, site?: number }) {

      const searchTrimmed = data.search?.trim();
      const sdk = new StudioSDK('learn-app', AppLearnStore.sdkOptions());

      console.log(store.state.cache?.search, store.state.cache?.site === data.site, searchTrimmed);

      if ((store.state.cache?.search === searchTrimmed && store.state.cache?.site === data.site) || !searchTrimmed?.length) return Promise.resolve();

      return sdk.content.search('lernen', searchTrimmed, data.site)
        .then((searchData) => {

          store.commit('cache', { search: searchTrimmed, site: data.site });

          return searchData;

        });

    },

    reset(store) {

      store.commit('data', {});
      store.commit('cache', {});
      store.commit('analyzedInput', { words: [], cache: '', search: '' });

    },

    analyzeInput(store, input: string) {

      const newCache = input?.length ? input?.trim() : store.state.analyzedInput.cache;
      const analyzedInput = {
        words: input?.split(' ').map((single) => single.trim()).filter((single) => single.length) || [],
        cache: newCache || '',
        search: input?.trim() || '',
      };

      store.commit('analyzedInput', analyzedInput);

    },

    addTags(store, tags: string[]) {

      const analyzedInputCache = hardCopy(store.state.analyzedInput);

      if (!analyzedInputCache?.words.length) {

        analyzedInputCache.search = tags.join(' ');

      }

      if (analyzedInputCache?.words) {

        const wordsArray = [...new Set([...analyzedInputCache?.words, ...tags])];


        analyzedInputCache.search = wordsArray.join(' ') || '';
        analyzedInputCache.words = wordsArray || [];
        analyzedInputCache.cache = analyzedInputCache.search;

      }

      store.commit('analyzedInput', analyzedInputCache);

    },

    replaceTags(store, tags: string[]) {

      const analyzedInputCache = {
        search: '',
        cache: '',
        words: [],
      };

      analyzedInputCache.search = tags.join(' ') || '';
      analyzedInputCache.words = tags || [];
      analyzedInputCache.cache = analyzedInputCache.search;

      store.commit('analyzedInput', analyzedInputCache);
      store.commit('suggestions', []);

    },

  },

};

export default AppLearnStore;
