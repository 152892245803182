import Models, { ModelDeclaration } from '@libs/Models.class';
import Textblock from './Textblock.model';
import MenuItemElement from './MenuItemElement.model';

export default interface MenuCategoryElement {
  textblock: Textblock
  items: MenuItemElement[]
}

const declaration: ModelDeclaration<MenuCategoryElement> = {
  textblock: { model: 'textblock' },
  items: { type:'array', contains: { model: 'menu-item-element'} },
};


Models.register('menu-category-element', declaration);