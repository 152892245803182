import { UnifiedRouteGuard } from '@libs/lila-auth';

const loadScreen = (screen: string) => () => import(
  /* webpackChunkName: "app-history" */
  `./screens/${screen}`
).then((m) => m.default);

export default [
  {
    path: '/:company/a/history/:site?',
    name: 'app-history-company-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home.screen.vue'),
    meta: {
      app: 'history',
    },
  },
  {
    path: '/:company/:project/a/history/:site?',
    name: 'app-history-project-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home.screen.vue'),
    meta: {
      app: 'history',
    },
  },
];
