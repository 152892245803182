import InstallableApp from '@interfaces/InstallableApp.interface';
import LoadElements from '@mixins/loadElements';
import routes from './routes';
import translations from './translations/translations';

import './models/Tracker.model';

LoadElements.appComponents(require.context('./partials', true, /[a-z]\w+\.partial\.(ts|vue)$/, 'lazy'), 'partials', 'tracker');
LoadElements.appComponents(require.context('./modules', true, /[a-z-]\w+\.module\.(editor\.)?vue$/, 'lazy'), 'modules', 'tracker');
LoadElements.extensions(require.context('./extensions', true, /[a-z-]\w+\.extension\.(ts|vue)$/, 'lazy'), 'tracker');

const app: InstallableApp = {
  name: 'tracker',
  publisher: 'lilaquadrat',
  translations,
  routes,
  store: 'AppTracker',
  overlay: [
    'overlay-tracker-ext'
  ]
};

export default app;
