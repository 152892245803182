// import PartialModel from '@libs/lila-model';
// import LinkPartialModel from './Link.model';
// import ListLinkModel from './ListLink.model';
// import ListStringModel from './ListString.model';
// import PicturePartialModel from './PicturePartialModel';
// import TextblockModel from './Textblock.model';

// export default class PicturegroupElementModel extends PartialModel {

//   picture = PicturePartialModel;

//   link = LinkPartialModel;

//   textblock = TextblockModel;

//   list = ListStringModel;

//   links = ListLinkModel;

// }


import Models, { ModelDeclaration } from '@libs/Models.class';
import GenericModel from '@interfaces/GenericModel.interface';
import Link from './Link.model';
import PicturePartial from './PicturePartial.model';
import Textblock from './Textblock.model';
import ListLink from './ListLink.model';
import ListString from './ListString.model';


export default interface PicturegroupElement extends GenericModel {
  picture: PicturePartial
  link: Link
  textblock: Textblock
  list: ListString
  links: ListLink
}

const declaration: ModelDeclaration<PicturegroupElement> = {
  picture: { model: 'picture-partial' },
  link: { model: 'link' },
  textblock: { model: 'textblock' },
  list: { model: 'list-string' },
  links: { model: 'list-link' },
};

Models.register('picturegroup-element', declaration);
