import vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import inview from '@libs/lila-inview';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
@Component({
  inheritAttrs: false,
})
abstract class ExtPartial extends vue {

  child: string = '';

  @Prop({ type: Array, default: () => [] }) variant: string[];


  // eslint-disable-next-line class-methods-use-this
  $categories(value: string[]) {

    const returnValue = [];

    value?.forEach((single) => {

      if (!single.match('^category:')) return;

      returnValue.push(single.slice(9));

    });

    return returnValue.join(', ');

  }


  unsetCurrentChild() {

    this.child = '';

  }


}

export {
  Component,
  ExtPartial,
  Prop,
  Watch,
  inview,
  vue,
};
