import GenericStore from '@interfaces/GenericStore.interface';
import MainStore, { state } from '@store/main.store';
import StudioSDK, { SDKResponse } from '@libs/StudioSDK';
import { DataObject, Editor } from '@lilaquadrat/studio/lib/interfaces';
import Vue from 'vue';
import hardCopy from '@mixins/hardCopy';
import AppLearnIntegratedState from '../interfaces/integrated-state.interface';

const AppLearnStore: GenericStore<AppLearnIntegratedState> = {

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
    };

  },

  namespaced: true,
  strict: true,

  state: {
    single: null,
    home: null,
    data: null,
    cache: null,
    footer: null,
    analyzedInput: {
      cache: '',
      search: '',
      words: [],
    },
    tabs: [],
    suggestions: [],
    activeTab: null,
    open: false,
    integratedViewMode: 'full',
    integratedShowType: 'search',
    searchCache: null,
  },

  mutations: {

    data(store, data: DataObject<Editor>) {

      store.data = data;

    },

    single(store, data: Editor) {

      store.single = data;

    },

    home(store, data: Editor) {

      store.home = data;

    },

    footer(store, data: Editor) {

      store.footer = data;

    },

    open(store, open: boolean) {

      store.open = open;

    },

    integratedViewMode(store, mode: 'sidebar' | 'full') {

      store.integratedViewMode = mode;

    },

    integratedShowType(store, mode: 'search' | 'content') {

      store.integratedShowType = mode;

    },

    searchCache(store, search: string) {

      store.searchCache = search;

    },

    cache(store, data: { search: string, site?: number }) {

      store.cache = data;

    },

    activeTab(store, tabId: number) {

      store.activeTab = tabId;

    },


    addTab(store, url?: string) {


      store.tabs.push({
        title: url,
        state: 0,
        url,
        content: null,
      });

    },

    tabContent(store, data: { url: string, content: SDKResponse<Editor> }) {

      let baseTab = store.tabs.find((single) => single.url === data.url);
      const baseIndex = store.tabs.findIndex((single) => single.url === data.url);

      baseTab = {
        url: data.url,
        content: data.content.data || null,
        state: data.content.status,
        title: data.content.data?.settings?.title,
      };

      console.log(store.tabs, baseTab);
      Vue.set(store.tabs, baseIndex, baseTab);

    },

    closeTab(store, index: number) {

      Vue.delete(store.tabs, index);

    },

    analyzedInput(store, input: AppLearnIntegratedState['analyzedInput']) {

      store.analyzedInput = input;

    },

    suggestions(store, suggestions: string[]) {

      store.suggestions = suggestions;

    },


  },

  actions: {
    /**
     * This action will be called, when the app will be activated
     */
    activate() {

      return Promise.resolve(true);

    },

    single(store, data: { link: string }) {

      const sdk = new StudioSDK('learn-app', AppLearnStore.sdkOptions());

      return sdk.content.fetch('lernen', data.link);


    },

    home(store) {

      const sdk = new StudioSDK('learn-app', AppLearnStore.sdkOptions());

      if (store.state.home) return Promise.resolve();

      return sdk.content.fetch('lernen', 'searchHome')
        .then((response) => store.commit('home', response.data));


    },

    footer(store) {

      const sdk = new StudioSDK('learn-app', AppLearnStore.sdkOptions());

      if (store.state.footer) return Promise.resolve();

      return sdk.content.fetch('lernen', 'searchFooter')
        .then((response) => store.commit('footer', response.data));


    },

    async search(store, data: { search: string, site?: number }) {

      const searchTrimmed = data.search?.trim();
      const sdk = new StudioSDK('learn-app', AppLearnStore.sdkOptions());

      console.log(store.state.cache?.search, store.state.cache?.site === data.site, searchTrimmed);

      if ((store.state.cache?.search === searchTrimmed && store.state.cache?.site === data.site) || !searchTrimmed?.length) return Promise.resolve();

      return sdk.content.search('lernen', searchTrimmed, data.site)
        .then((searchData) => {

          store.commit('cache', { search: searchTrimmed, site: data.site });

          return searchData;

        });

    },

    reset(store) {

      store.commit('data', {});
      store.commit('cache', {});
      store.commit('analyzedInput', { words: [], cache: '', search: '' });

    },

    toggle(store, open: boolean) {

      store.commit('open', open);

    },

    setIntegratedViewMode(store, mode: 'sidebar' | 'full') {

      store.commit('integratedViewMode', mode);

    },

    closeTab(store, index: number) {

      const tabsCount = store.state.tabs.length;
      const activeTab = store.state.activeTab;
      let newActiveTab: number;

      // if the current active tab gets closed
      if (activeTab === index) {

        // if last
        if (tabsCount === index + 1) {

          newActiveTab = tabsCount - 2;

        } else {

          // if the closed tab is not the last, no change need
          newActiveTab = activeTab;

        }

      } else if (index < activeTab) {

        // if the closed tab is before the current, index needs to be reduced in order to stay on the current tab
        newActiveTab = activeTab - 1;

      } else {

        // if the closed tab is after the current, no change needed
        newActiveTab = activeTab;

      }

      store.commit('closeTab', index);
      store.commit('activeTab', newActiveTab);

    },

    analyzeInput(store, input: string) {

      const newCache = input?.length ? input?.trim() : store.state.analyzedInput.cache;
      const analyzedInput = {
        words: input?.split(' ').map((single) => single.trim()).filter((single) => single.length) || [],
        cache: newCache || '',
        search: input?.trim() || '',
      };

      store.commit('analyzedInput', analyzedInput);

    },

    addTags(store, tags: string[]) {

      const analyzedInputCache = hardCopy(store.state.analyzedInput);

      if (!analyzedInputCache?.words.length) {

        analyzedInputCache.search = tags.join(' ');

      }

      if (analyzedInputCache?.words) {

        const wordsArray = [...new Set([...analyzedInputCache?.words, ...tags])];


        analyzedInputCache.search = wordsArray.join(' ') || '';
        analyzedInputCache.words = wordsArray || [];
        analyzedInputCache.cache = analyzedInputCache.search;

      }

      store.commit('analyzedInput', analyzedInputCache);

    },

    replaceTags(store, tags: string[]) {

      const analyzedInputCache = {
        search: '',
        cache: '',
        words: [],
      };

      analyzedInputCache.search = tags.join(' ') || '';
      analyzedInputCache.words = tags || [];
      analyzedInputCache.cache = analyzedInputCache.search;

      store.commit('analyzedInput', analyzedInputCache);
      store.commit('suggestions', []);

    },

    updateSuggestions(store, suggestions: string[]) {

      const useSuggestions = suggestions.every((tag) => store.state.analyzedInput.words.includes(tag))
        ? []
        : suggestions;

      store.commit('suggestions', useSuggestions);

    },

    async addOrSelectTab(store, url: string) {

      const tabIndex = store.state.tabs.findIndex((single) => single.url === url && !!url);

      console.log(tabIndex, url);

      if (tabIndex > -1) {

        store.commit('activeTab', tabIndex);

      } else {

        store.commit('addTab', url);
        store.commit('activeTab', store.state.tabs.length - 1);

        const content = await store.dispatch('single', { link: url });

        store.commit('tabContent', { url, content });

      }

    },

  },

};

export default AppLearnStore;
