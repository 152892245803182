import Models, { ModelDeclaration } from '@libs/Models.class';
import { ListCategory } from '@lilaquadrat/studio/lib/interfaces';
import { EmailsContent, ListContent } from '@lilaquadrat/interfaces';
import Participants from './participants.model';
import Agreements from './agreements.model';


export default interface AppListsList {
  name: string
  description: string
  payment: string
  state: string
  mode: string
  // start: string
  // end: string
  participants: Participants;
  categories: ListCategory[];
  agreements: Agreements[];
  content: ListContent
  emails: EmailsContent
}

const declaration: ModelDeclaration<AppListsList> = {
  name: { type: 'string' },
  description: { type: 'string' },
  payment: { type: 'string' },
  state: { type: 'string' },
  mode: { type: 'string' },
  // start: { type: 'string' },
  // end: { type: 'array', contains: { model: string }, default?: any[] },
  participants: { model: 'app-lists-participants' },
  categories: { type: 'array', contains: { model: 'app-lists-categories' } },
  agreements: { type: 'array', contains: { model: 'app-lists-agreements' } },
  content: { model: 'app-lists-content' },
  emails: { model: 'app-lists-emails' },
};

Models.register('app-lists-list', declaration);