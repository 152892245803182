var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("article", { class: _vm.navigationAnimation }, [
    _c("h1", [_vm._v("ERROR")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }