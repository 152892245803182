import GenericModel from '@interfaces/GenericModel.interface';
import Models, { ModelDeclaration } from '@libs/Models.class';
import PictureSource from '@models/PictureSource.model';
import SelectedFile from '@models/SelectedFile.model';

export default interface Media extends GenericModel {
  src: string
  alt: string
  type: string,
  copyright: string
  poster: string
  file: SelectedFile
  attributes: string[]
  source: PictureSource[]
}

const declaration: ModelDeclaration<Media> = {
  src: { type: 'string' },
  alt: { type: 'string' },
  type: { type: 'string' },
  copyright: { type: 'string' },
  file: { model: 'selected-file' },
  poster: { type: 'string' },
  attributes: { type: 'array', contains: { type: 'string' } },
  source: { type: 'array', contains: { model: 'picture-source' }, defaultItems: 3 },
};

Models.register('media', declaration);