import { CallResponse } from '@lilaquadrat/studio/lib/interfaces';
import Endpoints from './lila-endpoints';


export type AvailableTypes = 'PUT' | 'POST' | 'GET' | 'HEAD' | 'DELETE';

export default class Call<T> extends Endpoints {

  beforeSend: (req: XMLHttpRequest, data: T | FormData | Partial<unknown>, callback: () => void) => void;

  onprogress: (event) => void;

  fileUpload: boolean = false;

  app: string;

  /**
   *Creates an instance of Call.
   * @memberof Call
   */
  constructor(app: string, endpoints: Endpoint[]) {

    super();

    this.addEndpoints(endpoints);

    this.app = app;

  }

  options: {
    type: string,
    dataType: string,
  };

  head(endpoint: string, method: string, headers?: { [key: string]: string }) {

    return this.call(this.getUrl(endpoint, method), 'HEAD', undefined, headers);

  }

  get(endpoint: string, method: string, data?: Record<string, unknown>, headers?: { [key: string]: string }) {

    return this.call(this.getUrl(endpoint, method), 'GET', data, headers);

  }

  put<M>(endpoint: string, method: string, data: Partial<M>, headers?: { [key: string]: string }) {

    return this.call(this.getUrl(endpoint, method), 'PUT', data, headers);

  }

  post(endpoint: string, method: string, data: T, headers?: { [key: string]: string }) {

    return this.call(this.getUrl(endpoint, method), 'POST', data, headers);

  }

  delete(endpoint: string, method: string, headers?: { [key: string]: string }) {

    return this.call(this.getUrl(endpoint, method), 'DELETE', undefined, headers);

  }

  /**
   *
   *
   * @param {string} url
   * @param {string} type
   * @param {object} [data]
   * @returns
   * @memberof Call
   */
  call(url: string, type: AvailableTypes, data: T | FormData | undefined | Partial<unknown>, headers?: { [key: string]: string }):
    Promise<CallResponse<T>> {

    const req = new XMLHttpRequest();
    let response: string;
    let sendData: string | FormData;

    if (data) {

      if (data.constructor.name !== 'FormData') {

        if (type === 'GET') {

          sendData = Call.serializeObject(data, null);

        } else {

          sendData = JSON.stringify(data);

        }


      } else {

        sendData = data as FormData;
        this.fileUpload = true;

      }

    }


    const requestUrl = type === 'GET' && sendData
      ? `${url}?${sendData}`
      : url;
    const p = new Promise((resolve, reject) => {

      req.open(type, requestUrl);

      req.setRequestHeader('studio-app', this.app);

      if ((type === 'POST' || type === 'PUT') && data && !this.fileUpload) {

        req.setRequestHeader('Content-type', 'application/json');

      }

      if (headers) {

        Object.keys(headers).forEach((key: string) => {

          req.setRequestHeader(key, headers[key]);

        });

      }

      req.onreadystatechange = () => {

        if (req.readyState === 4) {

          response = req.getResponseHeader('Content-Type')
            ? Call.modifyContent(req.responseText, req.getResponseHeader('Content-Type'))
            : req.responseText;

          if (req.status >= 200 && req.status < 300) {

            resolve({ status: req.status, r: response });

          } else {

            // eslint-disable-next-line prefer-promise-reject-errors
            reject({ status: req.status, r: response });

          }

        }

      };

      if (this.onprogress) {

        req.onprogress = (event) => {

          this.onprogress(event);

        };

      }

      if (this.beforeSend) {

        this.beforeSend(req, data, () => {

          req.send(sendData);

        });

      } else {

        req.send(sendData);

      }

    });

    return p as Promise<CallResponse<T>>;

  }

  /**
   *
   *
   * @param {{[key: string]: any}} object
   * @param {string} [prefix]
   * @returns
   * @memberof Call
   */
  static serializeObject(object: { [key: string]: any }, prefix?: string): string {

    if (object === null || object === undefined) return '';

    return Object
      .keys(object)
      .map((key) => {

        let returnString: string;

        returnString = prefix
          ? `${prefix}[${key}]`
          : key;

        if (
          (Array.isArray(object[key]) && ['data[childData]'].includes(prefix))
          || (prefix === 'data' && key === 'modules')
        ) {

          if (object[key].length === 0) {

            returnString += '[0]=';
            return returnString;

          }

        }

        returnString = typeof (object[key]) === 'object'
          ? Call.serializeObject(object[key], returnString)
          : returnString += `=${encodeURIComponent(object[key])}`;

        if (returnString) return returnString;

        return null;

      })
      .filter((single) => single)
      .join('&');

  }

  /**
   *
   *
   * @param {string} endpoint
   * @param {string} method
   * @returns
   * @memberof Call
   */
  getUrl(endpoint: string, method: string) {

    if (!endpoint) return `${window.location.protocol}//${window.location.host}/${method}`;

    return this.endpoints[endpoint] ? this.endpoints[endpoint] + method : `${window.location.protocol}//${window.location.host}/${endpoint}${method}`;

  }

  /**
   *
   *
   * @param {string} content
   * @param {string} contentType
   * @returns
   * @memberof Call
   */
  static modifyContent(content: string, contentType: string) {

    let newContent: string;

    if (!content?.length) return content;

    if (contentType.match(/^application\/json/)) {

      newContent = JSON.parse(content);

    } else {

      newContent = content;

    }

    return newContent;

  }


}

export {
  Call,
};
