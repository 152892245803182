// import PartialModel from '@libs/lila-model';

// export default class ParticipantStateModel extends PartialModel {

//   uuid = false;

//   state = '';

// }


import Models, { ModelDeclaration } from '@libs/Models.class';

export default interface AppListsParticipantNote {
  note: string
}


const declaration: ModelDeclaration<AppListsParticipantNote> = {
  note: { type: 'string' },
};

Models.register('app-lists-participant-note', declaration);
