import { UnifiedRouteGuard } from '@libs/lila-auth';

const loadScreen = (screen: string) => () => import(
  /* webpackChunkName: "app-team" */
  `./screens/${screen}`
).then((m) => m.default);

export default [
  {
    path: '/:company/a/team',
    name: 'app-team-company-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home.screen.vue'),
    meta: {
      parent: true,
      app: 'team',
    },
    children: [
      {
        path: 'add',
        name: 'app-team-company-add',
        meta: {
          defaultRoute: 'app-team-company-add',
          app: 'team',
        },
        component: loadScreen('edit.screen'),
      },
      {
        path: 'edit/:id',
        name: 'app-team-company-edit',
        meta: {
          defaultRoute: 'app-team-company-edit',
          app: 'team',
        },
        component: loadScreen('edit.screen'),
      },
    ],
  },
  {
    path: '/:company/:project/a/team',
    name: 'app-team-project-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home.screen.vue'),
    meta: {
      parent: true,
      app: 'team',
    },
    children: [
      {
        path: 'add',
        name: 'app-team-project-add',
        meta: {
          defaultRoute: 'app-team-project-add',
          app: 'team',
        },
        component: loadScreen('edit.screen'),
      },
      {
        path: 'edit/:id',
        name: 'app-team-project-edit',
        meta: {
          defaultRoute: 'app-team-project-edit',
          app: 'team',
        },
        component: loadScreen('edit.screen'),
      },
    ],
  },
];
