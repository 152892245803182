// import PartialModel from '@libs/lila-model';

// export default class ListStringModel extends PartialModel {

//   title: string = '';

//   value: string[] = [];

// }
import Models, { ModelDeclaration, ModelFunctions } from '@libs/Models.class';

export default interface ListString {
  title: string
  value: string[]
}

const declaration: ModelDeclaration<ListString> = {
  title: { type: 'string' },
  value: { type: 'array', contains: { type: 'string' } },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};

Models.register('list-string', declaration, functions,);