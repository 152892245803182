// import ModuleModel from '@models/Module.model';
// import TextblockModel from '@models/Textblock.model';
// import modules from '@apps/editor/editor-modules';
// import PicturePartialModel from '@models/PicturePartialModel';

// export default class PictureModuleModel extends ModuleModel {

//   type: keyof typeof modules = 'picture-module';

//   uuid = true;

//   picture = PicturePartialModel;

//   textblock = TextblockModel;

// }

import Models, { ModelDeclarationExtended, ModelOptions } from '@libs/Models.class';
import Module from '@models/Module.model';
import PicturePartial from '@models/PicturePartial.model';
import Textblock from '@models/Textblock.model';

export default interface PictureModule extends Module {
  picture: PicturePartial
  textblock: Textblock
}


const declaration: ModelDeclarationExtended<PictureModule, Module> = {
  picture: { model: 'picture-partial' },
  textblock: { model: 'textblock' },
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('picture-module', declaration, {}, options);