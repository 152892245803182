export default {
  'app-hosting': 'hosting',
  'app-hosting-project-settings': 'Hosting Einstellungen',
  'app-hosting-project-settings-main': 'Allgemeine Einstellungen',
  'app-hosting-project-settings-overview': 'Einstellungen',
  'app-hosting-active': 'Hosting ist aktiv',
  'app-hosting-inactive': 'Hosting ist inaktiv',
  'app-hosting-activate': 'Hosting aktivieren',
  'app-hosting-deactivate': 'Hosting deaktvieren',
  'app-hosting-permissions-read': 'Hosting Details anzeigen',
  'app-hosting-permissions-toggle': 'Hosting aktivieren/deaktivieren',
  'app-hosting-permissions-edit': 'Hosting Details bearbeiten',
  'app-hosting-permissions-history': 'Historie anzeigen',
  'app-hosting-permissions-admin': 'Hosting Admin',
  'app-hosting-domains': 'Domains',
  'app-hosting-settings': 'Einstellungen',
  'app-hosting-settings-view': 'Zu den Einstellungen',
  'app-hosting-domains-view': 'Zu den Domains',
  'app-hosting-domain-active': 'aktiv',
  'app-hosting-domains-overview': 'Übersicht',
  'app-hosting-project-domains': 'Domains',
  'app-hosting-project-domains-single-edit': 'Domain bearbeiten',
  'app-hosting-project-domains-single-add': 'Domain hinzufügen',
  'app-hosting-domains-isDefault-title': 'System Standard Domain',
  'app-hosting-domains-isDefault-description': 'Jedes Projekt bekommt automatisch eine Domain zugewiesen um Hosting auch ohne Custom Domain nutzen zu können. Diese Domain kann deaktiviert werdenm, sobald eine Custom Domain hinzugefügt wurde.',
  'app-hosting-domains-validation-title': 'Domain validierung',
  'app-hosting-domains-validation-description': 'Um diese Domain deinem Projekt hinzuzufügen, muss sichergestellt werden, dass du der Eigentümer bist. Füge deiner Domain einen DNS TXT Record mit dem nachfolgenden Inhalt hinzu: ',
  'app-hosting-domains-isMain': 'Hauptdomain fur dieses Projekt',
  'app-hosting-domains-active': 'Aktiv',
  'app-hosting-domains-domain': 'Domain',
  'app-hosting-domains-add-button': 'Domain hinzufügen',
  'app-hosting-domains-edit-button': 'Domain bearbeiten',
  'app-hosting-domains-remove-button': 'Domain entfernen',
  'app-hosting-domains-validation-success': 'Domain erfolgreich validiert',
  'app-hosting-domains-validation-required': 'Domain validierung erforderlich',
  'validation-error-DNS_RECORD_MISSING': 'DNS Record wurde nicht gefunden',
  'app-hosting-domains-validate-button': 'DNS Record verifizieren',
  'app-hosting-domains-dns-check': 'DNS Record wird verifiziert',
  'app-hosting-domains-not-validated': 'DNS Record NICHT verifiziert',
  'app-hosting-domains-default': 'Standard Domain',
  'app-hosting-domains-main': 'Hauptdomain',
  'app-hosting-domains-a-record': 'A Record für die Domain',
  'app-hosting-domains-a-record-description': 'Damit die Website unter der Domain gefunden wird, muss der Domain ein A Record mit der nachfolgenden IP Adresse hinzugefügt werden.',
  'app-hosting-domains-visit': '%s aufrufen.',
  'app-hosting-project-settings-nginx': 'Spezifische Einstellungen',
  'app-hosting-settings-isApp': 'App Modus',
  'app-hosting-settings-isApp-description': 'Das Projekt ist mindestens in teilen dynamisch und alle Links die nicht auf einen Datensatz mit den passenden Dateinamen treffen, werden auf index geroutet.',
  'app-hosting-settings-redirectToMainDomain': 'Zur Hauptdomain weiterleiten',
  'app-hosting-settings-redirectToMainDomain-description': 'Alle Domains die dem Projekt zugeordnet sind werden automatisch auf die konfigurierte Hauptdomain weitergeleitet',
};

