import vue from 'vue';
import { Auth } from '@libs/lila-auth';
import { state } from '@store/main.store';

vue.directive('scope', (el, binding, vnode) => {

  console.debug(Auth.checkScope(state.user, binding.value, state.Company.company, state.Project.project));

  if (!Auth.checkScope(state.user, binding.value, state.Company.company, state.Project.project)) {

    el.classList.add('scopeMissing');

  } else {

    el.classList.remove('scopeMissing');

  }


});
