import Models, { ModelDeclaration } from '@libs/Models.class';
import { EmailsContent, ListContent } from '@lilaquadrat/interfaces';


const declaration: ModelDeclaration<EmailsContent> = {
  waiting: { type: 'string' },
  active: { type: 'string' },
  post: { type: 'string' },
};

Models.register('app-lists-emails', declaration);
