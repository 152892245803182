// import PartialModel from '@libs/lila-model';

// export default abstract class ListElementModel extends PartialModel {

//     id: string = '';

//     variant: string[] = [];

//     isEmpty: () => boolean;

// }
import Models, { ModelDeclaration } from '@libs/Models.class';

export default interface ListElement {
  //     isEmpty: () => boolean;
  id: string
  variant: string[]
}

const declaration: ModelDeclaration<ListElement> = {
  //     isEmpty: () => boolean;
  id: { type: 'string' },
  variant: { type: 'array', contains: { type: 'string' } },
};

Models.register('list-element', declaration);
