export default {
  'app-tracker': 'Zeiterfassung',
  'app-tracker-title': 'Zeiterfassung',
  'app-tracker-create-phone': 'Telefonat erfassen',
  'app-tracker-calls': 'Telefonate',
  'app-tracker-create-worktime': 'Arbeitszeit erfassen',
  'app-tracker-worktime': 'Arbeitszeit',
  'app-tracker-category-worktime': 'Arbeitszeit',
  'app-tracker-category-call': 'Telefonat',
  'app-tracker-description': 'Beschreibung',
  'app-tracker-name': 'Beschreibung',
  'app-tracker-no-description': 'Keine Beschreibung',
  'app-tracker-startEnd': 'Start & Endzeit',
  'app-tracker-category': 'Kategorie',
  'app-tracker-currentTime': 'Vergangene Zeit',
  'app-tracker-button-stop': 'Zeiterfassung beenden',
  'app-tracker-button-restart': 'Neue Zeiterfassung starten',
  'app-tracker-statistics-link': 'Statistiken anzeigen',
  'app-tracker-statistics-title': 'Zusammenfassung',
  'app-tracker-statistics-all': 'Gesamt',
  'app-tracker-statistics-call': 'Anrufe',
  'app-tracker-statistics-worktime': 'Arbeitszeit',
  'app-tracker-history-link': 'Historie anzeigen',
  'app-tracker-next-month': 'nächster Monat',
  'app-tracker-prev-month': 'vorheriger Monat',
  'app-tracker-current-month': 'aktueller Monat',
  'app-tracker-monday-short': 'MO',
  'app-tracker-tuesday-short': 'DI',
  'app-tracker-wednesday-short': 'MI',
  'app-tracker-thursday-short': 'DO',
  'app-tracker-friday-short': 'FR',
  'app-tracker-saturday-short': 'SA',
  'app-tracker-sunday-short': 'SO',
  'app-tracker-permissions-read': 'Zeiterfassung anzeigen',
  'app-tracker-permissions-list': 'Zeiterfassung auflisten',
  'app-tracker-permissions-create': 'Zeit erfassen',
  'app-tracker-permissions-edit': 'Zeiterfassung bearbeiten',
  'app-tracker-permissions-remove': 'Zeiterfassung löschen',
  'app-tracker-permissions-settings': 'Zeiterfassung App Einstellungen bearbeiten',
  'app-tracker-permissions-history': 'Historie anzeigen',
  'app-tracker-day': 'Tag',
  'app-tracker-today': 'Heute',
  'app-tracker-tomorrow': 'Morgen',
  'app-tracker-yesterday': 'Gestern',
  'app-tracker-time': 'Uhrzeit',
  'app-tracker-now': 'Jetzt',
  'app-tracker-manipulation': 'Anpassung',
  'app-tracker-subtract-1-hour': '- 1 Stunde',
  'app-tracker-add-1-hour': '+ 1 Stunde',
  'app-tracker-subtract-15-minutes': '- 15 Minuten',
  'app-tracker-add-15-minutes': '+ 15 Minuten',
  'app-tracker-subtract-1-day': '- 1 Tag',
  'app-tracker-add-1-day': '+ 1 Tag',
  'app-tracker-datestring-year': 'Jahr',
  'app-tracker-datestring-years': 'Jahre',
  'app-tracker-datestring-month': 'Monat',
  'app-tracker-datestring-months': 'Monate',
  'app-tracker-datestring-day': 'Tag',
  'app-tracker-datestring-days': 'Tage',
  'app-tracker-datestring-hour': 'Stunde',
  'app-tracker-datestring-hours': 'Stunden',
  'app-tracker-datestring-minute': 'Minute',
  'app-tracker-datestring-minutes': 'Minuten',
  'app-tracker-datestring-second': 'Sekunde',
  'app-tracker-datestring-seconds': 'Sekunden',
  'app-tracker-datestring-and': 'und',
  'app-tracker-add-button': 'Zeit erfassen',
  'app-tracker-add': 'Zeit manuell erfassen',
  'app-tracker-day-empty': 'Keine Buchung',
  'app-tracker-minutes_plural': '%s Minuten',
  'app-tracker-minutes_singular': '%s Minute',
  'app-tracker-hours_plural': '%s Stunden',
  'app-tracker-hours_singular': '%s Stunde',
  'app-tracker-add-multiple': 'Zeit erfassen',
  'app-tracker-project-add-multiple': 'Zeit erfassen',
  'app-tracker-calenderWeek': 'KW',
  'app-tracker-day-no-description': 'Keine Beschreibung',
  'app-tracker-different-month': ' ',
  'app-tracker-remove-day': 'Buchung entfernen',
  'overlay-tracker-ext': 'Zeiterfassung',
  'app-tracker-open-app': 'App öffnen'
};

