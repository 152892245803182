export default {
  'app-publish': 'Publish',
  'app-publish-settings-model': 'Publish Einstellungen',
  'app-publish-cancel': 'Geplante Veröffentlichung abbrechen',
  'app-publish-method': 'Methode',
  'app-publish-planned': 'Geplanter Zeitpunkt',
  'app-publish-timePlanned': 'geplant',
  'app-publish-time': 'Zeitpunkt',
  'app-publish-now': 'sofort',
  'app-publish-created': 'Erstellt',
  'app-publish-state': 'Status',
  'app-publish-state-planned': 'geplant',
  'app-publish-state-planned-description': 'Veröffentlichung wurde eingeplant',
  'app-publish-state-inQueue': 'eingereiht',
  'app-publish-state-inQueue-description': 'Veröffentlichung wird bearbeitet',
  'app-publish-state-canceled': 'abgebrochen',
  'app-publish-state-canceled-description': 'Vorgang wurde manuell abgebrochen',
  'app-publish-state-partialSuccess': 'unvollständig',
  'app-publish-state-partialSuccess-description': 'Es konnten nicht alle Datensätze veröffentlicht werden',
  'app-publish-state-success': 'erfolgreich',
  'app-publish-state-success-description': 'Alle Datensätze wurden erfolgreich veröffentlicht',
  'app-publish-state-failed': 'fehlgeschlagen',
  'app-publish-state-failed-description': 'Bei der Verarbeitung sind Fehler aufgetreten',
  'app-publish-state-inProgress': 'in Arbeit',
  'app-publish-state-inProgress-description': 'Publish wird gerade ausgeführt',
  'app-publish-state-noAction': 'leer',
  'app-publish-state-noAction-description': 'Keine Datensatze haben der Auswahl der Publish Einstellungen enstsprochen.',
  'app-publish-type': 'Typ',
  'app-publish-pre': 'Vordefinierte Art',
  'app-publish-app': 'App',
  'app-publish-model': 'Model',
  'app-publish-immediately': 'sofort',
  'app-publish-all': 'Alle Datensätze',
  'app-publish-sync': 'Geänderte Datensätze',
  'app-publish-result-statistics': 'Statistiken',
  'app-publish-result-affected-success': 'betroffene Datensätze, davon',
  'app-publish-result-succesful': 'erfolgreich.',
  'app-publish-result': 'Publish Ergebnisse',
  'app-publish-actions': 'Aktionen',
  'app-publish-publish-all': 'Alles veröffentlichen',
  'app-publish-publish-single': 'Datensatz veröffentlichen',
  'app-publish-publish-add': 'Publishen',
  'app-publish-category': 'Kategorie',
  'app-publish-single': 'Einzelner Datensatz',
  'app-publish-missing-method': 'Es ist aktuell keine Methode zum Veröffentlichen verfügbar.',
  'app-publish-webhook-key': 'Webhook Validation Key',
  'app-publish-secret-get': 'Schlüssel anzeigen',
  'app-publish-secret-refresh': 'Schlüssel erneuern',
  'all data with the state publish will be published': 'Alle Datensätze mit dem Status Publish werden veröffentlicht.',
  'in order to publish single data, the state has to be publish': 'Um einen einzelnen Datensatz veröffentlichen zu können, muss dessen Status Publish sein.',
  'if the secret gets refreshed, all systems that consume the webhook, need to be updated to the new secret.': 'Wenn der Schlüssel erneuert wird, mussen alle Systeme die den Webhook empfangen, den neuen Schlüssel nutzen.',
  'app-publish-method-active': 'aktiv',
  'app-publish-method-inactive': 'inaktiv',
  'app-publish-method-label': 'Label',
  'app-publish-method-type': 'Typ',
  'app-publish-method-type-ftp': 'FTP',
  'app-publish-method-type-webhook': 'Webhook',
  'app-publish-method-type-pdf': 'PDF',
  'app-publish-method-type-email': 'E-Mail',
  'app-publish-methods-add': 'Methode hinzufügen',
  'app-publish-methods-overview': 'Übersicht',
  'app-publish-methods-home': 'Übersicht',
  'app-publish-methods': 'Publish Methoden',
  'app-publish-method-create-button': 'Methode hinzufügen',
  'app-publish-method-edit-button': 'Änderungen speichern',
  'app-publish-method-ftp-host': 'Host',
  'app-publish-method-ftp-port': 'Port',
  'app-publish-method-ftp-username': 'Username',
  'app-publish-method-ftp-password': 'Passwort',
  'app-publish-method-ftp-mode': 'Mode',
  'app-publish-method-ftp-availableForApps': 'Verfügbar in Apps',
  'app-publish-method-webhook-availableForApps': 'Verfügbar in Apps',
  'app-publish-contentGroup-all': 'Alle',
  'app-publish-contentGroup-category': 'Kategorie "%s"',
  'app-publish-contentGroup-single': 'Einzelner Datensatz',
  'app-publish-method-availableForApps': 'Verfügbar in Apps',
  'app-publish-method-availableForApps-description': 'Via Livesuche STUDIO Apps auswählen, die diese Methode nutzen dürfen. z.B. Editor',
  'app-publish-method-availableForContentGroups': 'Datensätze gruppiert nach',
  'app-publish-method-availableForContentGroups-description': 'Es können alle, einzelne oder Datensätze in bestimmten Kateogrien veröffentlicht werden.',
  'app-publish-method-availableForContentGroups-all': 'Alle verfügbaren',
  'app-publish-method-availableForContentGroups-single': 'Einzelne Datensätze',
  'app-publish-method-availableForContentGroups-category': 'In einer Kategorie',
  'app-publish-method-ftp-type': 'Typ',
  'app-publish-method-ftp-availableForApps-element': 'Verfügbar in Apps Element %s',
  'app-publish-method-contextData': 'Die hier ausgewählten Datensätze stehen dem Design im Publish-Prozess zur Verfügung.',
  'app-publish-method-mailFrom': 'Absender Adresse',
  'app-publish-method-mailFrom-description': 'Alle E-Mails, die von STUDIO versendet werden, haben als Absender die hier gewählte Adresse.',
  'app-publish-affected': 'Betroffene Datensätze: %s ',
  'app-publish-no-affected': 'Es sind keine Datensätze von deiner Auswahl betroffen. Es wird nichts veröffentlicht.',
  'app-publish-method-webhook-url': 'Url',
  'app-publish-methods-edit': 'Methode bearbeiten',
  'app-publish-method-remove-button': 'Methode entfernen',
  'app-publish-affectedStates-all': 'Alle',
  'app-publish-affectedStates': 'Betroffene Datensätze Status',
  'app-publish-affectedStates-description': 'Es können nur Datensätze mit dem gewählten Status veröffentlicht werden.',
  'app-publish-method-type-internal': 'Internes Hosting',
  'app-publish-permissions-publish': 'Inhalte publishen',
  'app-publish-permissions-settings': 'Publish App Einstellungen bearbeiten',
  'app-publish-permissions-history': 'Historie anzeigen',
  'app-publish-permissions-secret': 'Secret anzeigen',
  'app-publish-permissions-refreshSecret': 'Neues Secret erstellen',
  'app-publishMethods-permissions-read': 'Publish Methoden anzeigen',
  'app-publishMethods-permissions-create': 'Publish Methoden erstellen',
  'app-publishMethods-permissions-edit': 'Publish Methoden bearbeiten',
  'app-publishMethods-permissions-remove': 'Publish Methoden entfernen',
  'app-publishMethods-permissions-history': 'Historie anzeigen',
};
