
import Models, { ModelDeclaration } from '@libs/Models.class';

export interface AppHostingAdminCommand {
  company?: string
  project?: string
  target?: string
  command?: string
}

const declaration: ModelDeclaration<AppHostingAdminCommand> = {
  company: { type: 'string' },
  project: { type: 'string' },
  target: { type: 'string' },
  command: { type: 'string' },
};

Models.register('app-hostingadmin-command', declaration);