// import ModuleModel from '@models/Module.model';
// import modules from '@apps/editor/editor-modules';
// import ListLinkModel from '@models/ListLink.model';
// import ListStringModel from '@models/ListString.model';
// import TextblockModel from '@models/Textblock.model';

// export default class CookiesModuleModel extends ModuleModel {

//   type: keyof typeof modules = 'cookies-module';

//   uuid = true;

//   textblock = TextblockModel;

//   list = ListStringModel;

//   links = ListLinkModel;

// }

import Models, { ModelDeclarationExtended, ModelOptions } from '@libs/Models.class';
import ListLink from '@models/ListLink.model';
import ListString from '@models/ListString.model';
import Module from '@models/Module.model';
import Textblock from '@models/Textblock.model';

export default interface CookiesModule extends Module {
  textblock: Textblock
  list: ListString
  links: ListLink
}

const declaration: ModelDeclarationExtended<CookiesModule, Module> = {
  textblock: { model: 'textblock' },
  list: { model: 'list-string' },
  links: { model: 'list-link' },
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('cookies-module', declaration, {}, options);