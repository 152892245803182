// import PartialModel from '@libs/lila-model';
// import DomainSecretModel from './DomainSecretModel';

// export default class DomainModel extends PartialModel {

//   domain: string = '';

//   project: string = '';

//   company: string = '';

//   isMain: boolean = false;

//   isDefault: boolean = false;

//   active: boolean = false;

//   validated: boolean = false;

//   secret = DomainSecretModel;

// }

import Models, { ModelDeclaration, ModelFunctions } from '@libs/Models.class';
import DomainSecret from './DomainSecret.model';

export default interface Domain {
  domain: string
  project: string
  company: string
  isMain: boolean
  isDefault: boolean
  active: boolean
  validated: boolean
  secret: DomainSecret
}

const declaration: ModelDeclaration<Domain> = {
  domain: { type: 'string' },
  project: { type: 'string' },
  company: { type: 'string' },
  isMain: { type: 'boolean', default: false },
  isDefault: { type: 'boolean', default: false },
  active: { type: 'boolean', default: false },
  validated: { type: 'boolean', default: false },
  secret: { model: 'domain-secret' },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};

Models.register('domain', declaration, functions);