// import ListModel from '@models/ListString.model';
// import modules from '@apps/editor/editor-modules';
// import ModuleModel from '@models/Module.model';
// import PicturePartialModel from '@models/PicturePartialModel';
// import TextblockModel from '@models/Textblock.model';
// import VideoPartialModel from '@models/VideoPartial.model';

// export default class VideoModuleModel extends ModuleModel {

//   type: keyof typeof modules = 'video-module';

//   uuid = true;

//   video = VideoPartialModel;

//   poster = PicturePartialModel;

//   textblock = TextblockModel;

//   list = ListModel;

//   fontVariant: string[] = [];

// }

import Models, { ModelDeclarationExtended, ModelOptions } from '@libs/Models.class';
import ListPartial from '@models/ListPartialModel.model';
import Module from '@models/Module.model';
import PicturePartial from '@models/PicturePartial.model';
import Textblock from '@models/Textblock.model';
import VideoPartial from '@models/VideoPartial.model';

export default interface VideoModule extends Module {
  fontVariant: string[]
  video: VideoPartial
  poster: PicturePartial
  textblock: Textblock
  list: ListPartial
}

const declaration: ModelDeclarationExtended<VideoModule, Module> = {
  video: { model: 'video-partial' },
  poster: { model: 'picture-partial' },
  textblock: { model: 'textblock' },
  list: { model: 'list-string' },
  fontVariant: { type: 'array', contains: { type: 'string' } },
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('video-module', declaration, undefined, options);