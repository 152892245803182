// import modules from '@apps/editor/editor-modules';
// import CompareElementModel from '@models/CompareElement.model';
// import CompareStructureElementModel from '@models/CompareStructureElement.model';
// import ModuleModel from '@models/Module.model';
// import TextblockModel from '@models/Textblock.model';

// export default class CompareModuleModel extends ModuleModel {

//   type: keyof typeof modules = 'compare-module';

//   uuid = true;

//   textblock = TextblockModel;

//   elements = [CompareElementModel];

//   structure = [CompareStructureElementModel];

// }

import Models, { ModelDeclarationExtended, ModelOptions } from '@libs/Models.class';
import CompareElement from '@models/CompareElement.model';
import CompareStructureElement from '@models/CompareStructureElement.model';
import Module from '@models/Module.model';
import Textblock from '@models/Textblock.model';

export default interface CompareModule extends Module {
  textblock: Textblock
  elements: CompareElement[]
  structure: CompareStructureElement[]
}

const declaration: ModelDeclarationExtended<CompareModule, Module> = {
  textblock: { model: 'textblock' },
  elements: { type: 'array', contains: { model: 'compare-element' } },
  structure: { type: 'array', contains: { model: 'compare-structure-element' } },
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('compare-module', declaration, {}, options);