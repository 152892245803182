import AppInterface from '@interfaces/App.interface';
import {
  Company,
  Content, HttpStatusCode, Me, Project, PublishMethod,
  Hosting, Domain, ApiResponses, AppFilter,
  ListOfModels, Tracker, TrackerStatistics, DataObject, Media,
  MediaContentFiles, PublishContentGroup, Publish, Customers, List, ListParticipants,
  EditorBase, MailFrom
, AppHostingSettings , MePermissions , CertificateAction, DomainsList, HostingSettings, 
BasicData} from '@lilaquadrat/interfaces';
import hardCopy from '@mixins/hardCopy';
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import mockJs from '../mockJs';

export type SDKResponse<T> = {
  data: T
  status: HttpStatusCode
  cacheLifetime?: number
  cacheTime?: number;
  isCache?: boolean
};

export type SDKCache = {
  group?: string
  action?: string
  id?: string
  cacheLifetime?: number
  cacheTime?: number;
};

export type SDKCallOptions = {
  /**
   * ignore the cache and always execute the call
   */
  bypassCache?: boolean
  group?: string,
  action?: string,
  /**
   * if a id is given, use it as key for atomic cache flushing
   *
   * e.g. ``flushId(id)``
   *
   * instead of the whole action category
   *
   * e.g. ``flushCache(group, action)``
   *
   */
  id?: string
  /**
   * in milliseconds
   */
  cacheLifetime?: number
};

let cachedCalls: Record<string, SDKResponse<any> & SDKCache> = {};

export default class StudioSDK {

  readonly endpoints = {
    live: {
      api: 'http://api.lilaquadrat.de',
      media: 'http://media.lilaquadrat.de',
    },
    next: {
      api: 'http://next-api.lilaquadrat.de',
      media: 'http://next-media.lilaquadrat.de',
    },
  };

  customEndpoints = {
    api: '',
    media: '',
  };

  authToken: string;

  mode: 'live' | 'next' | 'custom' = null;

  company: string;

  project: string;

  app: string;

  universalModel: string;

  options: {
    company?: string,
    project?: string,
    authToken?: string,
    mode?: StudioSDK['mode'],
    customEndpoints?: { api: string, media: string }
    universalModel?: string
  };

  constructor(
    app: string,
    options: StudioSDK['options'],
  ) {

    this.authToken = options.authToken;

    this.customEndpoints = options.customEndpoints;

    this.mode = options.customEndpoints ? 'custom' : options.mode || 'live';

    this.company = options.company;

    this.project = options.project;

    this.app = app;

    this.universalModel = options.universalModel;

  }

  private getUrl(type: 'api' | 'media', methodArray: (string | number)[], options?: { noCompanyProject?: boolean }) {

    const method = methodArray.filter((single) => single);
    const urlArray = [];
    let useEndpoint: string;

    if (this.mode === 'custom') {

      useEndpoint = this.customEndpoints[type];

    } else {

      useEndpoint = this.endpoints[this.mode][type];

    }

    urlArray.push(useEndpoint);
    urlArray.push(...method);

    return urlArray.filter((single) => single).join('/');

  }

  private getHeaders() {

    const headers: Record<string, string> = {
      'studio-app': this.app,
    };

    if (this.authToken) {

      headers.Authorization = `bearer ${this.authToken}`;

    }

    return headers;

  }

  static getCacheKeyMock(url: string) {

    const key = new URL(url);

    return key.pathname + key.search;

  }

  static getCacheKey(url: string) {

    let key: string = url;

    key = key.replace(/:|\/|\?|=|&/ig, '-');

    return key;

  }

  static getCache() {

    return cachedCalls;

  }

  static handleCall<T, D = any>(call: AxiosRequestConfig<D>, options?: SDKCallOptions): Promise<SDKResponse<T>> {

    if (ISMOCK) {

      if (call.method !== 'GET') return Promise.resolve({ data: {} as T, status: 200 });

      const key = StudioSDK.getCacheKeyMock(call.url);

      console.group(`SDK_MOCK_CALL: [${call.method}] ${key}`);

      // if url is not in MockJs.ts, error will be thrown
      if (!mockJs[key]) {

        console.error(key);
        console.groupEnd();
        throw new Error('MOCK_DATA_MISSING');

      }

      console.log({ data: mockJs[key].data as T, status: mockJs[key].status });
      console.groupEnd();

      return Promise.resolve({ data: mockJs[key].data as T, status: mockJs[key].status });

    }

    if (call.method === 'GET' && !options?.bypassCache) {

      const cacheHit = StudioSDK.cache<T>(axios.getUri(call), undefined, options);

      if (cacheHit) return Promise.resolve(cacheHit);

    }

    return axios.request(call)
      .then((response) => {

        if (call.method === 'GET') StudioSDK.cache(axios.getUri(call), response, options);

        return response;

      })
      .then((response) => ({ data: response.data, status: response.status }));

  }

  static cache<T>(url: string, response?: AxiosResponse<T>, options?: SDKCallOptions) {

    const key = options?.id ? options.id : StudioSDK.getCacheKey(url);

    if (response) {

      const useKey = options?.id
        ? options.id
        : key;

      cachedCalls[useKey] = {
        data: response.data,
        status: response.status,
        action: options?.action,
        group: options?.group,
        cacheLifetime: options?.cacheLifetime ? Date.now() + (options?.cacheLifetime || 0) : null,
        cacheTime: Date.now(),
      } as SDKResponse<T> & SDKCache;

      return undefined;

    }

    const useCache = cachedCalls[key];

    if (!useCache) return null;

    if (useCache.cacheLifetime && useCache.cacheLifetime < Date.now()) {

      console.debug('cache found but lifetime');

      delete cachedCalls[key];
      return null;

    }

    const returnCache = hardCopy(useCache);

    delete returnCache?.action;
    delete returnCache?.group;
    delete returnCache?.id;

    returnCache.isCache = true;

    return returnCache;

  }

  static flushCache(group?: string, action?: string) {

    if (group || action) {

      Object.keys(cachedCalls).forEach((key) => {

        let flush: boolean = false;
        const singleCache = cachedCalls[key];

        if (group && action) {

          if (singleCache.group === group && singleCache.action === action) flush = true;

        } else if (group) {

          if (singleCache.group === group) flush = true;

        } else if (action) {

          if (singleCache.action === action) flush = true;

        }

        if (flush) {

          delete cachedCalls[key];

        }

      });

    } else {

      cachedCalls = {};

    }

  }

  static flushId(id: string) {

    delete cachedCalls[id];

  }

  hosting = {

    single: () => StudioSDK.handleCall<Hosting>(
      {
        method: 'GET',
        url: this.getUrl('api', ['hosting', this.company, this.project]),
        headers: this.getHeaders(),
        params: {},
      },
      {
        group: 'single',
        action: 'hosting',
      },
    ),

    activate: () => StudioSDK.handleCall<Hosting>(
      {
        method: 'POST',
        url: this.getUrl('api', ['hosting', this.company, this.project, 'activate']),
        headers: this.getHeaders(),
        params: {},
      },
    ),

    deactivate: () => StudioSDK.handleCall<Hosting>(
      {
        method: 'POST',
        url: this.getUrl('api', ['hosting', this.company, this.project, 'deactivate']),
        headers: this.getHeaders(),
        params: {},
      },
    ),

    updateSettings: (settings: AppHostingSettings) => StudioSDK.handleCall<HostingSettings>(
      {
        method: 'PUT',
        url: this.getUrl('api', ['hosting', this.company, this.project, 'settings']),
        headers: this.getHeaders(),
        data: settings
      },
    ),

    admin: {

      list: (site: number = 1, options?: { active?: boolean }) => {

        const params = { ...options };

        return StudioSDK.handleCall<Domain[]>(
          {
            method: 'GET',
            url: this.getUrl('api', ['hosting', 'admin', this.company, 'list', 'hosting', site]),
            headers: this.getHeaders(),
            params,
          },
          {
            group: 'domains',
            action: 'list',
            bypassCache: true,
          },
        );

      },

      getSettings: () => StudioSDK.handleCall<HostingSettings>(
        {
          method: 'GET',
          url: this.getUrl('api', ['hosting', 'admin', this.company, 'settings']),
          headers: this.getHeaders(),
        },
        {
          group: 'hostingadmin',
          action: 'settings',
        },
      ),

      updateSettings: (settings: HostingSettings) => StudioSDK.handleCall<HostingSettings>(
        {
          method: 'PUT',
          url: this.getUrl('api', ['hosting', 'admin', this.company, 'settings']),
          headers: this.getHeaders(),
          data: settings
        },
      ),

    },

    commands: {

      admin: {

        list: (site: number = 1, filter: { state: string[], target: string, command: string, sort: string, order: string }) => StudioSDK.handleCall<Domain[]>(
          {
            method: 'GET',
            url: this.getUrl('api', ['hosting', 'admin', this.company, 'list', 'commands', site]),
            headers: this.getHeaders(),
            params: {
              state: filter.state,
              target: filter.target,
              command: filter.command,
              sort: filter.sort,
              order: filter.order,
            },
          },
          {
            group: 'commands',
            action: 'list',
          },
        ),

        single: (id: string) => StudioSDK.handleCall<Domain[]>(
          {
            method: 'GET',
            url: this.getUrl('api', ['hosting', 'admin', this.company, 'commands', id]),
            headers: this.getHeaders(),
          },
          {
            group: 'commands',
            action: 'single',
            id
          },
        ),

        add: (type: 'refresh' | 'docker' | 'nginx', command: string, company: string, project: string) => {

          const urlArray = ['hosting', 'admin', this.company];

          if (type === 'refresh') {

            urlArray.push(...['refresh', command]);

          }

          if (type === 'docker') {

            urlArray.push(...['docker', command]);

          }

          if (type === 'nginx') {

            urlArray.push(...['nginx', command]);

          }

          console.log(urlArray, type, command);

          return StudioSDK.handleCall(
            {
              method: 'POST',
              url: this.getUrl('api', urlArray),
              headers: this.getHeaders(),
              data: {
                company,
                project,
              }
            },
          );

        },

      }

    },

    certificates: {

      actions: {

        admin: {

          list: (site: number = 1, filter?: { state: string }) => {

            const params = { ...filter };

            return StudioSDK.handleCall<ListOfModels<CertificateAction>>(
              {
                method: 'GET',
                url: this.getUrl('api', ['certificatesActions', 'admin', this.company, 'list', site]),
                headers: this.getHeaders(),
                params,
              },
              {
                group: 'certificatesActions',
                action: 'list',
              },
            );

          },

          single: (id: string) => StudioSDK.handleCall<CertificateAction>(
            {
              method: 'GET',
              url: this.getUrl('api', ['certificatesActions', 'admin', this.company, id]),
              headers: this.getHeaders(),
            },
            {
              group: 'certificatesActions',
              action: 'single',
              id
            },
          ),

          cancel: (id: string) => StudioSDK.handleCall<CertificateAction>(
            {
              method: 'PUT',
              url: this.getUrl('api', ['certificatesActions', 'admin', this.company, 'cancel', id]),
              headers: this.getHeaders(),
            },
          ),

        }

      }

    },

    domains: {

      admin: {

        list: (site: number = 1, filter: { state: string[], sort: string, order: string }) => StudioSDK.handleCall<DomainsList[]>(
          {
            method: 'GET',
            url: this.getUrl('api', ['domains', 'admin', this.company, 'list', site]),
            headers: this.getHeaders(),
            params: {
              ...filter
            },
          },
          {
            group: 'domains',
            action: 'list',
            bypassCache: true,
          },
        ),

        single: (id: string) => StudioSDK.handleCall<Domain>(
          {
            method: 'GET',
            url: this.getUrl('api', ['domains', 'admin', this.company, id]),
            headers: this.getHeaders(),
          },
          {
            group: 'domains',
            action: 'single',
            id
          },
        ),

        request: (internalId: string) => StudioSDK.handleCall<Domain>(
          {
            method: 'POST',
            url: this.getUrl('api', ['domains', 'admin', this.company, 'request', internalId]),
            headers: this.getHeaders(),
          }
        ),

      },

      list: (site: number = 1, options?: { active?: boolean }) => {

        const params = { ...options };

        return StudioSDK.handleCall<Domain[]>(
          {
            method: 'GET',
            url: this.getUrl('api', ['domains', this.company, this.project, 'list', site]),
            headers: this.getHeaders(),
            params,
          },
          {
            group: 'domains',
            action: 'list',
            bypassCache: true,
          },
        );

      },
      single: (id: string, options?: { active?: boolean }) => {

        const params = { ...options };

        return StudioSDK.handleCall<Domain[]>(
          {
            method: 'GET',
            url: this.getUrl('api', ['domains', this.company, this.project, id]),
            headers: this.getHeaders(),
            params,
          },
          {
            group: 'domains',
            action: 'single',
            bypassCache: true,
          },
        );

      },
      update: (id: string, domain: Domain) => StudioSDK.handleCall<Domain>(
        {
          method: 'PUT',
          url: this.getUrl('api', ['domains', this.company, this.project, id]),
          headers: this.getHeaders(),
          data: domain,
        },
      ),
      remove: (id: string) => StudioSDK.handleCall<Domain>(
        {
          method: 'DELETE',
          url: this.getUrl('api', ['domains', this.company, this.project, id]),
          headers: this.getHeaders(),
        },
      ),
      create: (domain: Domain) => StudioSDK.handleCall<Domain>(
        {
          method: 'POST',
          url: this.getUrl('api', ['domains', this.company, this.project]),
          headers: this.getHeaders(),
          data: domain,
        },
      ),
      validate: (id: string) => StudioSDK.handleCall<ApiResponses['post']>(
        {
          method: 'POST',
          url: this.getUrl('api', ['domains', this.company, this.project, 'validate', id]),
          headers: this.getHeaders(),
        },
      ),
    },

  };

  content = {

    fetch: (type: string, link: string, options?: { state?: 'draft' | 'publish' }) => StudioSDK.handleCall<Content>(
      {
        method: 'GET',
        url: this.getUrl('api', ['content', type, link]),
        headers: this.getHeaders(),
        params: options,
      },
    ),

    search: (type: string, search: string, site: number = 1, options?: { state?: 'draft' | 'publish' }) => {

      const params = { search, ...options };

      return StudioSDK.handleCall<Content>(
        {
          method: 'GET',
          url: this.getUrl('api', ['content', type, 'search', site.toString()]),
          headers: this.getHeaders(),
          params,
        },
      );

    },

    predefined: (id: string) => StudioSDK.handleCall<Content>(
      {
        method: 'GET',
        url: this.getUrl('api', ['public', 'content', 'lilaquadrat', 'studio', id]),
        headers: this.getHeaders(),
      },
      {
        group: 'editor',
        action: 'single',
        id,
      },
    ),

    predefinedLatest: (categories: string[]) => StudioSDK.handleCall<Content>(
      {
        method: 'GET',
        url: this.getUrl('api', ['public', 'content', 'lilaquadrat', 'studio', 'latest']),
        headers: this.getHeaders(),
        params: {
          category: categories,
        },
      },
      {
        group: 'editor',
        action: 'single',
      },
    ),

  };

  apps = {


    list: (site?: number, filter?: AppFilter, search?: string) => StudioSDK.handleCall<ListOfModels<AppInterface>>(
      {
        method: 'GET',
        url: this.getUrl('api', ['apps', this.company, this.project, 'list', site]),
        headers: this.getHeaders(),
        params: {
          filter,
          search,
        },
      },
      {
        group: 'apps',
        action: 'list',
      },
    ),

    active: (data: { company: string, project?: string, search?: string }) => StudioSDK.handleCall<ListOfModels<AppInterface>>(
      {
        method: 'GET',
        url: this.getUrl('api', ['apps', data.company, data.project, 'active']),
        headers: this.getHeaders(),
        params: {
          search: data.search
        }
      },
      {
        group: 'apps',
        action: 'active',
      },
    ),

    toggle: (data: { company: string, project: string, active: boolean, id: string }) => StudioSDK.handleCall<ApiResponses['put']>(
      {
        method: 'PUT',
        url: this.getUrl('api', ['apps', data.company, data.project, 'toggle']),
        headers: this.getHeaders(),
        data: {
          active: data.active,
          id: data.id,
        },
      },
    ),

    admin: {

      list: (site?: number, filter?: AppFilter, search?: string) => StudioSDK.handleCall<ListOfModels<AppInterface>>(
        {
          method: 'GET',
          url: this.getUrl('api', ['apps', 'admin', this.company, 'list', site]),
          headers: this.getHeaders(),
          params: {
            search,
          },
        },
        {
          group: 'adminApps',
          action: 'list',
        },
      ),

      remove: (id: string) => StudioSDK.handleCall<PublishMethod>(
        {
          method: 'DELETE',
          url: this.getUrl('api', ['apps', 'admin', this.company, id]),
          headers: this.getHeaders(),
        },
      ),

    },

  };

  media = {

    get: (data: { company: string, project: string, site?: number, query?: { tags?: string[], filename?: string } }) => StudioSDK.handleCall<ListOfModels<Media>>(
      {
        method: 'GET',
        url: this.getUrl('media', [data.company, data.project, data.site]),
        headers: this.getHeaders(),
        params: {
          ...data.query,
        },
      },
      {
        group: 'media',
        action: 'get',
      },
    ),

    single: (filename: string) => StudioSDK.handleCall<BasicData<Media>>(
      {
        method: 'GET',
        url: this.getUrl('media', [this.company, this.project, filename, 'file']),
        headers: this.getHeaders(),
      },
      {
        group: 'media',
        id: filename
      },
    ),

    update: (id: string, tags?: string[]) => StudioSDK.handleCall<ApiResponses['put']>(
      {
        method: 'PUT',
        url: this.getUrl('media', [this.company, this.project, id]),
        headers: this.getHeaders(),
        data: {
          tags
        }
      },
    ),

    remove: (internalId: string) => StudioSDK.handleCall<ApiResponses['put']>(
      {
        method: 'DELETE',
        url: this.getUrl('media', [this.company, this.project, internalId]),
        headers: this.getHeaders(),
      },
    ),

    fileForContent: (data: { company: string, project: string, contentId: string }) => StudioSDK.handleCall<MediaContentFiles>(
      {
        method: 'GET',
        url: this.getUrl('media', [data.company, data.project, 'content', data.contentId]),
        headers: this.getHeaders(),
      },
      {
        group: 'media',
        action: 'fileForContent',
      },
    ),

    stats: (data: { company: string, project: string }) => StudioSDK.handleCall<any>(
      {
        method: 'GET',
        url: this.getUrl('media', [data.company, data.project, 'stats']),
        headers: this.getHeaders(),
      },
      {
        group: 'media',
        action: 'stats',
      },
    ),

  };

  editor = {
    getById: (id: string) => StudioSDK.handleCall<Content>(
      {
        method: 'GET',
        url: this.getUrl('api', ['editor', this.company, this.project, id]),
        headers: this.getHeaders(),
      },
      {
        group: 'editor',
        action: 'single',
        id,
      },
    ),

    getByInternalId: (id: string) => StudioSDK.handleCall<Content>(
      {
        method: 'GET',
        url: this.getUrl('api', ['editor', this.company, this.project, 'internal', id]),
        headers: this.getHeaders(),
      },
      {
        group: 'editor',
        action: 'single',
        id,
      },
    ),


    settings: (data: { company: string, project: string }) => StudioSDK.handleCall<Content>(
      {
        method: 'GET',
        url: this.getUrl('api', ['editor', data.company, data.project, 'settings']),
        headers: this.getHeaders(),
      },
      {
        group: 'editor',
        action: 'settings',
      },
    ),

    list: (site: number = 0, sort?: string, order?: number, options?: { layout?: boolean, partial?: boolean, active?: boolean, search?: string, tags?: string[], target?: BasicData<Content>['target'] }) => {

      const params = {
        sort,
        order,
        ...(options || {})
      };

      return StudioSDK.handleCall<ListOfModels<Content>>(
        {
          method: 'GET',
          url: this.getUrl('api', ['editor', this.company, this.project, 'list', site]),
          headers: this.getHeaders(),
          params
        },
        {
          group: 'editor',
          action: 'list',
        },
      );

    },
  };

  health = {

    health: () => StudioSDK.handleCall<void>(
      {
        method: 'GET',
        url: this.getUrl('api', ['health']),
        headers: this.getHeaders(),
      },
    ),

  };

  settings = {

    settings: () => axios.get('/settings/settings.json'),

    single: (data: { company: string, project: string, details?: boolean }) => StudioSDK.handleCall<Project>(
      {
        method: 'GET',
        url: this.getUrl('api', ['projects', data.company, data.project]),
        headers: this.getHeaders(),
      },
      {
        group: 'projects',
        action: 'single',
      },
    ),
  };

  companies = {
    single: (id: string) => StudioSDK.handleCall<Company>(

      {
        method: 'GET',
        url: this.getUrl('api', ['companies', id]),
        headers: this.getHeaders(),
      },
      {
        group: 'company',
        action: 'single',
        id,
      },
    ),

    update: (id: string, data: Company) => StudioSDK.handleCall<ApiResponses['put']>(

      {
        method: 'PUT',
        url: this.getUrl('api', ['companies', id]),
        headers: this.getHeaders(),
        data,
      },
    ),

    admin: {

      update: (companyId: string, adminCompanyId: string, data: Company) => StudioSDK.handleCall<ApiResponses['put']>(

        {
          method: 'PUT',
          url: this.getUrl('api', ['companies', 'admin', adminCompanyId, companyId]),
          headers: this.getHeaders(),
          data,
        },
      ),

    }

  };

  me = {

    single: (data?: { company: string, project: string }) => StudioSDK.handleCall<Me>(
      {
        method: 'GET',
        url: this.getUrl('api', ['me', 'permissions', data?.company, data?.project]),
        headers: this.getHeaders(),
      },
      {
        group: 'me',
        action: 'single',
      },
    ),

    all: () => StudioSDK.handleCall<MePermissions>(
      {
        method: 'GET',
        url: this.getUrl('api', ['me', 'permissions']),
        headers: this.getHeaders(),
      },
      {
        group: 'me',
        action: 'all',
      },
    ),

    settings: {

      single: (data?: { company: string, project: string }) => StudioSDK.handleCall<Me>(
        {
          method: 'GET',
          url: this.getUrl('api', ['me', 'permissions', data?.company, data?.project]),
          headers: this.getHeaders(),
        },
        {
          group: 'me',
          action: 'single',
        },
      ),

      update: (data?: any) => StudioSDK.handleCall<Me>(
        {
          method: 'PUT',
          url: this.getUrl('api', ['me', 'settings']),
          headers: this.getHeaders(),
          data
        },
      ),
  

    }


  };

  projects = {

    single: (data: { company: string, project: string, details?: boolean }) => StudioSDK.handleCall<Project>(
      {
        method: 'GET',
        url: this.getUrl('api', ['projects', data.company, data.project]),
        headers: this.getHeaders(),
      },
      {
        group: 'projects',
        action: 'single',
      },
    ),

    admin: {

      list: (site: number, data: { search: string }) => StudioSDK.handleCall<Project[]>(
        {
          method: 'GET',
          url: this.getUrl('api', ['projects', 'admin', this.company, site || 1]),
          headers: this.getHeaders(),
          params: {
            search: data.search
          }
        },
        {
          group: 'projects',
          action: 'list',
        },
      ),

      getByInternalId: (internalId: string) => StudioSDK.handleCall<Project>(
        {
          method: 'GET',
          url: this.getUrl('api', ['projects', 'admin', this.company, 'single', internalId]),
          headers: this.getHeaders(),
        },
        {
          group: 'projects',
          action: 'list',
          id: internalId,
        },
      ),

    }


  };

  publish = {

    publish: (data: Publish) => StudioSDK.handleCall<{ id: string }>(
      {
        method: 'POST',
        url: this.getUrl('api', ['publish', this.company, this.project]),
        headers: this.getHeaders(),
        data,
      },
    ),

    single: (internalId: string, options?: { cacheLifetime?: number }) => StudioSDK.handleCall<Publish>(
      {
        method: 'GET',
        url: this.getUrl('api', ['publish', this.company, this.project, internalId]),
        headers: this.getHeaders(),
      },
      {
        group: 'publish',
        action: 'single',
        id: internalId,
        cacheLifetime: options?.cacheLifetime,
      },
    ),

    affected: (query: { app?: string, model?: string, changed?: Date, affectedStates?: PublishMethod['affectedStates'], category?: string }, options?: { cacheLifetime?: number }) => StudioSDK.handleCall<Publish>(
      {
        method: 'GET',
        url: this.getUrl('api', ['publish', this.company, this.project, 'affected']),
        headers: this.getHeaders(),
        params: query,
      },
      {
        group: 'publish',
        action: 'affected',
        cacheLifetime: options?.cacheLifetime,
      },
    ),

    methods: {

      list: (site: number = 1, options?: { active?: boolean }) => {

        const params = { ...options };

        return StudioSDK.handleCall<PublishMethod>(
          {
            method: 'GET',
            url: this.getUrl('api', ['publish', 'methods', this.company, this.project, 'list', site]),
            headers: this.getHeaders(),
            params,
          },
          {
            group: 'methods',
            action: 'list',
          },
        );

      },

      mailFrom: () => StudioSDK.handleCall<MailFrom[]>(
        {
          method: 'GET',
          url: this.getUrl('api', ['publish', 'methods', this.company, this.project, 'mailFrom']),
          headers: this.getHeaders(),
        },
        {
          group: 'publish',
          action: 'mailFrom',
        },
      ),

      active: (app: string, contentGroups: PublishContentGroup[]) => StudioSDK.handleCall<PublishMethod[]>(
        {
          method: 'GET',
          url: this.getUrl('api', ['publish', 'methods', this.company, this.project, 'active', app]),
          headers: this.getHeaders(),
          params: {
            contentGroups,
          },
        },
        {
          group: 'methods',
          action: 'active',
        },
      ),

      single: (internalId: string) => StudioSDK.handleCall<PublishMethod>(
        {
          method: 'GET',
          url: this.getUrl('api', ['publish', 'methods', this.company, this.project, internalId]),
          headers: this.getHeaders(),
        },
        {
          group: 'methods',
          action: 'single',
          id: internalId,
        },
      ),

      add: (method: PublishMethod) => StudioSDK.handleCall<PublishMethod, PublishMethod>(
        {
          method: 'POST',
          url: this.getUrl('api', ['publish', 'methods', this.company, this.project]),
          headers: this.getHeaders(),
          data: method,
        },
      ),

      update: (id: string, method: PublishMethod) => StudioSDK.handleCall<PublishMethod, PublishMethod>(
        {
          method: 'PUT',
          url: this.getUrl('api', ['publish', 'methods', this.company, this.project, id]),
          headers: this.getHeaders(),
          data: method,
        },
      ),

      remove: (id: string) => StudioSDK.handleCall<PublishMethod>(
        {
          method: 'DELETE',
          url: this.getUrl('api', ['publish', 'methods', this.company, this.project, id]),
          headers: this.getHeaders(),
        },
      ),

    },

    admin: {

      mailFrom: {

        save: (data: MailFrom) => StudioSDK.handleCall<MailFrom>(
          {
            method: 'POST',
            url: this.getUrl('api', ['publish', 'admin', this.company]),
            headers: this.getHeaders(),
            data,
          },
        ),

        update: (internalId: string, data: MailFrom) => StudioSDK.handleCall<MailFrom>(
          {
            method: 'PUT',
            url: this.getUrl('api', ['publish', 'admin', this.company, internalId]),
            headers: this.getHeaders(),
            data,
          },
        ),

        remove: (internalId: string) => StudioSDK.handleCall<MailFrom>(
          {
            method: 'DELETE',
            url: this.getUrl('api', ['publish', 'admin', this.company, internalId]),
            headers: this.getHeaders(),
          },
        ),

        list: (site: number, data: { search: string }) => StudioSDK.handleCall<MailFrom[]>(
          {
            method: 'GET',
            url: this.getUrl('api', ['publish', 'admin', this.company, 'list', site || 1]),
            headers: this.getHeaders(),
            params: {
              search: data.search
            }
          },
          {
            group: 'mailFrom',
            action: 'list',
          },
        ),

        single: (internalId: string) => StudioSDK.handleCall<MailFrom>(
          {
            method: 'GET',
            url: this.getUrl('api', ['publish', 'admin', this.company, internalId]),
            headers: this.getHeaders(),
          },
          {
            group: 'mailFrom',
            action: 'single',
            id: internalId,
          },
        ),
    

      }
  
    }

  };

  tracker = {

    list: (site: number = 0, search?: string, category?: string[], user?: string) => StudioSDK.handleCall<DataObject<Tracker>>(
      {
        method: 'GET',
        url: this.getUrl('api', ['tracker', this.company, this.project, 'list', site]),
        headers: this.getHeaders(),
        params: {
          search,
          category,
          user,
        },
      },
      {
        group: 'tracker',
        action: 'list',
      },
    ),

    statistics: (year?: number, month?: number, user?: string) => StudioSDK.handleCall<TrackerStatistics>(
      {
        method: 'GET',
        url: this.getUrl('api', ['tracker', this.company, this.project, 'statistics']),
        headers: this.getHeaders(),
        params: {
          year,
          month,
          user,
        },
      },
      {
        group: 'tracker',
        action: 'statistics',
      },
    ),

    day: (date: string, user?: string) => StudioSDK.handleCall<TrackerStatistics>(
      {
        method: 'GET',
        url: this.getUrl('api', ['tracker', this.company, this.project, 'day', date]),
        headers: this.getHeaders(),
        params: {
          user,
        },
      },
      {
        group: 'tracker',
        action: 'day',
      },
    ),

    single: (id: string) => StudioSDK.handleCall<TrackerStatistics>(
      {
        method: 'GET',
        url: this.getUrl('api', ['tracker', this.company, this.project, id]),
        headers: this.getHeaders(),
      },
      {
        group: 'tracker',
        action: 'single',
        id,
      },
    ),

    save: (tracker: Tracker) => StudioSDK.handleCall<Tracker>(
      {
        method: 'POST',
        url: this.getUrl('api', ['tracker', this.company, this.project]),
        headers: this.getHeaders(),
        data: tracker,
      },
    ),

    saveMultiple: (tracker: Tracker[]) => StudioSDK.handleCall<Tracker>(
      {
        method: 'POST',
        url: this.getUrl('api', ['tracker', this.company, this.project, 'multiple']),
        headers: this.getHeaders(),
        data: tracker,
      },
    ),

    description: (id: string, description: Tracker['description']) => StudioSDK.handleCall<Tracker>(
      {
        method: 'PUT',
        url: this.getUrl('api', ['tracker', this.company, this.project, 'description', id]),
        headers: this.getHeaders(),
        data: {
          description,
        },
      },
    ),

    update: (id: string, data: Pick<Tracker, 'category' | 'startTime' | 'endTime' | 'description'>) => StudioSDK.handleCall<Tracker>(
      {
        method: 'PUT',
        url: this.getUrl('api', ['tracker', this.company, this.project, id]),
        headers: this.getHeaders(),
        data,
      },
    ),

    remove: (id: string) => StudioSDK.handleCall<Tracker>(
      {
        method: 'DELETE',
        url: this.getUrl('api', ['tracker', this.company, this.project, id]),
        headers: this.getHeaders(),
      },
    ),

  };

  customers = {

    list: (site: number = 0, search?: string, tags?: string[], type?: string, sort?: number, order?: string) => StudioSDK.handleCall<DataObject<Customers>>(
      {
        method: 'GET',
        url: this.getUrl('api', ['customers', this.company, 'list', site]),
        headers: this.getHeaders(),
        params: {
          search,
          tags,
          sort,
          order,
          type,
        },
      },
      {
        group: 'customers',
        action: 'list',
      },
    ),

    single: (id: string) => StudioSDK.handleCall<Customers>(
      {
        method: 'GET',
        url: this.getUrl('api', ['customers', this.company, id]),
        headers: this.getHeaders(),
      },
      {
        group: 'customers',
        action: 'single',
        id,
      },
    ),

    tags: (search: string) => StudioSDK.handleCall<string[]>(
      {
        method: 'GET',
        url: this.getUrl('api', ['customers', this.company, 'tags', search]),
        headers: this.getHeaders(),
      },
      {
        group: 'customers',
        action: 'tags',
      },
    ),

    update: (id: string, data: Customers) => StudioSDK.handleCall<Customers>(
      {
        method: 'PUT',
        url: this.getUrl('api', ['customers', this.company, id]),
        headers: this.getHeaders(),
        data,
      },
    ),

    add: (data: Customers) => StudioSDK.handleCall<Customers>(
      {
        method: 'POST',
        url: this.getUrl('api', ['customers', this.company]),
        headers: this.getHeaders(),
        data,
      },
    ),

    remove: (id: string) => StudioSDK.handleCall<Customers>(
      {
        method: 'DELETE',
        url: this.getUrl('api', ['customers', this.company, id]),
        headers: this.getHeaders(),
      },
    ),

  };

  lists = {

    single: (id: string) => StudioSDK.handleCall<List>(
      {
        method: 'GET',
        url: this.getUrl('api', ['lists', this.company, this.project, id]),
        headers: this.getHeaders(),
      },
      {
        group: 'lists',
        action: 'single',
        id,
      },
    ),

    add: (data: List) => StudioSDK.handleCall<List>(
      {
        method: 'POST',
        url: this.getUrl('api', ['lists', this.company, this.project]),
        headers: this.getHeaders(),
        data,
      },
    ),

    update: (id: string, data: List) => StudioSDK.handleCall<List>(
      {
        method: 'PUT',
        url: this.getUrl('api', ['lists', this.company, this.project, id]),
        headers: this.getHeaders(),
        data,
      },
    ),

    remove: (id: string) => StudioSDK.handleCall<List>(
      {
        method: 'DELETE',
        url: this.getUrl('api', ['lists', this.company, this.project, id]),
        headers: this.getHeaders(),
      },
    ),

    getByInternalId: (id: string) => StudioSDK.handleCall<List>(
      {
        method: 'GET',
        url: this.getUrl('api', ['lists', this.company, this.project, id]),
        headers: this.getHeaders(),
      },
      {
        group: 'lists',
        action: 'single',
        id,
      },
    ),

    list: (site: number = 0, search?: string, tags?: string[], state?: string, mode?: string, sort?: string, order?: number) => StudioSDK.handleCall<DataObject<List[]>>(
      {
        method: 'GET',
        url: this.getUrl('api', ['lists', this.company, this.project, 'list', site]),
        headers: this.getHeaders(),
        params: {
          search,
          tags,
          sort,
          order,
          state,
          mode,
        },
      },
      {
        group: 'lists',
        action: 'list',
      },
    ),

    participants: {

      single: (listId: string, id: string) => StudioSDK.handleCall<ListParticipants>(
        {
          method: 'GET',
          url: this.getUrl('api', ['lists', 'participants', this.company, this.project, listId, id]),
          headers: this.getHeaders(),
        },
        {
          group: 'listParticipants',
          action: 'single',
          id,
        },
      ),

      udpateState: (listId: string, id: string, state: ListParticipants['state']) => StudioSDK.handleCall<ListParticipants>(
        {
          method: 'PUT',
          url: this.getUrl('api', ['lists', 'participants', this.company, this.project, listId, 'state', id]),
          headers: this.getHeaders(),
          data: { state },
        },
        {
          group: 'listParticipants',
          action: 'single',
          id,
        },
      ),

      udpateNote: (listId: string, id: string, note: ListParticipants['note']) => StudioSDK.handleCall<ListParticipants>(
        {
          method: 'PUT',
          url: this.getUrl('api', ['lists', 'participants', this.company, this.project, listId, 'note', id]),
          headers: this.getHeaders(),
          data: { note },
        },
        {
          group: 'listParticipants',
          action: 'single',
          id,
        },
      ),

      remove: (listId: string, id: string) => StudioSDK.handleCall<ListParticipants>(
        {
          method: 'DELETE',
          url: this.getUrl('api', ['lists', 'participants', this.company, this.project, listId, id]),
          headers: this.getHeaders(),
        },
      ),

      list: (listId: string, site: number = 0, search?: string, tags?: string[], category?: string[], state?: string[], sort?: string, order?: number) => StudioSDK.handleCall<DataObject<ListParticipants>>(
        {
          method: 'GET',
          url: this.getUrl('api', ['lists', 'participants', this.company, this.project, listId, 'list', site]),
          headers: this.getHeaders(),
          params: {
            search,
            tags,
            sort,
            order,
            state,
            category,
          },
        },
        {
          group: 'listParticipants',
          action: 'list',
        },
      ),

    },
  };

  team = {

    find: (search: string) => StudioSDK.handleCall<any>({
      method: 'GET',
      url: this.getUrl('api', ['projects', this.company, this.project, 'team']),
      headers: this.getHeaders(),
      params: {
        search,
      },
    }),

    single: (id: string) => StudioSDK.handleCall<any>({
      method: 'GET',
      url: this.getUrl('api', ['projects', this.company, this.project, 'team', id]),
      headers: this.getHeaders(),
    }),

  };

  renderer = {
    get: (type: EditorBase['type']) => StudioSDK.handleCall<EditorBase>(
      {
        method: 'GET',
        url: this.getUrl('media', ['editor', this.company, this.project, type]),
        headers: this.getHeaders(),
      },
      {
        group: 'renderer',
        action: 'get',
      }
    ),

    update: (type: EditorBase['type']) => StudioSDK.handleCall<EditorBase>(
      {
        method: 'GET',
        url: this.getUrl('media', ['editor', this.company, this.project, 'update', type]),
        headers: this.getHeaders(),
      },
      {
        group: 'renderer',
        action: 'update',
      }
    ),

    reset: (type: EditorBase['type']) => StudioSDK.handleCall<EditorBase>(
      {
        method: 'POST',
        url: this.getUrl('media', ['editor', this.company, this.project, 'reset', type]),
        headers: this.getHeaders(),
      },
      {
        group: 'renderer',
        action: 'update',
      }
    ),

    checkUpdate: (type: EditorBase['type']) => StudioSDK.handleCall<EditorBase>(
      {
        method: 'HEAD',
        url: this.getUrl('media', ['editor', this.company, this.project, 'updateAvailable', type]),
        headers: this.getHeaders(),
      },
      {
        group: 'renderer',
        action: 'updateAvailable',
      }
    ),

  };

}
