import { Call } from '@libs/lila-call';
import { Auth } from '@libs/lila-auth';
import MainStore, { state } from '@store/main.store';
import {
  DataObject, Publish, PublishContentGroup, PublishMethod, Secret,
} from '@lilaquadrat/studio/lib/interfaces';
import GenericStore from '@interfaces/GenericStore.interface';
import StudioSDK from '@libs/StudioSDK';
import Vue from 'vue';
import BaseCallData from '@interfaces/BaseCallData.interface';
import AppPublishMainState from '../interfaces/main-state.interface';

const AppPublishStore: GenericStore<AppPublishMainState> = {

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },

  namespaced: true,
  strict: true,

  state: {
    settings: null,
    data: {},
    methods: {},
    single: null,
    singleMethod: null,
    statusTracker: [],
  },

  mutations: {

    settings(store, settings) {

      store.settings = settings;

    },

    data(store, data: DataObject<Publish>) {

      store.data = data;

    },

    methods(store, data: DataObject<Publish>) {

      store.methods = data;

    },

    single(store, data: Publish) {

      store.single = data;

    },

    singleMethod(store, data: PublishMethod) {

      store.singleMethod = data;

    },

    statusTracker(store, data: Partial<Publish>) {

      const statusTrackerIndex = store.statusTracker.findIndex((single) => single.referenceId === data.referenceId);

      if (statusTrackerIndex > -1) {

        Vue.set(store.statusTracker, statusTrackerIndex, data);

      } else {

        store.statusTracker.push(data);

      }


    },

  },

  actions: {

    updateStatus(store, _id: string) {

      const sdk = new StudioSDK('publish-app', AppPublishStore.sdkOptions());

      console.log('update status call');

      return sdk.publish.single(_id, { cacheLifetime: 15000 })
        .then((response) => {

          console.log(response);

          if (!response.isCache) store.commit('statusTracker', response.data);

          return response;

        });


    },

    getStatus(store, _id: string) {

      const sdk = new StudioSDK('publish-app', AppPublishStore.sdkOptions());

      return sdk.publish.single(_id, { cacheLifetime: 15000 });

    },

    getAffected(store, query) {

      const sdk = new StudioSDK('publish-app', AppPublishStore.sdkOptions());

      return sdk.publish.affected(query, { cacheLifetime: 15000 });

    },

    publish(store, data: Publish) {

      const sdk = new StudioSDK('publish-app', AppPublishStore.sdkOptions());

      return sdk.publish.publish(data);

    },

    getSettings(store, data) {

      console.log('GET SETTINGS', store.state.settings?.app);

      const call = new Call<any>('publish', MainStore.getters.endpoints);

      return call.get('api', `/publish/${data.company}/${data.project}/settings`, {}, Auth.getAuthHeader())
        .then((settings) => store.commit('settings', settings.r?.settings));

    },

    settings(store, data) {

      const call = new Call('publish', MainStore.getters.endpoints);

      return call.put('api', `/publish/${data.company}/${data.project}/settings`, data.settings, Auth.getAuthHeader())
        .then(() => store.dispatch('getSettings', data))
        .then((settings) => store.commit('settings', settings));

    },

    secret(store, data) {

      console.log('GET SECRET', data);

      const call = new Call<Secret>('publish', MainStore.getters.endpoints);

      return call.get('api', `/publish/${data.company}/${data.project}/secret`, {}, Auth.getAuthHeader());

    },

    refreshSecret(store, data) {

      const call = new Call('publish', MainStore.getters.endpoints);

      return call.put('api', `/publish/${data.company}/${data.project}/secret`, {}, Auth.getAuthHeader());

    },

    get(store, data: { settings: BaseCallData, query?: any }) {

      const urlArray = ['publish', data.settings.company, data.settings.project, 'list'];
      const call = new Call<DataObject<Publish>>('publish', MainStore.getters.endpoints);

      if (data.settings.site) {

        urlArray.push(data.settings.site);

      }

      return call.get('api', `/${urlArray.join('/')}`, data.query, Auth.getAuthHeader());

    },

    getMethods(store, data: { settings: BaseCallData, query?: any }) {

      const sdk = new StudioSDK('publish-app', AppPublishStore.sdkOptions());

      return sdk.publish.methods.list(+data.settings.site);

    },

    getMailFrom() {

      const sdk = new StudioSDK('publish-app', AppPublishStore.sdkOptions());

      return sdk.publish.methods.mailFrom();

    },

    addMethod(store, data: PublishMethod) {

      const sdk = new StudioSDK('publish-app', AppPublishStore.sdkOptions());

      return sdk.publish.methods.add(data);

    },

    activeMethods(store, data: { app: string, contentGroups: PublishContentGroup[] }) {

      const sdk = new StudioSDK('publish-app', AppPublishStore.sdkOptions());

      return sdk.publish.methods.active(data.app, data.contentGroups);

    },

    updateMethod(store, data: { data: PublishMethod, id: string }) {

      const sdk = new StudioSDK('publish-app', AppPublishStore.sdkOptions());

      return sdk.publish.methods.update(data.id, data.data);

    },

    removeMethod(store, id: string) {

      const sdk = new StudioSDK('publish-app', AppPublishStore.sdkOptions());

      return sdk.publish.methods.remove(id);

    },

    singleMethod(store, data: { settings: BaseCallData, _id?: string }) {

      const sdk = new StudioSDK('publish-app', AppPublishStore.sdkOptions());

      return sdk.publish.methods.single(data._id);

    },

    single(store, data: { settings: BaseCallData, _id?: string }) {

      const sdk = new StudioSDK('publish-app', AppPublishStore.sdkOptions());

      return sdk.publish.single(data._id);

    },

    cancel(store, data: { settings: BaseCallData, _id?: Publish['_id'] }) {

      const urlArray = ['publish', data.settings.company, data.settings.project, 'cancel', data._id];
      const call = new Call<DataObject<Publish>>('publish', MainStore.getters.endpoints);

      return call.put('api', `/${urlArray.join('/')}`, {}, Auth.getAuthHeader());

    },

    activate(store, params) {

      if (!store.state.settings?.app) {

        return store.dispatch('getSettings', params);

      }

      return Promise.resolve(true);

    },

  },

};

export default AppPublishStore;
