export default (value: string[]) => {

  const returnValue = [];

  value?.forEach((single) => {

    if (!single.match('^category:')) return;

    returnValue.push(single.slice(9));

  });

  return returnValue.join(', ');

};
