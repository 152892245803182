/* eslint-disable max-len */
export default {
  home: 'Home',
  notifications: 'Benachrichtigungen',
  notice: 'Hinweis',
  total: 'Gesamt',
  logout: 'Logout',
  settings: 'Einstellungen',
  name: 'Name',
  projects: 'Projekte',
  project: 'Projekt',
  data: 'Datensätze',
  state: 'Status',
  media: 'Medien',
  filter: 'Filter',
  filename: 'Dateiname',
  search: 'Suche',
  tags: 'Tags',
  required: 'erforderlich',
  disabled: 'deaktiviert',
  saving: 'wird gespeichert',
  saved: 'gespeichert',
  error: 'Fehler',
  domain: 'Domain',
  active: 'Aktiv',
  host: 'Host',
  port: 'Port',
  username: 'Benutzername',
  password: 'Passwort',
  mode: 'Mode',
  cancel: 'abbrechen',
  content: 'Inhalt',
  publish: 'Publish',
  publishMethods: 'Publish Methoden',
  draft: 'Entwurf',
  back: 'zurück',
  sitetitle: 'Seitentitel',
  save: 'speichern',
  new: 'neu',
  changes: 'Änderungen',
  exit: 'exit',
  modules: 'Module',
  overview: 'Übersicht',
  picture: 'Bild',
  pictures: 'Bilder',
  background: 'Hintergrund',
  options: 'Optionen',
  textblock: 'Text',
  variant: 'Variante',
  font_variant: 'Schriftvariante',
  headline: 'Überschrift',
  subline: 'Subline',
  intro: 'Intro',
  list: 'Liste',
  attributes: 'Eigenschaften',
  classes: 'Klassen',
  tools: 'Tools',
  import: 'Importieren',
  export: 'Exportieren',
  elements: 'Elemente',
  history: 'Historie',
  date: 'Datum',
  user: 'User',
  reason: 'Grund',
  older: 'älter',
  newer: 'neuer',
  current: 'aktuell',
  legal: 'Rechtliches',
  contact: 'Kontakt',
  social: 'Social Media',
  'no id': 'Keine ID',
  from: 'von',
  'clone of': 'Kopie von',
  'back to overview': 'Zurück zur Übersicht',
  prename: 'Vorname',
  lastname: 'Nachname',
  personal_data: 'Persönliches',
  processing: 'wird verarbeitet',
  general: 'Allgemein',
  remove: 'entfernen',
  folder: 'Ordner',
  success: 'Erfolg',

  confirm: 'bestätigen',
  confirmed: 'bestätigt',

  'date & time': 'Datum & Uhrzeit',

  company: 'Firma',
  description: 'Beschreibung',

  TERMS_CONDITIONS: 'Nutzungsvereinbarung',
  ACCEPT_TERMS_CONDITIONS: 'Nutzungsvereinbarung akzeptiert',

  SET_AS_HOME: 'Zur Startseite machen',
  THIS_IS_YOUR_HOME: 'Das ist meine Startseite',

  ME_SETTINGS: 'Meine Einstellungen',
  COMPANY_SETTINGS: 'Firmeneinstellungen',
  PROJECT_SETTINGS: 'Projekteinstellungen',
  PROJECT_MAIN_SETTINGS: 'Allgemeine Einstellungen',

  FILE_DUPLICATE: 'Datei bereits vorhanden',
  IMAGE_INVALID: 'Bildatei fehlerhaft',

  CONTENT_SPECIFIC_SETTTINGS: 'Datentypeinstellungen',

  HOSTING_SETTINGS: 'Hostingeinstellungen',
  'use internal hosting': 'Internes Hosting verwenden',
  hosting: 'Hosting',
  'internal hosting': 'Internes Hosting',

  'copy to clipboard': 'kopieren',

  'activating hosting': 'Hosting aktivieren',
  'it takes a few moments to enable or disable the hosting.': 'Es dauert ein paar Minuten, bis das Hosting de - oder aktiviert wird.',

  'you are able to use the internal hosting solution for projects that are available to the public.': 'Mit dem internen Hosting können Projekte für die Öffentlichkeit zugänglich gemacht werden.',

  'internal hosting has to be active, to use this publish method.': 'Um diese Publishmethode zu nutzen, muss das Interne Hosting aktiviert sein.',

  CREATE_COMPANY: 'Firma anlegen',
  'create company': 'Firma anlegen',
  CREATE_PROJECT: 'Projekt anlegen',
  'create project': 'Projekt anlegen',

  SAVE_CHANGES: 'Änderungen speichern',

  LAST_UPDATE: 'Update',
  LAST_PUBLISH: 'Publish',

  NEW_DATA: 'Datensatz anlegen',
  CREATE_DATA: 'Datensatz anlegen',
  OPEN_FILTERMENU: 'Filtermenü öffnen',

  COMPLETE_REGISTRATION: 'Registrierung abschließen',

  'ADD DOMAIN': 'Domain hinzufügen',
  'REMOVE DOMAIN': 'Domain entfernen',
  'CHECK DNS': 'DNS Eintrag überprüfen',
  'DNS CHECK FAILED': 'DNS konnte nicht gefunden werden',
  'DNS CHECK ONGOING': 'DNS wird überpüft',
  'DNS CHECK SUCCESFULL': 'DNS validiert',
  'domain validation': 'Domain validierung',

  'short description of this project': 'Kurze Beschreibung zu diesem Projekt',
  'upload new files': 'Neue Dateien hochladen',
  'filename has to be set to deploy via ftp': 'Dateiname muss vorhanden sein um via FTP zu veröffentlichen.',
  'filename has to be set to deploy to the internal hosting': 'Dateiname muss vorhanden sein um via Intern zu veröffentlichen.',
  'The url that gets passed to the router': 'Diese URL wird an den Router übergeben',
  'deactivate Server Side Rendering': 'Serverrendering deaktivieren',
  'deactivate rendering': 'Rendering deaktivieren',
  'Doesnt create a prerendered html and forces the app to render it live.': 'Prerendering wird deaktiviert und das APP wird gezwungen live zu rendern.',
  'This content is no site on its own, its used for a different purpose.': 'Dieser Datensatz ist keine eigenständige Seite.',

  'Be aware that you will overwrite all changes that are made after this version': 'Alle Änderungen die nach diesem Eintrag gemacht wurden, werden überschrieben.',
  'This will become the current version': 'Diese Version wird zur aktuellen Version.',

  'we have to validate that you are the owner of this domain. you need to add the following DNS entry to you domain:': 'Es wird Zugriff auf den DNS Server benötigt um die Domain hinzuzufügen. Der folgenden DNS Eintrag muss hinzugefügt werden:',

  'if the domain gets removed, the project will not be available under this domain anymore.': 'Wenn diese Domain entfernt wird, ist das Projekt nicht mehr unter dieser Domain erreichbar.',

  'The domain will get a free letsencrypt certificate to enable SSL': 'Diese Domain erhält ein kostenloses letsencrypt Zertifikate um SSL zu aktivieren',

  'This site will not be altered': 'Dieser Eintrag wird nicht verändert.',

  'start editing this history entry': 'Diesen Eintrag bearbeiten',

  'create a copy from this history': 'Kopie von diesem Eintrag anlegen',

  'start editing the current version': 'Aktuelle Version bearbeiten',

  'copy the content and import it in your target data': 'Den Inhalt kopieren und im gewünschten Datensatz importieren',

  'input data has error': 'Die eigegebenen Daten sind fehlerhaft',

  'input data is empty': 'Die eigegebenen Daten sind leer',

  'url if the picture is not hosted in media': 'Link zum Bild, wenn Medien nicht genutzt wird.',

  'remove this module': 'Diese Modul entfernen',
  'clone this module': 'Dieses Modul kopieren',

  'trigger data hook for all': 'Alles via Datahook',
  'trigger data hook for this': 'Diesen Datensatz via Datahook',

  'trigger ftp for all': 'Alles via FTP',
  'trigger ftp for this': 'Diesen Datensatz via FTP',

  'trigger internal for all': 'Alles via Intern',
  'trigger internal for this': 'Diesen Datensatz via Intern',

  'module or partial name': 'Modul oder Partialname',

  'You have unsaved changes, do you really want to leave?': 'Änderungen wurde noch nicht gespeichert, diese Seite wirklich verlassen?',

  internal: 'Internes Hosting',

  ALT_TEXT: 'Alternativer Text',

  ADD_FILES: 'Dateien hochladen',
  MEDIA_UPLOAD_HISTORY: 'Kürzliche Uploads',

  ADD_NEW_MODULE: 'Modul hinzufügen',
  SHOW_CONTENT: 'Inhalte anzeigen',
  ADD_WITH_ENTER: 'Enter zum hinzufügen',

  REMOVE_MEDIA: 'Entfernen',
  REFRESH_MEDIA: 'aktualisieren',

  ADD_NEW_ELEMENT: 'Neues Element hinzufügen',
  NO_ATTRIBUTES_CHOOSEN: 'Keine Eigenschaft ausgewählt',
  NO_VARIANTS_CHOOSEN: 'Keine Varianten ausgewählt',
  NO_VARIANTS_AVAILABLE: 'Keine Varianten verfügbar',

  DATA_SETTINGS: 'Einstellungen',
  HTML_SPECIFIC: 'HTML spezifisch',

  DELETE_THIS_DATA: 'Diesen Datensatz löschen',
  DUPLICATE_THIS_DATA: 'Diesen Datensatz kopieren',

  ID_DESCRIPTION: 'Interne ID um diesen Datensatz wieder zu finden.',
  PARTIAL_DESCRIPTION: 'Dieser Datensatz ist ein Partial und wird in einem größeren Kontext genutzt.',

  DATA_DESCRIPTION: 'Kurze Beschreibung für diesen Datensatz',
  DATA_DESCRIPTION_NAME: 'Beschreibung',

  TITLE_PREVIEW: 'Seitentitel Vorschau',
  TITLE_DESCRIPTION: 'Wenn ein Seitentitel angeben wird, wird der kurze Seitentitel aus den Projekteinstellungen angehängt. Wenn Seitentitel im Datensatz leer gelassen wird, wird die lange Version aus den Projekteinstellungen angezeigt.',

  DESCRIPTION_TITLE: 'Meta Description',
  DESCRIPTION_PREVIEW: 'Meta Description Vorschau',
  DESCRIPTION_DESCRIPTION: 'Wenn eine Meta Description angeben wird, wird die kurze Meta Description aus den Projekteinstellungen angehängt. Wenn die Meta Description im Datensatz leer gelassen wird, wird die lange Version aus den Projekteinstellungen angezeigt.',

  PUBLISH_DESCRIPTION: 'Um einen Datensatz veröffentlichen zu können, werden die notwendigen Rechte im Projekt benötigt und mindestens eine Publish Einstellung muss aktiv gesetzt sein.',

  MEDIA_SELECT: 'Medien wählen',
  NO_VIDEO_PREVIEW: 'Keine Vorschau vorhanden',
  MEDIA_URL: 'URL',

  'blog-intro-module': 'Blog Intro Modul',
  'picture-module': 'Bild Modul',
  'emotion-module': 'Emotion Modul',
  'navigation-module': 'Navigation Modul',
  'contact-module': 'Kontakt Modul',
  'gallery-module': 'Gallery Modul',
  'picturegroup-module': 'Bildergruppe Modul',
  'pictureandtext-module': 'Bild und Text Modul',
  'facts-module': 'Fakten Modul',
  'menu-module': 'Menu Modul',
  'video-module': 'Video Modul',
  'partial-module': 'Partial Modul',
  'faq-module': 'FAQ Modul',
  'index-module': 'Index Modul',
  'prices-module': 'Preise Modul',
  'training-module': 'Training Modul',
  'footer-module': 'Footer Modul',
  'placeholder-module': 'Platzhalter Modul',
  'compare-module': 'Vergleich Modul',
  'text-module': 'Text Modul',
  'cookies-module': 'Cookies Modul',
  'quellcode-module': 'Quellcode Modul',
  'quote-module': 'Zitat Modul',
  'events-list-module': 'Events Liste Modul',
  'event-module': 'Event Modul',
  'story-module': 'Story Modul',
  'location-module': 'Karten Modul',

  'event-module-start': 'Beginn',
  'event-module-end': 'Ende',
  'event-module-artist': 'Künstler',
  'event-module-location': 'Veranstaltungsort',
  'event-module-name': 'Veranstaltungsname',
  'event-module-infos': 'Allgemeine Informationen',

  'location-module-google-maps': 'Google Maps Url',
  'location-module-google-maps-description': 'Bitte wähle die gewünschte Adresse unter https://www.google.com/maps aus. Anschließend kannst du die erstellte URL über die Option "Teilen" -> "Karte einbetten" abrufen und hier einfügen. Nur die Url ohne iframe etc.',

  history_create: 'angelegt',
  history_change: 'bearbeitet',

  view: 'anzeigen',

  contract: 'Vertrag',

  'key account manager': 'Verantwortliche Ansprechpartner',

  edit: 'bearbeiten',

  or: 'oder',

  UNSUPPORTED_TYPE: 'Dateityp nicht unterstutzt',

  tablet: 'Tablet',
  desktop: 'Desktop',
  wide: 'Widescreen',

  MODE_PRESENTATION: 'Präsentation',
  MODE_CONTENT: 'Einzelner Inhalt',

  POSITION_TOP: 'Kopfzeile',
  POSITION_CONTENT: 'Inhalt',
  POSITION_BOTTOM: 'Fußzeile',

  position: 'Position',

  author: 'Autor',

  'me-settings': 'Einstellungen',
  'project-settings-home': 'Einstellungen',
  'me-settings-overview': 'Einstellungen',
  'me-settings-personal': 'Persönliche Daten',
  'project-settings': 'Allgemeine Einstellungen',
  'project-settings-publish': 'Publish',
  'project-settings-editor': 'Editor',
  'project-settings-content-html': 'html',
  'project-settings-hosting': 'Hosting',
  'project-settings-domain': 'Domain',
  'project-settings-domain-add': 'Domain hinzufügen',
  'team-home-project': 'Team',
  'team-add-project': 'Mitarbeiter hinzufügen',
  'team-edit-project': 'Mitarbeiter bearbeiten',
  'team-home-company': 'Team',
  'team-add-company': 'Mitglied hinzufügen',
  'team-edit-company': 'Mitglied bearbeiten',

  'project-id': 'ID',
  'project-id-format': 'Maximal 15 Zeichen, nur Zahlen und Buchstaben',
  'project-name': 'Name',

  'project-settings-auth0': 'Auth0',
  'project-settings-auth0-domain': 'Domain',
  'project-settings-auth0-description': 'Falls das App / die Website einen Login für Nutzer hat, muss hier die Domain aus Auth0 hinterlegt werden.',

  'personal data': 'Persönliche Daten',
  personal: 'Persönlich',
  'add member': 'Mitglied hinzufügen',
  ADD_USER: 'Benutzer hinzufügen',
  'user last name': 'Nachname des Benutzers',

  'html settings': 'HTML Einstellungen',
  'Title & Description': 'Titel und Beschreibung',
  'Sitetitle Full': 'Kompletter Seitentitel',
  'Sitetitle Short': 'Kurzer Seitentitel',
  Sitetitle: 'Seitentitel',
  Description: 'Beschreibung',
  'Description Full': 'Komplette Beschreibung',
  'Description Short': 'Kurze Beschreibung',
  Images: 'Bilder',
  'Deactivate async image loading': 'Deaktiviere asynchrones Bilder laden',
  'If async loading if deactivated, all images will be loaded traditionally via html instead of javascript': 'Wenn asynchrones Lden deaktiviert ist, werden alle Bilder mit HTML geladen anstelle von javascript',
  'Save Changes': 'Änderungen speichern',

  'short description of this company': 'Kurzbeschreibung dieser Firma',

  REMOVE_SELECTED: 'AUSWAHL AUFHEBEN',

  REMOVE_PERMISSIONS: 'Berechtigung entfernen',

  NO_LAYOUT_CHOOSEN: 'Kein Layout gewählt',
  INHERITED_FROM_LAYOUT: 'von Layout geerbt',

  SELECT_FILE: 'Datei auswählen',
  SELECT_NEW_FILE: 'Neue Datei auswählen',
  USE_THIS: 'Auswählen',

  APPS_ACTIVE: 'Aktive Apps',

  LAYOUT_NAME: 'Layout',

  'app-project-settings': 'Einstellungen',

  'permissions-model': 'Berechtigung',

  available: 'verfügbar',

  DRAG_AND_DROP_FILES: 'Dateien hier ablegen',

  CLICK_HERE_TO_ADD_FILE: 'Hier klicken um Dateien auszuwahlen',

  NO_FILE: 'Keine Datei vorhanden',

  PUBLISH_STATE: 'Publish Status',

  category: 'Kategorie',

  'company-representive': 'Vertretungsberechtigter Kontakt',
  'company-id': 'ID',

  'company-vat': 'Ust. ID',

  'company-register': 'Handelsregister',
  'company-address': 'Anschrift',
  'company-legal': 'Rechtliches',
  'company-contact': 'Kontaktdaten',
  'company-name': 'Firmenname',
  'company-city': 'Stadt',
  'company-streetNumber': 'Strasse & Hausnummer',
  'company-zipcode': 'Postleitzahl',
  'company-country': 'Land',
  'company-internet': 'Homepage',
  'company-phone': 'Telefon',
  'company-attributes': 'Eigenschaften',

  CONTENT_NOT_FOUND: 'Inhalt nicht gefunden',
  'Something went wrong': 'Da ist etwas schiefgegangen',
  'The content is missing': 'Der Inalt wurde nicht gefunden',
  'Click here': 'Hier klicken',
  'to show terms and conditions.': 'um Nutzungsvereinbarung anzuzeigen.',
  'Terms and conditions are already accepted.': 'Nutzungsvereinbarung wurden bereits akzeptiert.',

  'Click to (un)select all.': 'Klicken um alle an/abwaehlen.',

  MONTH_1: 'Januar',
  MONTH_2: 'Februar',
  MONTH_3: 'Maerz',
  MONTH_4: 'April',
  MONTH_5: 'Mai',
  MONTH_6: 'Juni',
  MONTH_7: 'Juli',
  MONTH_8: 'August',
  MONTH_9: 'September',
  MONTH_10: 'Oktober',
  MONTH_11: 'November',
  MONTH_12: 'Dezember',

  'ERROR-400': 'Bitte prüfe deine Eingaben',
  'ERROR-403': 'Du hast nicht die notwendigen Rechte',

  USER_NOT_FOUND: 'Benutzer wurde nicht gefunden',

  'button-action-error': 'Aktion fehlgeschlagen',

  'validation-error-type-number': 'muss eine Zahl sein',
  'validation-error-type-string': 'muss Text sein',
  'validation-error-minLength': 'muss mindestens %s Zeichen lang sein',
  'validation-error-maxLength': 'darf maximal %s Zeichen lang sein',
  'validation-error-additionalProperties': 'Das zusätzliche Attribut "%s" ist nicht erlaubt',
  'validation-error-maxItems': 'darf maximal %s Elemente besitzen',
  'validation-error-minItems': 'muss mindestens %s Elemente besitzen',
  'validation-error-contains': 'muss mindestens %s valide(s) Element(e) besitzen',
  'validation-error-required': 'muss gesetzt sein',
  'validation-error-enum': 'muss einer der folgenden Werte sein: %s',
  'validation-error-pattern': 'muss im format %s sein',
  'validation-error-DOCUMENT_ALREADY_EXISTS': 'Ein Datensatz mit der Id "%s" existiert bereits.',

  'model-editor': 'Editor Datensätze',
  'model-editorBase': 'Editor Design',
  'model-media': 'Mediendateien',
  'model-permissions': 'Team',
  'model-publish': 'Publish',
  'model-publishMethods': 'Publish Methoden',

  MORE_MODULE_OPTIONS: 'Weitere Optionen',
  NO_ENTRIES: 'Keine passenden Ergebnisse',
  RECENT_SELECTED: 'Vorherige Auswahl',

  'app-preview': 'Vorschau',
  'app-internal': 'Vorschau',

  'country-deu': 'Deutschland',
  'country-aut': 'Österreich',
  'country-che': 'Schweiz', 

  'company-country-select': 'Land wählen',

  'date-picker-fromAfterTo': 'Der Endzeitpunkt liegt vor dem Startzeitpunkt',
  'date-picker-from': 'Von',
  'date-picker-to': 'Bis',
  'date-picker-future': 'Zeitpunkt muss in der Zukunft liegen',

  'application/pdf': 'PDF',
  'gender-m': 'männlich',
  'gender-w': 'weiblich',
  'gender-d': 'divers',

  copy: 'kopieren',
  copied: 'kopiert',

  paste: 'einfügen',
  pasted: 'eingefügt',

  favorites: 'Favoriten',
  'all projects':'Alle Projekte',
  'apps':'Apps',

  'app-settings-company-home': 'Firmeneinstellungen',
  'app-settings': 'Einstellungen',

  'partner': 'Partner',

  'yes': 'Ja',
  'no': 'Nein',

  PICTURE_NOT_LOADED_ERROR: 'Bild konnte nicht geladen werden',
  datetimepicker_remove_date: 'Auswahl entfernen',

  'list-model': 'Liste verbinden',
  'callToAction': 'Call To Action',

  'element-no-title': 'Kein Titel',
  'text-position': 'Text Position',

  'datepicker-range-days_singular'                                                                        : '%s Tag',
  'datepicker-range-days_plural'                                                                          : '%s Tage',
  'datepicker-predefined-now'                                                                             : 'jetzt',
  'datepicker-select-button'                                                                              : 'Datum auswählen',
  'datepicker-select-time-button'                                                                         : 'Zeit auswählen',
  'datepicker-remove-button'                                                                         : 'Datum entfernen'
};
