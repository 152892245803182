// import PartialModel from '@libs/lila-model';

// export default class ProjectHostingModel extends PartialModel {
//   active: boolean = false;
// }

import Models, { ModelDeclaration, ModelFunctions } from '@libs/Models.class';

export default interface ProjectHosting {
  active: boolean
}

const declaration: ModelDeclaration<ProjectHosting> = {
  active: { type: 'boolean', default: false },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};

Models.register('project-hosting', declaration, functions);