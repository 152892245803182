<template>
  <article :class="navigationAnimation" />
</template>

<script lang="ts">
import {
  ExtComponent, Component, Watch, Prop,
} from '@libs/lila-component';
import { Call } from '@libs/lila-call';
import auth from '@libs/lila-auth';
import loginCallbackHook from '@mixins/loginCallbackHook';

@Component
// eslint-disable-next-line global-require
export default class LoginScreen extends ExtComponent {

  token: string;

  mounted() {

    console.log(this.$route.name);

    if (this.$route.name === 'login-callback') {

      this.callback();

    }

    if (this.$route.name === 'login') {

      this.login();

    }

  }

  // eslint-disable-next-line class-methods-use-this
  login(): void {

    auth.authorize();

  }

  async callback() {


    try {

      await auth.handle();
      await loginCallbackHook(this.$store, this.$router);
      this.$router.push({ name: 'me-home' });
      
    } catch (error) {

      console.error(error);
    
    }


  }

}
</script>
