export default function Keyboard(vueInstance: Vue): void {

  window.addEventListener('keydown', (event: KeyboardEvent) => {

    if (event.ctrlKey) {

      if (['s', 'o', 'p'].includes(event.key)) {

        vueInstance.$emit('command', event.key);
        event.preventDefault();

      }

      if (['c', 'v'].includes(event.key)) {

        if (!window.getSelection().toString().length && !['INPUT', 'TEXTAREA'].includes(document.activeElement.nodeName)) {

          vueInstance.$emit('command', event.key);
          event.preventDefault();

        }

      }

    }

  });

}
