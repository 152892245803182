// import PartialModel from '@libs/lila-model';

// export default class ChildDataModel extends PartialModel {

//   index: string[] = [];

//   data: any = {};

//   // eslint-disable-next-line class-methods-use-this
//   onSave = (data: any) => {

//     const cleanedData = data;

//     delete cleanedData.data;

//     return cleanedData;

//   };

// }

import Models, { ModelDeclaration } from '@libs/Models.class';

export default interface ChildData {
    index: string[]
    data: object
}

const declaration: ModelDeclaration<ChildData> = {
    index: { type: 'array', contains: { type: 'string' } },
    // data: { model: 'string', default: '' },
    // eslint-disable-next-line class-methods-use-this
    // onSave = (data: any) => {
    //   const cleanedData = data;

    //   delete cleanedData.data;

    //   return cleanedData;
    // },
};

Models.register('child-data', declaration);
