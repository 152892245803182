import { UnifiedRouteGuard } from '@libs/lila-auth';

const loadScreen = (screen: string) => () => import(
  /* webpackChunkName: "app-hostingadmin" */
  `./screens/${screen}`
).then((m) => m.default);

export default [
  {
    path: '/:company/a/hostingadmin',
    name: 'app-hostingadmin-company-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home.screen.vue'),
    meta: {
      animationGroup: 'hostingadmin',
      app: 'hostingadmin',
      parent: true,
      rememberSettings: true,
      settingsKey: 'hosting'
    },
    children: [
      {
        path: 'settings',
        component: loadScreen('settings.screen.vue'),
        meta: {
          sidescreen: true,
          defaultRoute: 'app-hostingadmin-company-settings-overview',
          app: 'editor',
        },
        children: [
          {
            path: 'base',
            name: 'app-hostingadmin-company-settings-base',
            component: loadScreen('settings-base.screen.vue'),
            meta: {
              sidescreen: true,
              name: 'app-hostingadmin-company-settings-base',
              app: 'editor',
            },
          },
          {
            path: 'main',
            name: 'app-hostingadmin-company-settings-main',
            component: loadScreen('settings-main.screen.vue'),
            meta: {
              sidescreen: true,
              name: 'app-hostingadmin-company-settings-main',
              app: 'editor',
            },
          },
          {
            path: '',
            name: 'app-hostingadmin-company-settings-overview',
            component: loadScreen('settings-overview.screen.vue'),
            meta: {
              sidescreen: true,
              name: 'app-hostingadmin-company-settings-overview',
              app: 'editor',
            },
          },
        ],
      },
      {
        path: 'add',
        component: loadScreen('single.screen.vue'),
        name: 'app-hostingadmin-company-add',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-hostingadmin-company-add',
          app: 'hostingadmin',
        },
      },
    ],
  },
  {
    path: '/:company/a/hostingadmin/certificatesActions/:site?',
    name: 'app-hostingadmin-company-certificatesActions-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home-certificates-actions.screen.vue'),
    meta: {
      animationGroup: 'hostingadmin',
      app: 'hostingadmin',
      parent: true,
      rememberSettings: true,
      settingsKey: 'certificates',
      routeSub: 'app-hostingadmin-certificatesActions'
    },
    children: [
      {
        path: 'single/:id',
        component: loadScreen('single-certificates-actions.screen.vue'),
        name: 'app-hostingadmin-company-certificatesActions-single',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-hostingadmin-company-certificatesActions-single',
          app: 'hostingadmin',
          routeSub: 'app-hostingadmin-certificatesActions'
        },
      },
    ]
  },
  {
    path: '/:company/a/hostingadmin/domains/:site?',
    name: 'app-hostingadmin-company-domains-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home-domains.screen.vue'),
    meta: {
      animationGroup: 'hostingadmin',
      app: 'hostingadmin',
      parent: true,
      rememberSettings: true,
      settingsKey: 'domains',
      routeSub: 'app-hostingadmin-domains'
    },
    children: [
      {
        path: 'single/:id',
        component: loadScreen('single-domains.screen.vue'),
        name: 'app-hostingadmin-company-domains-single',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-hostingadmin-company-domains-single',
          app: 'hostingadmin',
          routeSub: 'app-hostingadmin-domains'
        },
      },
    ]
  },
  {
    path: '/:company/a/hostingadmin/commands/:site?',
    name: 'app-hostingadmin-company-commands-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home-commands.screen.vue'),
    meta: {
      animationGroup: 'hostingadmin',
      app: 'hostingadmin',
      parent: true,
      rememberSettings: true,
      settingsKey: 'commands',
      routeSub: 'app-hostingadmin-commands'
    },
    children: [
      {
        path: 'single/:id',
        component: loadScreen('single-commands.screen.vue'),
        name: 'app-hostingadmin-company-commands-single',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-hostingadmin-company-commands-single',
          app: 'hostingadmin',
          routeSub: 'app-hostingadmin-commands'
        },
      },
      {
        path: 'add',
        component: loadScreen('add-commands.screen.vue'),
        name: 'app-hostingadmin-company-commands-add',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-hostingadmin-company-commands-add',
          app: 'hostingadmin',
          routeSub: 'app-hostingadmin-commands'
        },
      },
    ]
  }
];
