import { Route } from 'vue-router';
import equal from 'fast-deep-equal';

class Settings {

  storage: Storage;

  prefix: string = 'studio';

  constructor(prefix?: string) {

    this.storage = window.localStorage;
    if (prefix) this.prefix = prefix;

  }

  update(key: string, value: unknown) {

    this.storage.setItem(Settings.prefixKey(key, this.prefix), JSON.stringify(value));

  }

  remove(key: string) {

    this.storage.removeItem(Settings.prefixKey(key, this.prefix));

  }

  get(key: string): any {

    const item = this.storage.getItem(Settings.prefixKey(key, this.prefix));


    return item ? JSON.parse(item) : undefined;

  }

  clean() {

    this.storage.clear();

  }

  static prefixKey(key: string, prefix: string) {

    return `${prefix}.${key}`;

  }

  /**
   * create a key for storage of used setttings
   * ```
   * /company/project/app
   * ```
   * becomes
   * ```
   * app-company-project
   * ```
   */
  static getKeyAppRoute(route: Route) {

    /**
     * used the meta.app and route.meta.settingsKey 
     * with settingsKey it is possible to have more than one set of settings inside of one app
     */
    const keyArray = [route.meta.app, route.params.company, route.params.project, route.meta.settingsKey];

    /**
    * add route params to the key
    */
    if (route.meta.addParams) {

      route.meta.addParams.forEach((single) => {

        if (route.params[single]) keyArray.push(route.params[single]);

      });

    }

    return keyArray.filter((single) => single).join('-');

  }

  /**
   * loads settings from localstorage and adds them as parameters
   */
  routeGuard(to: Route, next: (...unknown) => void) {

    const storedSettings = this.get(Settings.getKeyAppRoute(to));

    // if there are stored settings and the current query empty
    if (to.meta.rememberSettings && storedSettings && equal(to.query, {}) && !to.params.site) {

      // add settingsRedirected to skip routerLeave guards
      return next({
        ...to,
        query: storedSettings.query,
        params: {
          ...to.params,
          site: storedSettings.site,
          settingsRedirected: true
        },
        replace: true,
      });

    }

    return next();

  }

}

export default new Settings();
export {
  Settings,
};
