import { Call } from '@libs/lila-call';
import { Auth } from '@libs/lila-auth';
import MainStore, { state } from '@store/main.store';
import StudioSDK from '@libs/StudioSDK';
import GenericStore from '@interfaces/GenericStore.interface';
import ProjectStoreState from './ProjectStoreState.interface';

const ProjectStore: GenericStore<ProjectStoreState> = {

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },

  namespaced: true,
  strict: true,

  state: {
    settings: null,
    project: null,
    permissions: null,
  },

  mutations: {

    settings(localState, settings) {

      localState.settings = settings;

    },

    project(localState, project: string) {

      localState.project = project;

    },

    permissions(localState, permission) {

      localState.permissions = permission;

    },

  },

  actions: {
    create(store, data) {

      console.log('CREATE', store);

      const call = new Call('system', MainStore.getters.endpoints);

      return call.post('api', `/projects/${data.company}/create`, data, Auth.getAuthHeader());

    },

    getSettings(store, data: { company: string, project: string, details?: boolean }) {

      console.log('GET SETTINGS', store, data, store.state);

      const sdk = new StudioSDK('projects', ProjectStore.sdkOptions());

      return sdk.projects.single(data);

    },

    saveSettings(store, data) {

      const call = new Call('system', MainStore.getters.endpoints);

      return call.put('api', `/projects/${data.company}/${data.project}`, data.settings, Auth.getAuthHeader())
        .then(() => store.dispatch('getSettings', data))
        .then((settings) => store.commit('settings', settings.data));

    },

    toggleHosting(store, data) {

      const call = new Call('system', MainStore.getters.endpoints);

      return call.post('api', `/hosting/${data.company}/${data.project}/toggle`, { hosting: data.hosting }, Auth.getAuthHeader());

    },

    unset(store) {

      store.commit('settings', null);
      store.commit('project', null);
      store.commit('permissions', null);


    },
  },

};

export default ProjectStore;
