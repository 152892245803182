import { UnifiedRouteGuard } from '@libs/lila-auth';

const loadScreen = (screen: string) => () => import(
  /* webpackChunkName: "app-tracker" */
  `./screens/${screen}`
).then((m) => m.default);

export default [
  {
    path: '',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('overview.screen.vue'),
    meta: {
      app: 'tracker',
    },
    children: [
      {
        path: '/:company/:project/a/tracker/statistics/:month?/:day?',
        name: 'app-tracker-project-statistics',
        beforeEnter: UnifiedRouteGuard,
        component: loadScreen('statistics.screen.vue'),
        meta: {
          app: 'tracker',
          noScroll: true,
          rememberSettings: true,
        },
      },
      {
        path: '/:company/:project/a/tracker/:site?',
        name: 'app-tracker-project-home',
        beforeEnter: UnifiedRouteGuard,
        component: loadScreen('home.screen.vue'),
        meta: {
          app: 'tracker',
          noScroll: true,
          parent: true,
          rememberSettings: true,
        },
        children: [
          {
            path: 'single/:id',
            name: 'app-tracker-project-single',
            beforeEnter: UnifiedRouteGuard,
            component: loadScreen('single.screen.vue'),
            meta: {
              app: 'tracker',
              sidescreen: true,
              noScroll: true,
              defaultRoute: 'app-tracker-project-single',
            },
          },
          {
            path: 'add',
            name: 'app-tracker-project-add',
            beforeEnter: UnifiedRouteGuard,
            component: loadScreen('add.screen.vue'),
            meta: {
              app: 'tracker',
              sidescreen: true,
              noScroll: true,
              defaultRoute: 'app-tracker-project-add',
            },
          },
          {
            path: 'add-multiple',
            name: 'app-tracker-project-add-multiple',
            beforeEnter: UnifiedRouteGuard,
            component: loadScreen('add-multiple.screen.vue'),
            meta: {
              app: 'tracker',
              sidescreen: true,
              noScroll: true,
              defaultRoute: 'app-tracker-project-add',
            },
          },
        ],
      },
    ],
  },
];
