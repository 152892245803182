// import PartialModel from '@libs/lila-model';
// import PicturegroupElementModel from '@models/PicturegroupElement.model';
// import LinkPartialModel from './Link.model';

// export default class FooterSocialModel extends PartialModel {

//   title = '';

//   link = LinkPartialModel;

//   elements = [PicturegroupElementModel];

// }
import Models, { ModelDeclaration, ModelFunctions } from '@libs/Models.class';
import Link from './Link.model';
import PicturegroupElement from './PicturegroupElement.model';

export default interface FooterSocial {
  title: string
  link: Link
  elements: PicturegroupElement[]
}

const declaration: ModelDeclaration<FooterSocial> = {
  title: { type: 'string' },
  link: { model: 'link' },
  elements: { type: 'array', contains: { model: 'picturegroup-element' } },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};

Models.register('footer-social', declaration, functions);