// import PartialModel from '@libs/lila-model';

// export default abstract class ModulePdfModel extends PartialModel {

//   newSiteAfter: boolean = false;

//   newSiteBefore: boolean = false;

// }
import Models, { ModelDeclaration } from '@libs/Models.class';

export default interface ModulePdf {
  newSiteAfter: boolean
  newSiteBefore: boolean
}

const declaration: ModelDeclaration<ModulePdf> = {
  newSiteAfter: { type: 'boolean', default: false },
  newSiteBefore: { type: 'boolean', default: false },
};

Models.register('module-pdf', declaration);