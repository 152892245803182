import { AppsPlugin } from '@plugins/apps';
import MainStoreState from '@store/mainStoreState.interface';
import { Store } from 'vuex';
import Router from 'vue-router';
import log from 'loglevel';

export default (store: Store<MainStoreState>, router: Router) => {

    log.info('%c[STUDIO-APP]%c%c[HOOK]%c init', 'background: #3f2d56; color: #CCC;', 'padding: 10px', 'background: #5A8C99; color: #FFF;');

    AppsPlugin.installOverlayApps(store, router);

    return Promise.resolve();

};