// import PartialModel from '@libs/lila-model';

// export default class ProjectEditorModel extends PartialModel {

//   studioUrl: string = '';

// }

import Models, { ModelDeclaration, ModelFunctions } from '@libs/Models.class';

export default interface ProjectEditor {
  studioURL: string
}

const declaration: ModelDeclaration<ProjectEditor> = {
  studioURL: { type: 'string' },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }

};

Models.register('project-editor', declaration, functions);