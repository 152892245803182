import { UnifiedRouteGuard } from '@libs/lila-auth';

const loadScreen = (screen: string) => () => import(
  /* webpackChunkName: "app-partner" */
  `./screens/${screen}`
).then((m) => m.default);

export default [
  {
    path: '/:company/a/partner',
    name: 'app-partner-company-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home.screen.vue'),
    meta: {
      app: 'partner',
    },
  },
  {
    path: '/:company/a/partner',
    name: 'app-partner-project-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home.screen.vue'),
    meta: {
      app: 'partner',
    },
  },
  {
    path: '/:company/a/partner/admin/:site?',
    name: 'app-partner-company-admin-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('admin.screen.vue'),
    meta: {
      parent: true,
      app: 'partner',
    },
    children: [
      {
        path: 'add',
        name: 'app-partner-company-admin-add',
        meta: {
          sidescreen: true,
          app: 'partner',
          defaultRoute: 'app-partner-company-admin-add',
        },
        component: loadScreen('add.screen'),
      },
      {
        path: 'edit/:partner',
        component: loadScreen('single.screen'),
        meta: {
          sidescreen: true,
          app: 'partner',
          defaultRoute: 'app-partner-company-admin-edit',
        },
        children: [
          {
            path: 'contacts',
            name: 'app-partner-company-admin-edit-contacts',
            meta: {
              sidescreen: true,
              app: 'partner',
              name: 'app-partner-company-admin-edit-contacts',
            },
            component: loadScreen('single-contacts.screen'),
          },
          {
            path: 'contract',
            name: 'app-partner-company-admin-edit-contract',
            meta: {
              sidescreen: true,
              app: 'partner',
              name: 'app-partner-company-admin-edit-contract',
            },
            component: loadScreen('single-contract.screen'),
          },
          {
            path: '',
            name: 'app-partner-company-admin-edit',
            meta: {
              sidescreen: true,
              app: 'partner',
              name: 'app-partner-company-admin-edit',
            },
            component: loadScreen('single-overview.screen'),
          },
        ],
      },
    ],
  },
];
