import InstallableApp from '@interfaces/InstallableApp.interface';
import LoadElements from '@mixins/loadElements';
import routes from './routes';
import translations from './translations/translations';

LoadElements.appComponents(require.context('./partials', true, /[a-z]\w+\.partial\.(ts|vue)$/, 'lazy'), 'partials', 'partner');
LoadElements.extensions(require.context('./extensions', true, /[a-z-]\w+\.extension\.(ts|vue)$/, 'lazy'), 'partner');

const app: InstallableApp = {
  name: 'partner',
  publisher: 'lilaquadrat',
  translations,
  routes,
};

export default app;
