import vue from 'vue';

/**
 *
 *
 * @export
 * @class loadElements
 */
export default class loadElements {

  static partials(requireComponent: __WebpackModuleApi.RequireContext): void {

    requireComponent.keys().forEach((fileName) => {

      const filename = fileName.match(/([a-z-]+)\.partial\.ts$/i);
      const componentName = `${filename[1]}-partial`;

      vue.component(
        componentName,
        () => import(`../partials/${filename[1]}/${filename[0]}`),
      );

    });

  }

  static modules(requireModules: __WebpackModuleApi.RequireContext): void {

    requireModules.keys().forEach((fileName) => {

      const filename = fileName.match(/([a-z-]+)\.module\.ts$/i);
      const componentName = `${filename[1]}-module`;

      vue.component(
        componentName,
        () => import(`../modules/${filename[1]}/${filename[0]}`),
      );

    });

  }

  static editorComponents(requireModules: __WebpackModuleApi.RequireContext, folder: string): void {

    requireModules.keys().forEach((filename: string) => {

      const matches = filename.match(/([a-z-]+)\.(module|partial)\.editor\.(ts|vue)$/i);
      const componentName = `${matches[1]}-${matches[2]}-editor`;
      /**
       * check if file is inside of his own folder
       */
      const inFolder = filename.indexOf(`./${matches[1]}/`) === 0;
      const importPath = inFolder ? `${matches[1]}/${matches[0]}` : matches[0];

      if (folder === 'modules') {

        vue.component(
          componentName,
          () => import(`../modules/${importPath}`),
        );

      }

      if (folder === 'partials') {

        vue.component(
          componentName,
          () => import(`../partials/${importPath}`),
        );

      }

    });

  }

  static components(requireModules: __WebpackModuleApi.RequireContext, type: 'partials' | 'modules'): void {

    requireModules.keys().forEach((filename: string) => {

      const matches = filename.match(/([a-z-]+)\.(module|partial).(ts|vue)$/i);
      const componentName = `${matches[1]}-${matches[2]}`;
      /**
       * check if file is inside of his own folder
      */
      const inFolder = filename.indexOf(`./${matches[1]}/`) === 0;
      const importPath = inFolder ? `${matches[1]}/${matches[0]}` : matches[0];

      if (type === 'modules') {

        vue.component(
          componentName,
          () => import(`../modules/${importPath}`),
        );

      }

      if (type === 'partials') {

        vue.component(
          componentName,
          () => import(`../partials/${importPath}`),
        );

      }

    });

  }

  static appComponents(requireModules: __WebpackModuleApi.RequireContext, type: 'partials' | 'modules', app: string): void {

    requireModules.keys().forEach((filename: string) => {

      const matches = filename.match(/([a-z-]+)\.(module|partial)(\.(v[1-9]))?(.(editor))?.vue$/i);


      if (!matches) return;

      const componentArray = [matches[1], matches[2]];

      // if a version is found add it to the componentName
      if(matches[4]) {

        componentArray.push(matches[4]);
      
      }

      if(matches[6]) {

        componentArray.push(matches[6]);
      
      }

      const componentName = componentArray.join('-');
      /**
       * check if file is inside of his own folder
      */
      const inFolder = filename.indexOf(`./${matches[1]}/`) === 0;
      const importPath = inFolder ? `${matches[1]}/${matches[0]}` : matches[0];


      if (type === 'modules') {

        vue.component(
          componentName,
          () => import(`../apps/${app}/modules/${importPath}`),
        );

      }

      if (type === 'partials') {

        vue.component(
          componentName,
          () => import(`../apps/${app}/partials/${importPath}`),
        );

      }

    });

  }

  /**
   * app variable with e.g. `media` and filename `select.extension.vue` will result in
   * component named `<select-media-ext />`
   */
  static extensions(requireModules: __WebpackModuleApi.RequireContext, app: string): void {

    requireModules.keys().forEach((filename: string) => {

      const matches = filename.match(/([a-z-]+)\.(extension).(ts|vue)$/i);

      if (!matches) return;

      const componentName = `${matches[1]}-${app}-ext`;
      /**
       * check if file is inside of his own folder
      */
      const importPath = `${matches[1]}.extension.vue`;


      vue.component(
        componentName,
        () => import(`../apps/${app}/extensions/${importPath}`),
      );


    });

  }

  static designComponents(requireModules: __WebpackModuleApi.RequireContext, type: 'partials' | 'modules', namespace?: string): void {

    requireModules.keys().forEach((filename: string) => {

      if(!filename.startsWith('@')) return;

      const matches = filename.match(/([a-z-]+)\.(module|partial).(vue)$/i);
      const componentName = namespace ? `${namespace}-${matches[1]}-${matches[2]}` : `${matches[1]}-${matches[2]}`;
      /**
       * check if file is inside of his own folder
      */
      const inFolder = filename.indexOf(`./${matches[1]}/`) === 0;
      const importPath = inFolder ? `${matches[1]}/${matches[0]}` : matches[0];

      if (type === 'modules') {

        vue.component(
          componentName,
          () => import(`/node_modules/@lilaquadrat/design/src/modules/${importPath}`),
        );

      }

      if (type === 'partials') {

        vue.component(
          componentName,
          () => import(`/node_modules/@lilaquadrat/design/src/partials/${importPath}`),
        );

      }

    });

  }

}
