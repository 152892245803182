import GenericStore from '@interfaces/GenericStore.interface';
import MainStore, { state } from '@store/main.store';
import { DataObject } from '@lilaquadrat/studio/lib/interfaces';
import StudioSDK from '@libs/StudioSDK';
import { HostingCommand } from '@lilaquadrat/studio/interfaces';
import AppHostingAdminCommands from '../interfaces/commands-state.interface';

const AppHostingAdminStore: GenericStore<AppHostingAdminCommands> = {
  namespaced: true,
  strict: true,

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },


  state: {

    data: {},

    search: {
      tags: [],
      search: null,
      type: null,
      sort: '_id',
      order: '1',
    },

    single: null,

  },

  mutations: {

    data(store, data: DataObject<HostingCommand[]>) {

      store.data = data;

    },

    single(store, data: HostingCommand) {

      store.single = data;

    },


    updateSearch(appHostingAdminState, update: { data: any, type: string }) {

      console.log(update, update.data.length, !update.data.length, appHostingAdminState.search);

      if (!['state', 'target', 'command', 'order', 'sort', 'search'].includes(update.type)) return;

      if (!update.data.length) {

        appHostingAdminState.search[update.type] = null;
        return;

      }

      console.log(update, appHostingAdminState.search);

      appHostingAdminState.search[update.type] = update.data;

    },

    setSearch(appHostingAdminState, search: { tags?: string[], search?: string, filterState?: string }) {

      appHostingAdminState.search = search;

    },

  },

  actions: {

    get(store, data: { site?: number, query: { state: string[], target: string, command: string, sort: string, order: string } }) {

      const sdk = new StudioSDK('hostingadmin-app', AppHostingAdminStore.sdkOptions());

      return sdk.hosting.commands.admin.list(data.site, data.query);

    },

    single(store, id: string) {

      const sdk = new StudioSDK('hostingadmin-app', AppHostingAdminStore.sdkOptions());

      return sdk.hosting.commands.admin.single(id);

    },

    command(store, data: { type: 'refresh' | 'docker' | 'nginx', command: string, project: string, company: string }) {

      const sdk = new StudioSDK('hostingadmin-app', AppHostingAdminStore.sdkOptions());

      return sdk.hosting.commands.admin.add(data.type, data.command, data.company, data.project);

    },


    /**
     * This action will be called, when the app will be activated
     */
    activate(store, params) {

      return Promise.resolve(true);

    },
  },

};

export default AppHostingAdminStore;
