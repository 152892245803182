import { UnifiedRouteGuard } from '@libs/lila-auth';

const loadScreen = (screen: string) => () => import(
  /* webpackChunkName: "app-publishadmin" */
  `./screens/${screen}`
).then((m) => m.default);

export default [
  {
    path: '/:company/a/publishadmin',
    name: 'app-publishadmin-company-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home.screen.vue'),
    meta: {
      animationGroup: 'publishadmin',
      app: 'publishadmin',
      parent: true,
      rememberSettings: true,
    },
    children: [
      {
        path: 'add',
        component: loadScreen('single.screen.vue'),
        name: 'app-publishadmin-company-add',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-publishadmin-company-add',
          app: 'publishadmin',
        },
      },
      {
        path: 'edit/:id',
        component: loadScreen('single.screen.vue'),
        name: 'app-publishadmin-company-edit',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-publishadmin-company-edit',
          app: 'publishadmin',
        },
      },
    ],
  },
];
