import './models/Module.model';
import './models/Accordion.model';
import './models/AccordionElement.model';
import './models/ChildData.model';
import './models/Company.model';
import './models/CompareElement.model';
import './models/CompareStructureElement.model';
import './models/Domain.model';
import './models/DomainSecret.model';
import './models/FactsElement.model';
import './models/FooterContact.model';
import './models/FooterSitemap.model';
import './models/FooterSocial.model';
import './models/GalleryElement.model';
import './models/MenuCategoryElement.model';
import './models/MenuItemElement.model';
import './models/GenericData.model';
import './models/GenericDataSingle.model';
import './models/Link.model';
import './models/LinkGroupElement.model';
import './models/ListElement.model';
import './models/ListLink.model';
import './models/ListPartialModel.model';
import './models/ListString.model';
import './models/Me.model';
import './models/ModuleIndex.model';
import './models/ModulePdf.model';
import './models/PicturegroupElement.model';
import './models/PicturePartial.model';
import './models/PictureSource.model';
import './models/PricesElement.model';
import './models/Project.model';
import './models/Auth0Project.model';
import './models/ProjectEditor.model';
import './models/ProjectHosting.model';
import './models/SelectedFile.model';
import './models/Textblock.model';
import './models/VideoPartial.model';
import './models/VideoSource.model';
