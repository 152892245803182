// import PartialModel from '@libs/lila-model';
// import { Content } from '@lilaquadrat/studio/lib/interfaces';

// export default class GenericDataModel extends PartialModel {

//   editor = [];

//   lists = [];

//   customers = [];

//   media = [];

//   data: Record<string, Content> = {};

//   // eslint-disable-next-line class-methods-use-this
//   onSave = (data: any) => {

//     const cleanedData = data;

//     delete cleanedData.data;

//     return cleanedData;

//   };

// }


import Models, { ModelDeclaration, ModelHooks } from '@libs/Models.class';
import { Content } from '@lilaquadrat/studio/lib/interfaces';

export default interface GenericDataSingle {
  editor: string
  lists: string
  customers: string
  media: string
  data: Record<string, Content>
}

const declaration: ModelDeclaration<GenericDataSingle> = {
  editor: { type: 'string'},
  lists: { type: 'string'},
  customers: { type: 'string'},
  media: { type: 'string'},
  data: { type: 'object', default: {} },
};
const functions: ModelHooks<GenericDataSingle> = {
  preSave: (data) => {

    console.log('GENERIC DATA SINGLE PRE SAVE', data);
    delete data.data;

  }
};

Models.register('generic-data-single', declaration, functions);