// import PartialModel from '@libs/lila-model';

// export default class ListPartialModel extends PartialModel {

//     [key: number]: string

// }

import Models, { ModelDeclaration, ModelFunctions } from '@libs/Models.class';

export default interface ListPartial {
  key: string
}

const declaration: ModelDeclaration<ListPartial> = {
  key: { type: 'string', default: 'string' },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};

Models.register('list-partial', declaration, functions);