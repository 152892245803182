import authInstance, { UnifiedRouteGuard } from '@libs/lila-auth';

import LoginScreen from './screens/login.screen.vue';

import ErrorScreen from '@screens/error.screen.vue';

import { RouteConfig } from 'vue-router';

const loadScreen = (screen: string) => {
  return () => import(`./screens/${screen}`).then(m => m.default);
};

const routes: RouteConfig[] = [
  {
    path: '/login',
    name: 'login',
    component: LoginScreen,
  },
  {
    path: '/login/callback',
    name: 'login-callback',
    component: LoginScreen,
  },
  {
    path: '/error/:type',
    name: 'error',
    component: ErrorScreen,
  },
  {
    path: '',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('main.screen.vue'),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: '',
        name: 'me-home',
        beforeEnter: UnifiedRouteGuard,
        component: loadScreen('me-home.screen.vue'),
        meta: {
          authRequired: true,
          parent: true
        },
        children: [
          {
            path: '/me/settings',
            component: loadScreen('me-settings.screen.vue'),
            meta: {
              sidescreen: true,
              name: 'me-settings-sidescreen',
              defaultRoute: 'me-settings-overview'
            },
            children: [
              {
                path: 'personal',
                name: 'me-settings-personal',
                component: loadScreen('me-settings-personal.screen.vue'),
                meta: {
                  sidescreen: true,
                  name: 'me-settings-personal-sidescreen'
                },
              },
              {
                path: '',
                name: 'me-settings-overview',
                component: loadScreen('me-settings-overview.screen.vue'),
                meta: {
                  sidescreen: true,
                  name: 'me-settings-overview-sidescreen'
                },
              }
            ]
          },

        ],
      },
      {
        path: '/:company',
        name: 'company-home',
        beforeEnter: UnifiedRouteGuard,
        component: loadScreen('company-home.screen.vue'),
        meta: {
          parent: true
        },
        children: [
          {
            path: 'project-create',
            name: 'project-create',
            component: loadScreen('project-create.screen.vue'),
            meta: {
              sidescreen: true,
              name: 'project-create-sidescreen',
              defaultRoute: 'project-create'
            },
          },
          {
            path: 'company-create',
            name: 'company-create',
            component: loadScreen('company-create.screen.vue'),
            meta: {
              sidescreen: true,
              name: 'company-create-sidescreen',
              defaultRoute: 'company-create'
            },
          },
          {
            path: 'settings',
            name: 'app-settings-company-home',
            component: loadScreen('company-settings.screen.vue'),
            meta: {
              sidescreen: true,
              name: 'company-settings-sidescreen',
              defaultRoute: 'company-settings'
            },
          },
        ],
      },
      {
        path: '/:company/:project',
        name: 'project-home',
        beforeEnter: UnifiedRouteGuard,
        component: loadScreen('project-home.screen'),
        meta: {
          parent: true,
        },
        children: [
          {
            path: 'settings',
            name: 'app-settings-project-home',
            component: loadScreen('project-settings.screen.vue'),
            meta: {
              sidescreen: true,
              defaultRoute: "project-settings-home"
            }
          },
        ],
      },
    ],
  },
  {
    path: '',
    name: 'app-main-internal-screen',
    component: loadScreen('main.screen.vue'),
    meta: {
      authRequired: true,
    }
  },
  {
    path: '',
    name: 'app-main-screen',
    component: loadScreen('main.screen.vue'),
    meta: {
      authRequired: true,
    }
  }
];



export default routes;