
import Models, { ModelDeclarationExtended, ModelOptions } from '@libs/Models.class';
import MenuCategoryElement from '@models/MenuCategoryElement.model';
import Module from '@models/Module.model';
import Textblock from '@models/Textblock.model';

export default interface MenuModel extends Module {
  name: string
  textblock: Textblock
  intro: Textblock
  categories: MenuCategoryElement[]
}

const declaration: ModelDeclarationExtended<MenuModel, Module> = {
  name: { type: 'string' },
  textblock: { model: 'textblock' },
  intro: { model: 'textblock' },
  categories: { type: 'array', contains: { model: 'menu-category-element' } },
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('menu-module', declaration, undefined, options);