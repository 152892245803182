import GenericStore from '@interfaces/GenericStore.interface';
import IndexedDb from '@libs/indexedDb';
import StudioSDK from '@libs/StudioSDK';
import {
  DataObject, Tracker, TrackerStatistics, TrackerStatisticsDay,
} from '@lilaquadrat/studio/lib/interfaces';
import MainStore, { state } from '@store/main.store';
import ModelsClass from '@libs/Models.class';
import AppTrackerMainState from '../interfaces/main-state.interface';

const AppTrackerStore: GenericStore<AppTrackerMainState> = {

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },

  namespaced: true,
  strict: true,

  state: {
    trackers: [],
    statistics: {},
    data: {},
    day: {} as TrackerStatisticsDay,
    single: {} as Tracker,
    overlay: [],
  },

  mutations: {

    addLocal(appState, trackerData: Tracker) {

      appState.trackers = [trackerData, ...appState.trackers];

    },

    addMultipleLocal(appState, trackerData: Tracker[]) {

      console.log(trackerData);

      appState.trackers = trackerData;

    },

    statistics(appState, data: TrackerStatistics) {

      appState.statistics = data;

    },

    data(store, data: DataObject<Tracker>) {

      store.data = data;

    },

    day(store, day: DataObject<Tracker>) {

      store.day = day;

    },

    single(store, tracker: Tracker) {

      store.single = tracker;

    },

  },

  actions: {

    async createLocal(store, trackerData: Tracker) {

      const tracker: Tracker = {
        mode: trackerData.mode,
        category: trackerData.category,
        company: trackerData.company,
        project: trackerData.project,
      };
      const key = await IndexedDb.db.add('trackers', tracker);

      return {indexedKey: key, data: tracker};

    },

    save(store, trackerData: Tracker) {

      const sdk = new StudioSDK('editor-app', { ...AppTrackerStore.sdkOptions(), company: trackerData.company, project: trackerData.project });

      return sdk.tracker.save(trackerData);


    },

    saveMultiple(store, trackerData: Tracker[]) {

      const sdk = new StudioSDK('time', { ...AppTrackerStore.sdkOptions() });

      return sdk.tracker.saveMultiple(trackerData);


    },

    async getTrackersLocal(store, filter?: {company?: string, project?: string, active?: boolean}, sort: 1 | 0 = 1) {

      const trackers: {indexedKey: number, data: Tracker}[] = [];
      let cursor = await IndexedDb.db.transaction('trackers').store.openCursor(null, !sort ? 'next' : 'prev');
      const filterFunction = (tracker: Tracker) => {

        let match = true;

        if(filter.company) match = tracker.company === filter.company;
        if(filter.project && match) match = tracker.project === filter.project;
        if(filter.active && match) match = tracker.endTime === null || !tracker.endTime;

        return match;

      };

      
      while (cursor) {
        
        let match = true;

        
        if(filter) {
          
          match = filterFunction(cursor.value);
          
        }

        if(match) trackers.push({ indexedKey: cursor.key, data: cursor.value });
        // eslint-disable-next-line no-await-in-loop
        cursor = await cursor.continue();

      }

      return trackers;

    },

    /**
     * checks if any trackers are "stuck" in indexeddb and syncs it with the backend
     */
    async sync(store) {

      const cursor = await IndexedDb.db.transaction('trackers', 'readwrite').store.openCursor(null, 'next');

      while (cursor) {

        if (!cursor?.value) return;

        console.log(cursor.value, cursor.value.startTime > 0 && cursor.value.endTime > 0);

        if (cursor.value.startTime > 0 && cursor.value.endTime > 0) {

          console.log('sny', cursor.value);
          // eslint-disable-next-line no-await-in-loop
          store.dispatch('save', ModelsClass.save(cursor.value, 'tracker'));
          // eslint-disable-next-line no-await-in-loop

          // eslint-disable-next-line no-await-in-loop
          await cursor.delete();

        }


        // eslint-disable-next-line no-await-in-loop
        await cursor.continue();

      }

    },

    list(store, data: {settings: BaseCallData, category?: string[], site?: number, search?: string, user?: string}) {

      const sdk = new StudioSDK('tracker', { ...AppTrackerStore.sdkOptions() });

      console.log(data);

      return sdk.tracker.list(data?.site, data.search, data.category, data.user);

    },

    statistics(store, query: {year?: number, month?: number, user?: string}) {

      const sdk = new StudioSDK('tracker', { ...AppTrackerStore.sdkOptions() });

      return sdk.tracker.statistics(query.year, query.month, query.user);

    },

    day(store, data: {date: string, user?: string}) {

      const sdk = new StudioSDK('tracker', { ...AppTrackerStore.sdkOptions() });

      return sdk.tracker.day(data.date, data.user);

    },

    single(store, id: string) {

      const sdk = new StudioSDK('tracker', { ...AppTrackerStore.sdkOptions() });

      return sdk.tracker.single(id);

    },

    updateName(store, data: {id: string, tracker: Pick<Tracker, 'description'>}) {

      const sdk = new StudioSDK('tracker', { ...AppTrackerStore.sdkOptions() });

      return sdk.tracker.description(data.id, data.tracker.description);

    },

    update(store, data: {tracker: Pick<Tracker, 'category'|'startTime'|'endTime'|'description'>, id: string}) {

      const sdk = new StudioSDK('tracker', { ...AppTrackerStore.sdkOptions() });

      return sdk.tracker.update(data.id, data.tracker);

    },


    singleLocal(store, key: string) {

      return IndexedDb.db.get('trackers', key);

    },

    updateLocal(store, updateData: {key: Tracker['indexedKey'], tracker: Tracker}) {

      return IndexedDb.db.put('trackers', updateData.tracker, updateData.key);

    },

    removeLocal(store, key: Tracker['indexedKey']) {

      return IndexedDb.db.delete('trackers', +key);

    },

    remove(store, id: string) {

      const sdk = new StudioSDK('tracker', { ...AppTrackerStore.sdkOptions() });

      return sdk.tracker.remove(id);

    },

    async overlayElements(store, data: {company: string, project: string}) {

      const localTrackers: Tracker[] = await store.dispatch('getTrackersLocal', {...data, active: true});
      const trackersWithElement = [];

      localTrackers.forEach((single) => {

        trackersWithElement.push({element: 'overlay-tracker-ext', app: 'tracker', data: single});

      });

      trackersWithElement.sort((a, b) => a.data.indexedKey - b.data.indexedKey);

      return trackersWithElement;

    },

    addOverlayElement(store, trackerData: Tracker) {

      return store.dispatch('createLocal', trackerData);

    },

    /**
     * This action will be called, when the app will be activated
     */
    activate() {

      return Promise.resolve(true);

    },
  },

};

export default AppTrackerStore;
