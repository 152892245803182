import { IDBPDatabase, openDB } from 'idb';
import log from 'loglevel';

class IndexedDb {

  db: IDBPDatabase<any>;

  stores: any[];

  register(stores: any) {

    this.stores = stores;

  }

  async connect(version: number) {

    const stores = this.stores;

    log.info('%c[STUDIO-APP]%c%c[INDEXEDDB]%c try connected', 'background: #3f2d56; color: #CCC;', 'padding: 10px', 'background: #CCC; color: #555;');

    this.db = await openDB<any>('lilaquadrat-studio', version, {
      upgrade(db) {

        stores?.forEach((store) => {

          store(db);

        });

      },
    });

    log.info('%c[STUDIO-APP]%c%c[INDEXEDDB]%c connected', 'background: #3f2d56; color: #CCC;', 'padding: 10px', 'background: #CCC; color: #555;');

    return this.db;

  }

  disconnect() {

    this.db.close();

  }

}

export default new IndexedDb();
