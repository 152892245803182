import { UnifiedRouteGuard } from '@libs/lila-auth';

const loadScreen = (screen: string) => () => import(
  /* webpackChunkName: "app-editor" */
  `./screens/${screen}`
).then((m) => m.default);

export default [
  {
    path: '/:company/:project/a/editor/new/:base?/:type?',
    name: 'app-editor-project-new',
    beforeEnter: UnifiedRouteGuard,
    meta: {
      animationGroup: 'editor',
      app: 'editor',
    },
    component: loadScreen('editor.screen.vue'),
  },
  {
    path: '/:company/:project/a/editor/edit/:id/history/:index',
    name: 'app-editor-project-edit-history-index',
    beforeEnter: UnifiedRouteGuard,
    meta: {
      animationGroup: 'editor',
      app: 'editor',
    },
    component: loadScreen('editor.screen.vue'),
  },
  {
    path: '/:company/:project/a/editor/edit/:id/:history?/:use?',
    component: loadScreen('editor.screen.vue'),
    beforeEnter: UnifiedRouteGuard,
    name: 'app-editor-project-edit',
    meta: {
      animationGroup: 'editor',
      app: 'editor',
    },
  },
  {
    path: '/:company/:project/a/editor/history/:id/:site?/:version?',
    component: loadScreen('history.screen.vue'),
    beforeEnter: UnifiedRouteGuard,
    name: 'app-editor-project-history',
    meta: {
      animationGroup: 'editor',
      app: 'editor',
    },
  },
  {
    path: '/:company/:project/a/editor/:site?',
    component: loadScreen('home.screen.vue'),
    beforeEnter: UnifiedRouteGuard,
    name: 'app-editor-project-home',
    meta: {
      animationGroup: 'editor',
      app: 'editor',
      parent: true,
      rememberSettings: true,
    },
    children: [
      {
        path: 'settings',
        component: loadScreen('settings.screen.vue'),
        meta: {
          sidescreen: true,
          defaultRoute: 'app-editor-project-settings-overview',
          app: 'editor',
        },
        children: [
          {
            path: 'base',
            name: 'app-editor-project-settings-base',
            component: loadScreen('settings-base.screen.vue'),
            meta: {
              sidescreen: true,
              name: 'app-editor-project-settings-base',
              app: 'editor',
            },
          },
          {
            path: 'main',
            name: 'app-editor-project-settings-main',
            component: loadScreen('settings-main.screen.vue'),
            meta: {
              sidescreen: true,
              name: 'app-editor-project-settings-main',
              app: 'editor',
            },
          },
          {
            path: '',
            name: 'app-editor-project-settings-overview',
            component: loadScreen('settings-overview.screen.vue'),
            meta: {
              sidescreen: true,
              name: 'app-editor-project-settings-overview',
              app: 'editor',
            },
          },
        ],
      },
    ],
  },
];
