import GenericModel from '@interfaces/GenericModel.interface';
import Models, { ModelDeclarationExtended, ModelOptions } from '@libs/Models.class';
import ListLink from '@models/ListLink.model';
import ListString from '@models/ListString.model';
import Module from '@models/Module.model';
import Link from '@models/Link.model';
import Textblock from '../interfaces/textblock.interface';

export default interface CallToActionModule extends GenericModel {
  type: 'callToAction-module'
  textblock: Textblock
  list: ListString
  links: ListLink
  link: Link
  variant: string[]
}

const declaration: ModelDeclarationExtended<CallToActionModule, Module> = {
  link: {model: 'link'},
  textblock: {model: 'textblock'},
  links: { model: 'list-link' },
  list: { model: 'list-string' },
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('callToAction-module', declaration, undefined, options);