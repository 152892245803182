// import PartialModel from '@libs/lila-model';

// export default class AppEditorTitleDescriptionModel extends PartialModel {

//   full: string = '';

//   short: string = '';

// }

import Models, { ModelDeclaration } from '@libs/Models.class';
import { AppEditorTitleDescription } from '@lilaquadrat/interfaces';


const declaration: ModelDeclaration<AppEditorTitleDescription> = {
  full: { type: 'string' },
  short: { type: 'string' },
};

Models.register('editor-title-description', declaration);