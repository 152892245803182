
import Models, { ModelDeclaration } from '@libs/Models.class';

export default interface EditorSettings {
  modes: string[]
}

const declaration: ModelDeclaration<EditorSettings> = {
  modes: { type: 'array', contains: { type: 'string' } },
};

Models.register('editor-settings', declaration);