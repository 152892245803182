import GenericStore from '@interfaces/GenericStore.interface';
import MainStore, { state } from '@store/main.store';
import { DataObject, HostingSettings } from '@lilaquadrat/studio/lib/interfaces';
import StudioSDK from '@libs/StudioSDK';
import { Hosting } from '@lilaquadrat/studio/interfaces';
import AppHostingAdminMainState from '../interfaces/main-state.interface';

const AppHostingAdminStore: GenericStore<AppHostingAdminMainState> = {
  namespaced: true,
  strict: true,

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },


  state: {

    data: {},

    search: {
      tags: [],
      search: null,
      type: null,
      sort: '_id',
      order: '1',
    },

    single: null,

    settings: null

  },

  mutations: {

    data(store, data: DataObject<Hosting>) {

      store.data = data;

    },

    single(store, data: any) {

      store.single = data;

    },

    settings(store, data: HostingSettings) {

      store.settings = data;

    },

    updateSearch(appHostingAdminState, update: { data: any, type: string }) {

      console.log(update, update.data.length, !update.data.length, appHostingAdminState.search);

      if (!update.data.length) {

        appHostingAdminState.search[update.type] = null;
        return;

      }

      console.log(update, appHostingAdminState.search);

      appHostingAdminState.search[update.type] = update.data;

    },

    setSearch(appHostingAdminState, search: { tags?: string[], search?: string, type?: string }) {

      appHostingAdminState.search = search;

    },

  },

  actions: {
    // sdkListExample(store, data: {data: Test, id: string}) {

    //   const sdk = new StudioSDK('test-app', AppTestStore.sdkOptions());

    //   return sdk.test.list(data.id, data.data);

    // },

    get() {

      const sdk = new StudioSDK('editor-app', AppHostingAdminStore.sdkOptions());

      return sdk.hosting.admin.list();

    },

    getSettings() {

      const sdk = new StudioSDK('editor-app', AppHostingAdminStore.sdkOptions());

      return sdk.hosting.admin.getSettings();

    },

    updateSettings(store, settings: HostingSettings) {

      const sdk = new StudioSDK('editor-app', AppHostingAdminStore.sdkOptions());

      return sdk.hosting.admin.updateSettings(settings);

    },

    single(store) {

      return Promise.resolve({ data: {} });

    },

    /**
     * This action will be called,  when the  app will be     vate       */
    activate(store, params) {

      return Promise.resolve();

    },
  },

};

export default AppHostingAdminStore;
