import Models, { ModelDeclarationExtended, ModelOptions } from '@libs/Models.class';
import Link from '@models/Link.model';
import Module from '@models/Module.model';


export default interface QuoteModule extends Module {
  citation: string
  quote: string
  link: Link
}

const declaration: ModelDeclarationExtended<QuoteModule, Module> = {
  quote: { type: 'string' },
  citation: { type: 'string' },
  link: { model: 'link' },
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('quote-module', declaration, undefined, options);