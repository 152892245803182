import MainStore, { state } from '@store/main.store';
import GenericStore from '@interfaces/GenericStore.interface';
import AppAppstoreOverlayState from '../interfaces/overlay.interface';

const AppStore: GenericStore<AppAppstoreOverlayState> = {

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },

  namespaced: true,
  strict: true,

  state: {

    open: false

  },


  mutations: {


    open(store, open: boolean) {

      store.open = open;

    },

  },

  actions: {

    toggle(store, open: boolean) {

      store.commit('open', open);

    },

  },

};

export default AppStore;
