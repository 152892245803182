export default {
  'app-hostingadmin': 'HostingAdmin',
  'app-hostingadmin-settings-baseDomain': 'Base Domain',
  'app-hostingadmin-company-settings': 'Einstellungen',
  'app-hostingadmin-company-settings-overview': 'Einstellungen',
  'app-hostingadmin-company-settings-main': 'Allgemeine Einstellungen',
  'app-hostingadmin-add-title': 'HostingAdmin erstellen',
  'app-hostingadmin-title-active': 'Aktiv',
  'app-hostingadmin-hosting-active': 'Hosting ist aktiv',
  'app-hostingadmin-hosting-inactive': 'Hosting ist inaktiv',
  'app-hostingadmin-certificatesActions': 'Zertifikat Actions',
  'app-hostingadmin-certificatesActions-domains': 'Domains',
  'app-hostingadmin-certificatesActions-title-updated': 'Aktualisiert',
  'app-hostingadmin-certificatesActions-title-planned': 'Geplant',
  'app-hostingadmin-certificatesActions-title-created': 'Erstellt',
  'app-hostingadmin-certificatesActions-contentType': 'Content Type',
  'app-hostingadmin-certificatesActions-type': 'Typ',
  'app-hostingadmin-certificatesActions-attributes': 'Attribute',
  'app-hostingadmin-certificatesActions-attributes-renew': 'renew',
  'app-hostingadmin-certificatesActions-attributes-forceFile': 'forceFile',
  'app-hostingadmin-certificatesActions-attributes-saveInFault': 'saveInVault',
  'app-hostingadmin-certificatesActions-tries': 'Versuche',
  'app-hostingadmin-certificatesActions-results': 'Ergebnisse',
  'app-hostingadmin-certificatesActions-companyProject': 'Firma & Projekt',
  'app-hostingadmin-certificatesActions-state': 'Status',
  'app-hostingadmin-certificatesActions-state-failed': 'fehlgeschlagen',
  'app-hostingadmin-certificatesActions-state-success': 'erfolgreich',
  'app-hostingadmin-certificatesActions-state-retry': 'retry',
  'app-hostingadmin-certificatesActions-state-new': 'neu',
  'app-hostingadmin-certificatesActions-cancel': 'Action abbrechen',
  'app-hostingadmin-certificatesActions-state-canceled': 'abgebrochen',
  'app-hostingadmin-domains': 'Domains',
  'app-hostingadmin-domains-domain': 'Domain',
  'app-hostingadmin-domains-companyProject': 'Firma & Projekt',
  'app-hostingadmin-domains-title-valid-from-to': 'Gültigkeit',
  'app-hostingadmin-domains-title-certificate': 'SSL Zertifikat',
  'app-hostingadmin-domains-title-active': 'aktiv',
  'app-hostingadmin-domains-title-isValid': 'gültig',
  'app-hostingadmin-domains-title-valid-date': 'SSL gültig bis',
  'app-hostingadmin-domains-attributes': 'Eigenschaften',
  'app-hostingadmin-domains-attributes-isMain': 'isMain',
  'app-hostingadmin-domains-attributes-isDefault': 'isDefault',
  'app-hostingadmin-domains-attributes-validated': 'validated',
  'app-hostingadmin-domains-attributes-active': 'active',
  'app-hostingadmin-domains-title-updated': 'Aktualisiert',
  'app-hostingadmin-domains-title-created': 'Erstellt',
  'app-hostingadmin-domains-filter': 'Filter',
  'app-hostingadmin-domains-certificateNeeded': 'Fehlendes Zertifikat',
  'app-hostingadmin-certificate-error-CERTIFICATEACTION_EXISTS': 'Es wird bereits ein Zertifikat angefordert.',
  'app-hostingadmin-certificate-request': 'SSL Zertifikate erstellen',
  'app-hostingadmin-certificate-invalid': 'Das SSL Zertifikate ist ungültig',
  'app-hostingadmin-certificate-domain-active-cert-invalid': 'Die Domain is aktiv und das SSL Zertifikate ist ungültig',
  'app-hostingadmin-commands': 'Commands',
  'app-hostingadmin-single-command-title': 'Command',
  'app-hostingadmin-commands-companyProject': 'Firma & Projekt',
  'app-hostingadmin-commands-targetCommand': 'Target & Command',
  'app-hostingadmin-commands-data': 'Data',
  'app-hostingadmin-commands-action': 'Action',
  'app-hostingadmin-commands-state': 'Status',
  'app-hostingadmin-commands-created': 'Erstellt',
  'app-hostingadmin-commands-updated': 'Aktualisiert',
  'app-hostingadmin-commands-state-new': 'Neu',
  'app-hostingadmin-commands-state-inProgress': 'In Arbeit',
  'app-hostingadmin-commands-state-success': 'Erfolgreich',
  'app-hostingadmin-commands-state-error': 'Fehler',
  'app-hostingadmin-commands-target': 'Target',
  'app-hostingadmin-commands-target-docker': 'docker',
  'app-hostingadmin-commands-target-nginx': 'nginx',
  'app-hostingadmin-single-command-add': 'Commando ausführen',
  'app-hostingadmin-commands-command-preview': 'Preview',
  'app-hostingadmin-commands-command': 'Command',
  'app-hostingadmin-commands-command-up': 'up',
  'app-hostingadmin-commands-command-down': 'down',
  'app-hostingadmin-commands-command-restart': 'restart',
  'app-hostingadmin-commands-command-start': 'start',
  'app-hostingadmin-commands-command-stop': 'stop',
  'app-hostingadmin-commands-command-rebuild': 'rebuild',
  'app-hostingadmin-commands-command-kill': 'kill',
  'app-hostingadmin-commands-command-check': 'check',
  'app-hostingadmin-commands-command-status': 'status',
  'app-hostingadmin-commands-refresh': 'refresh commandos',
  'app-hostingadmin-commands-refresh-description': 'Zum erstellen von proxy_http.conf, stream.conf, docker-compose.json und nginx.conf Dateien auf Projekt ebene',
  'app-hostingadmin-commands-nginx': 'nginx commandos',
  'app-hostingadmin-commands-nginx-description': 'Commandos für den nginx Hauptprozess. Dieser ist hauptsaechlich für den proxy zu den einzelnen docker container verantwortlich',
  'app-hostingadmin-commands-docker': 'docker commandos',
  'app-hostingadmin-commands-docker-description': 'Alle Befehle die docker-compose betreffen',
  'app-hostingadmin-commands-docker-project-description': 'Wenn Firma und Projekt angegeben wird, betrifft der Befehl nur diesen einen container',
  'app-hostingadmin-commands-refresh-stream': 'stream',
  'app-hostingadmin-commands-refresh-stream-description': 'stream erstellt stream.conf für ssl preread proxy',
  'app-hostingadmin-commands-refresh-compose': 'compose',
  'app-hostingadmin-commands-refresh-compose-description': 'docker compose datei zum starten der einzelnen docker containern',
  'app-hostingadmin-commands-refresh-nginx': 'nginx',
  'app-hostingadmin-commands-refresh-nginx-description': 'nginx conf für ein einzelnes projekt',
  'app-hostingadmin-commands-refresh-nginxMain': 'nginx main',
  'app-hostingadmin-commands-refresh-nginxMain-description': 'nginx erstellt proxy_http.conf für http zu https proxy'
};
