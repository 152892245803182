export default {
  'app-learn': 'Lernen',
  'app-learn-input-placeholder': 'Suche',
  'app-learn-results': 'passende Ergebnisse für',
  'app-learn-no-results-pre': 'Für',
  'app-learn-no-results': 'wurden keine passenden Artikel gefunden.',
  'app-learn-back-to-overview': 'Lernen Übersicht',
  'app-learn-content-not-found': 'Inhalt nicht gefunden',
  'app-learn-content-loading': 'wird geladen',
  'app-learn-content-suggestions': 'Vorschläge',
};
