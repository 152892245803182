import { Call } from '@libs/lila-call';
import { Auth } from '@libs/lila-auth';
import MainStore from '@store/main.store';
import { DataObject, Partner } from '@lilaquadrat/studio/lib/interfaces';
import GenericStore from '@interfaces/GenericStore.interface';
import AppPartnerState from '../interfaces/AppPartnerState';

const PartnerStore: GenericStore<AppPartnerState> = {
  namespaced: true,
  strict: true,

  state: {
    single: {},
    news: {},
    list: {},
    singleCacheUrl: [],
    newsCacheUrl: [],
  },

  mutations: {

    single(state, data: any) {

      state.single = data;

    },

    cacheUrl(state, data: string[]) {

      state.singleCacheUrl = data;

    },

    news(state, data: any) {

      state.news = data;

    },

    newsCacheUrl(state, data: string[]) {

      state.newsCacheUrl = data;

    },

    list(state, data: any) {

      state.list = data;

    },

  },
  actions: {

    refresh(store: any) {


      const urlArray = store.state.singleCacheUrl;
      const call = new Call<Partner>('partners', MainStore.getters.endpoints);

      return call.get('api', `/${urlArray.filter((single) => single).join('/')}`, {}, Auth.getAuthHeader())
        .then((single) => {

          store.commit('single', single.r);

          return single.r;

        });

    },

    single(store: any, data: { partner: string, company: string, admin?: boolean, bypassCache?: boolean }) {

      if (!data.bypassCache) {

        if (store.state.single?.company?.id === data.partner) {

          return new Promise((resolve) => resolve(store.state.single));

        }

      }

      const urlArray = ['partners', data.admin ? 'admin' : null, data.company, data.partner];
      const call = new Call<Partner>('partners', MainStore.getters.endpoints);

      return call.get('api', `/${urlArray.filter((single) => single).join('/')}`, {}, Auth.getAuthHeader())
        .then((single) => {

          store.commit('single', single.r);
          store.commit('cacheUrl', urlArray);

          return single.r;

        });

    },

    news(store: any, data: { bypassCache?: boolean }) {

      if (!data?.bypassCache) {

        if (store.state.news?.id) {

          return new Promise((resolve) => resolve(store.state.news));

        }

      }

      const urlArray = ['editor', 'lilaquadrat', 'homepage', 'latest'];
      const call = new Call<Partner>('partners', MainStore.getters.endpoints);

      return call.get('api', `/${urlArray.filter((single) => single).join('/')}`, { category: 'partner-app' }, Auth.getAuthHeader())
        .then((single) => {

          store.commit('news', single.r);
          store.commit('newsCacheUrl', urlArray);

          return single.r;

        });

    },

    user(store: any, data: { partner: string, company: string, admin: boolean, search: string }) {

      const urlArray = ['partners', data.admin ? 'admin' : null, data.company, data.partner, 'user'];
      const call = new Call<Partner>('partners', MainStore.getters.endpoints);

      return call.get('api', `/${urlArray.filter((single) => single).join('/')}`, { search: data.search }, Auth.getAuthHeader());

    },

    contacts(store: any, data: { partner: string, company: string, contacts: string[] }) {

      const urlArray = ['partners', 'admin', data.company, data.partner, 'contacts'];
      const call = new Call<Partner>('partners', MainStore.getters.endpoints);

      return call.put('api', `/${urlArray.filter((single) => single).join('/')}`, { contacts: data.contacts }, Auth.getAuthHeader());

    },

    contract(store: any, data: { partner: string, company: string, admin: boolean, fileId: string }) {

      const urlArray = ['partners', data.admin ? 'admin' : null, data.company, data.partner, 'contract', data.fileId];
      const call = new Call<Partner>('partners', MainStore.getters.endpoints);

      return call.put('api', `/${urlArray.filter((single) => single).join('/')}`, {}, Auth.getAuthHeader());

    },

    removeContract(store: any, data: { partner: string, company: string }) {

      const urlArray = ['partners', 'admin', data.company, data.partner, 'contract'];
      const call = new Call<Partner>('partners', MainStore.getters.endpoints);

      return call.delete('api', `/${urlArray.filter((single) => single).join('/')}`, Auth.getAuthHeader());

    },

    changeContractState(store: any, data: { partner: string, company: string, state: 'active' | 'inactive' }) {

      const urlArray = ['partners', 'admin', data.company, data.partner, 'contract', 'confirm', data.state];
      const call = new Call<Partner>('partners', MainStore.getters.endpoints);

      return call.put('api', `/${urlArray.filter((single) => single).join('/')}`, {}, Auth.getAuthHeader());

    },

    state(store: any, data: { partner: string, company: string, action: 'activate' | 'deactivate' }) {

      const urlArray = ['partners', 'admin', data.company, data.partner, data.action];
      const call = new Call<Partner>('partners', MainStore.getters.endpoints);

      return call.put('api', `/${urlArray.filter((single) => single).join('/')}`, {}, Auth.getAuthHeader());

    },

    list(store: any, data: { company: string, query: Record<string, unknown>, site?: number }) {

      console.log(MainStore.getters.endpoints);

      const urlArray = ['partners', 'admin', data.company, 'list', data.site];
      const call = new Call<DataObject<Partner>>('partners', MainStore.getters.endpoints);

      return call.get('api', `/${urlArray.filter((single) => single).join('/')}`, data.query, Auth.getAuthHeader())
        .then((single) => {

          store.commit('list', single.r);

          return single.r;

        });

    },


    save(store, data) {

      const urlArray = ['partners', 'admin', data.company];
      const call = new Call<void>('appstore', MainStore.getters.endpoints);


      return call.post('api', `/${urlArray.filter((single) => single).join('/')}`, data.data, Auth.getAuthHeader());


    },


  },

};


export default PartnerStore;
