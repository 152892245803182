// import PartialModel from '@libs/lila-model';
// import { Content } from '@lilaquadrat/studio/lib/interfaces';

// export default class GenericDataModel extends PartialModel {

//   editor = [];

//   lists = [];

//   customers = [];

//   media = [];

//   data: Record<string, Content> = {};

//   // eslint-disable-next-line class-methods-use-this
//   onSave = (data: any) => {

//     const cleanedData = data;

//     delete cleanedData.data;

//     return cleanedData;

//   };

// }


import Models, { ModelDeclaration, ModelHooks } from '@libs/Models.class';
import { Content } from '@lilaquadrat/studio/lib/interfaces';

export default interface GenericData {
  editor: string[]
  lists: string[]
  customers: string[]
  media: string[]
  data: Record<string, Content>
}

const declaration: ModelDeclaration<GenericData> = {
  editor: { type: 'array', contains: { type: 'string' } },
  lists: { type: 'array', contains: { type: 'string' } },
  customers: { type: 'array', contains: { type: 'string' } },
  media: { type: 'array', contains: { type: 'string' } },
  data: { type: 'object', default: {} },
};
const functions: ModelHooks<GenericData> = {
  preSave: (data) => {

    console.log('GENERIC DATA PRE SAVE', data);
    delete data.data;

  }
};

Models.register('generic-data', declaration, functions);