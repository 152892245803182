// import PartialModel from '@libs/lila-model';
// import ListModel from './ListString.model';

// export default class CompareStructureElementModel extends PartialModel {

//   headline: string = '';

//   description: string = '';

//   list: string[] = [];

// }

import Models, { ModelDeclaration, ModelFunctions } from '@libs/Models.class';

export default interface CompareStructureElement {
  headline: string
  description: string
  list: string[]
}

const declaration: ModelDeclaration<CompareStructureElement> = {
  headline: { type: 'string' },
  description: { type: 'string' },
  list: { type: 'array', contains: { type: 'string' } },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};

Models.register('compare-structure-element', declaration, functions);