import Models, { ModelDeclaration, ModelHooks } from '@libs/Models.class';

export default interface Tracker {
  project: string
  company: string
  description: string
  category: string
  indexedKey: number
  startTime: Date
  endTime: Date
  currentTime: number
  mode: string
}

const declaration: ModelDeclaration<Tracker> = {
  project: { type: 'string' },
  company: { type: 'string' },
  description: { type: 'string' },
  category: { type: 'string', default: 'worktime' },
  indexedKey: { type: 'number' },
  startTime: { type: 'Date' },
  endTime: { type: 'Date' },
  currentTime: { type: 'number' },
  mode: { type: 'string', default: 'timeframe' },
};
const hooks: ModelHooks<Tracker> = {
  preSave: (data) => {

    delete data.indexedKey;
    data.startTime = new Date(data.startTime);
    data.endTime = new Date(data.endTime);

    data.currentTime = data.endTime.getTime() - data.startTime.getTime(); 

  }
};

Models.register('tracker', declaration, hooks);