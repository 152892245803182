// import ModuleModel from '@models/Module.model';
// import modules from '@apps/editor/editor-modules';
// import ChildDataModel from '@models/ChildData.model';
// import TextblockModel from '@models/Textblock.model';
// import GenericDataModel from '@models/GenericData.model';

// export default class TrainingModuleModel extends ModuleModel {

//   type: keyof typeof modules = 'training-module';

//   uuid = true;

//   genericData = GenericDataModel;

//   textblock = TextblockModel;

// }

import Models, { ModelDeclarationExtended, ModelOptions } from '@libs/Models.class';
import GenericData from '@models/GenericData.model';
import Module from '@models/Module.model';
import Textblock from '@models/Textblock.model';

export default interface TrainingModule extends Module {
  genericData: GenericData
  textblock: Textblock
}


const declaration: ModelDeclarationExtended<TrainingModule, Module> = {
  genericData: { model: 'generic-data' },
  textblock: { model: 'textblock' },
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('training-module', declaration, {}, options);