// import ModuleModel from '@models/Module.model';
// import modules from '@apps/editor/editor-modules';
// import ListLinkModel from '@models/ListLink.model';

// export default class IndexModuleModel extends ModuleModel {

//   type: keyof typeof modules = 'index-module';

//   uuid = true;

//   links = ListLinkModel;

// }


import Models, { ModelDeclarationExtended, ModelFunctions, ModelOptions } from '@libs/Models.class';
import ListLink from '@models/ListLink.model';
import Module from '@models/Module.model';

export default interface IndexModule extends Module {
  links: ListLink
}

const declaration: ModelDeclarationExtended<IndexModule, Module> = {
  links: { model: 'list-link' },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('index-module', declaration, functions, options);