import Models, { ModelDeclaration } from '@libs/Models.class';

export default interface AppPublishadminMailFrom {
  email: string,
  displayName: string
  restrictedTo: string[]
}

const declaration: ModelDeclaration<AppPublishadminMailFrom> = {
  email: { type: 'string' },
  displayName: { type: 'string' },
  restrictedTo: { type: 'array', contains: { type: 'string' } },
};

Models.register('app-publishadmin-mailfrom', declaration);