export default {
  'app-gfm-name': 'Pflanzenname deutsch',
  'app-gfm-title': 'Green for Me',
  'app-gfm-latin': 'Pflanzenname botanisch',
  'app-gfm-hint': 'Pflanzentipp',
  'app-gfm-amount': 'Anzahl pro QM',
  'app-gfm-cmyear': 'Wuchsgeschwindigkeit in cm/Jahr',
  'app-gfm-size': 'Maße',
  'app-gfm-size-select': 'Pflanzenhöhe in cm',
  'app-gfm-plantdistance': 'Pflanzabstand in cm',
  'app-gfm-fruit': 'Frucht?',
  'app-gfm-fruitText': 'Beschreibung Frucht',
  'app-gfm-bloom_name': 'Blütefarbe',
  'app-gfm-colors-1': 'Blütefarbe 1',
  'app-gfm-colors-2': 'Blütefarbe 2',
  'app-gfm-winter-green-always': 'Immergrüne Pflanze',
  'app-gfm-winter-green-summer': 'Sommergrüne Pflanze',
  'app-gfm-winter-green-winter': 'Wintergrüne Pflanze',
  'app-gfm-winter-green-half': 'Halbimmergrüne Pflanze',
  'app-gfm-locationText': 'Standort Freitext',
  'app-gfm-soil': 'Bodenfeuchte',
  'app-gfm-sun': 'Standort',
  'app-gfm-areas': 'Lebensbereich Stauden',
  'app-gfm-category': 'Pflanzenkategorie',
  'app-gfm-bloomCategory': 'Blütekategorie',
  'app-gfm-heyday': 'Blütezeit',
  'app-gfm-soilType': 'Bodenart',
  month_1: 'Januar',
  month_2: 'Februar',
  month_3: 'März',
  month_4: 'April',
  month_5: 'Mai',
  month_6: 'Juni',
  month_7: 'Juli',
  month_8: 'August',
  month_9: 'September',
  month_10: 'Oktober',
  month_11: 'November',
  month_12: 'Dezember',
  'app-gfm-water': 'Wasserbedarf',
  'app-gfm-fertilize': 'Düngebedarf',
  'app-gfm-planttime': 'Pflanzzeit',
  'app-gfm-time': 'Pflegeaufwand/Zeitbedarf',
  'gfm-app-time-1': 'Pflegeaufwand Stufe 1',
  'gfm-app-time-2': 'Pflegeaufwand Stufe 2',
  'gfm-app-time-3': 'Pflegeaufwand Stufe 3',
  'gfm-app-time-4': 'Pflegeaufwand Stufe 4',
  'app-gfm-poison': 'giftig?',
  'app-gfm-poisonText': 'Falls ja, wie giftig?',
  'app-gfm-winter': 'winterhart?',
  'app-gfm-winterZone': 'Winterhärtezone',
  'app-gfm-lifespan': 'Lebensdauer',
  'app-gfm-root': 'Wurzelsystem',
  'app-gfm-growth': 'Wuchs',
  'app-gfm-conviviality': 'Geselligkeit',
  'app-gfm-insects': 'Insektenfreundlich',
  'app-gfm-animalCompatibility': 'Insektenart',
  'app-gfm-animalResistance': 'Resistenz',
  'app-gfm-purposeInBed': 'Aufgabe im Beet',
  'app-gfm-purpose': 'Aufgabe im Garten',
  'app-gfm-habit': 'Wuchsform',
  'app-gfm-shape': 'Blüteform',
  'app-gfm-style': 'Aussehen',
  'app-gfm': 'Pflanzen',
  'app-gfm-plants': 'Pflanzen',
  'app-green-for-me': 'GFM Pflanzen',
  'app-gfm-description': 'Name & Beschreibung',
  'app-gfm-sizeLocation': 'Maße',
  'app-gfm-location': 'Lebensbereich',
  'app-gfm-properties': 'Eigenschaften',
  'app-gfm-bloom': 'Blüte',
  'app-gfm-bloom-month': 'Bluete',
  'app-gfm-description-content': 'Deutscher & Botanischer Name, Bild, Beschreibung & Tipps',
  'app-gfm-size-content': 'Alles rund um Zahlen: Größe, Wachstum, Anzahlen...',
  'app-gfm-style-content': 'Blüte- & Blattfarben & Blatteigenschaften ',
  'app-gfm-location-content': 'Standort, Boden, Lebensbereich & Pflanzenart',
  'app-gfm-bloom-content': 'Blütezeit, Blühkategorie, Blühkalender',
  'app-gfm-care-content': 'Wasser-& Düngebedarf, Pflanzzeit, Aufwand (Zeit)',
  'app-gfm-basics-content': 'Giftigkeit, Winterhärte & Lebensdauer',
  'app-gfm-growth-content': 'Wurzeln, Wuchsform & Geselligkeit',
  'app-gfm-animals-content': 'Resistenz: Schnecken, Kaninchen & Wühlmäuse, Insektenfreundlichkeit',
  'app-gfm-design-content': 'Aufgabe im Beet & Garten, Pflanzen- und Blüteform, Stil',
  'app-gfm-animals': 'Tiere',
  'app-gfm-design': 'Gestaltung',
  'app-gfm-basics': 'Basics',
  'app-gfm-care': 'Pflege',
  'app-gfm-properties-content': '',
  'app-gfm-conviviality-1': 'I einzeln oder in kleinen Tuffs',
  'app-gfm-conviviality-2': 'II in kleinen Trupps von etwa 3-10 Pflanzen',
  'app-gfm-conviviality-3': 'III in größeren Gruppen von 10-20 Pflanzen',
  'app-gfm-conviviality-4': 'IV in größeren Kolonien, ausgesprochen flächig',
  'app-gfm-conviviality-5': 'V vorwiegend großflächig',
  'app-gfm-areas-1': 'G  - Gehölz',
  'app-gfm-areas-2': 'GR - Gehölz-Rand',
  'app-gfm-areas-3': 'Fr - Freifläche mit Wildstaudencharakter',
  'app-gfm-areas-4': 'B  - Beet',
  'app-gfm-areas-5': 'SH - Freifläche mit Steppen-Heide-Charakter',
  'app-gfm-areas-6': 'H  - Freifläche mit Heide-Charakter',
  'app-gfm-areas-7': 'St - Steinanlage',
  'app-gfm-areas-8': 'FS - Fels-Steppe',
  'app-gfm-areas-9': 'M  - Matten',
  'app-gfm-areas-10': 'SF - Stein-Fugen',
  'app-gfm-areas-11': 'MK - Mauer-Kronen',
  'app-gfm-areas-12': 'A  - Alpinum',
  'app-gfm-areas-13': 'WR - Wasser-Rand',
  'app-gfm-areas-14': 'W  - Wasserpflanzen',
  'app-gfm-areas-15': 'KÜBEL - nicht winterharte Stauden',
  'app-gfm-purposeInBed-1': 'Leitpflanze',
  'app-gfm-purposeInBed-2': 'Begleitpflanze',
  'app-gfm-purposeInBed-3': 'Füllpflanze',
  'app-gfm-purposeInBed-4': 'Streupflanze',
  'app-gfm-purpose-1': 'Erosionsschutz /Hang',
  'app-gfm-purpose-2': 'Sichtschutz',
  'app-gfm-purpose-3': 'Sicherheit',
  'app-gfm-purpose-4': 'Lärmschutz',
  'app-gfm-purpose-5': 'Unkrautunterdrücker',
  'app-gfm-purpose-6': 'Duftpflanzen',
  'app-gfm-purpose-7': 'Windschutz',
  'app-gfm-purpose-8': 'Schattenspender',
  'app-gfm-purpose-9': 'Raumteiler',
  'app-gfm-habit-1': 'rund & kugelig',
  'app-gfm-habit-2': 'gewölbt & oval',
  'app-gfm-habit-3': 'konisch & pyraamidal',
  'app-gfm-habit-4': 'säulenförmig',
  'app-gfm-habit-5': 'stachelig',
  'app-gfm-habit-6': 'trichterförmig',
  'app-gfm-habit-7': 'quadratisch',
  'app-gfm-habit-8': 'frei wachsend',
  'app-gfm-shape-1': 'Ähren & Trauben',
  'app-gfm-shape-2': 'Köpfchen & Quirle',
  'app-gfm-shape-3': 'Rispen',
  'app-gfm-shape-4': 'Dolden',
  'app-gfm-shape-5': 'Magaritenblumen',
  'app-gfm-shape-6': 'Netze & Gitter',
  'gfm-app-white': 'weiss',
  'gfm-app-no-color': 'Keine Farbe',
  'gfm-app-multi-color': 'mehrfarbig',
  'app-gfm-growth-1': 'horstbildend',
  'app-gfm-growth-2': 'polsterbildend',
  'app-gfm-growth-3': 'rhizombildend',
  'app-gfm-growth-4': 'kompakt',
  'app-gfm-root-1': 'tief',
  'app-gfm-root-2': 'flach',
  'app-gfm-root-3': 'herz',
  'app-gfm-bloomCategory-1': 'Frühlingsblüher',
  'app-gfm-bloomCategory-2': 'Sommerblüher',
  'app-gfm-bloomCategory-3': 'Herbstblüher',
  'app-gfm-bloomCategory-4': 'Winterblüher',
  'app-gfm-bloomCategory-5': 'Basispflanzen',
  'app-gfm-bloomCategory-6': 'Bodendecker',
  'gfm-app-location-1': 'Halbschatten',
  'gfm-app-location-2': 'sonnig',
  'gfm-app-location-3': 'schattig',
  'gfm-app-location-4': 'absonnig',
  'gfm-app-soil-1': '3 - feucht',
  'gfm-app-soil-2': '2 - normal',
  'gfm-app-soil-3': '1 - trocken',
  'gfm-app-soil-4': '4 - nasser Boden (Sumpf)',
  'gfm-app-soil-5': '5 - flaches Wasser',
  'gfm-app-soil-6': '6 - Schwimmblattpflanzen',
  'gfm-app-soil-7': '7 - untergetauchte Pflanzen',
  'gfm-app-soil-8': '8 - Schwimmpflanzen',
  'app-gfm-category-1': 'Laubgehölze',
  'app-gfm-category-2': 'Nadelgehölze',
  'app-gfm-category-3': 'Heckenpflanzen',
  'app-gfm-category-4': 'Obst',
  'app-gfm-category-5': 'Stauden',
  'app-gfm-category-6': 'Rosen',
  'app-gfm-category-7': 'Bodendecker',
  'app-gfm-category-8': 'Kletterpflanzen',
  'app-gfm-category-9': 'Kräuter',
  'app-gfm-category-10': 'Gräser',
  'app-gfm-category-11': 'Farne',
  'app-gfm-category-12': 'Blumenzwiebeln/ Knollen',
  'app-gfm-category-13': 'Saatgut',
  'app-gfm-category-14': 'Sträucher',
  'app-gfm-category-15': 'Bambus',
  'app-gfm-category-16': 'Clematis',
  'app-gfm-category-17': 'Rhododendren',
  'app-gfm-category-18': 'Teichpflanzen',
  'app-gfm-category-19': 'Einjährige',
  'app-gfm-category-20': 'Blütenstauden',
  'app-gfm-category-21': 'Blattschmuckstauden',
  'app-gfm-category-22': 'Halbstrauch',
  'app-gfm-style-1': 'Flower Power',
  'app-gfm-style-2': 'Jungle',
  'app-gfm-style-3': 'Cosmopolitan',
  'app-gfm-style-4': 'Back to nature',
  'app-gfm-style-5': 'save the bees',
  'app-gfm-style-6': 'mediterran',
  'gfm-app-lifespan-1': 'einjährig',
  'gfm-app-lifespan-2': 'zweijährig',
  'gfm-app-lifespan-3': 'mehrjährig',
  'gfm-app-animalCompatibility-1': 'Schmetterlinge',
  'gfm-app-animalCompatibility-2': 'bienenfreundlich',
  'gfm-app-animalCompatibility-3': 'Vögel',
  'gfm-app-animalResistance-1': 'schneckenresitent',
  'gfm-app-animalResistance-2': 'kaninchenresitent',
  'gfm-app-animalResistance-3': 'wühlmäuseresistent',
  'gfm-app-soilType-1': 'Sandboden',
  'gfm-app-soilType-2': 'Lehmboden',
  'gfm-app-soilType-3': 'Tonboden',
  'gfm-app-soilType-4': 'leichter Boden',
  'gfm-app-soilType-5': 'schwerer Boden',
  'gfm-app-soilType-6': 'frischer/normaler Boden',
  'gfm-app-soilType-7': 'saurer Boden',
  'gfm-app-soilType-8': 'stickstoffarmer Boden',
  'gfm-app-soilType-9': 'stickstoffreicher Boden',
  'gfm-app-winterZone-1': 'Z1 unter -45,5',
  'gfm-app-winterZone-2': 'Z2 -45,5 bis -40,1',
  'gfm-app-winterZone-3': 'Z3 -40,1 bis -34,5',
  'gfm-app-winterZone-4': 'Z4 -34,5 bis -28,9',
  'gfm-app-winterZone-5': 'Z5 -28,8 bis -23,4',
  'gfm-app-winterZone-6': 'Z6 -23,4 bis -17,8 v. a. wintergrüne Pflanzen bei Kahlfrost schützen',
  'gfm-app-winterZone-7': 'Z7 -17,8 bis -12,3 v. a. wintergrüne Pflanzen bei Kahlfrost schützen',
  'gfm-app-winterZone-8': 'Z8 -12,3 bis -6,7 Winterschutz notwendig, z. B. aus Laub und/oder Reisig; in rauen Lagen möglichst drinnen hell und kühl überwintern',
  'gfm-app-winterZone-9': 'Z9 -6,7 bis -1,2 intensiver Winterschutz (z.B. mit Vlies); besser drinnen hell und kühl überwintern',
  'gfm-app-winterZone-10': 'Z10 -1,2 bis +4,4 frostfreie Überwinterung notwendig, 5-15 °C (z.B. Wintergarten, Treppenhaus)',
  'gfm-app-winterZone-11': 'Z11 über +4,4',
  APP_GFM_PUBLISH_POSSIBLE: 'vollständig',
  APP_GFM_PUBLISH_NOT_POSSIBLE: 'unvollständig',
  'To set the status to publish, the data must be 100% filled.': 'Um eine Planze den Status Publish zu geben, müssen alle wichtigen Daten ausgefüllt sein.',
  'All data is present therefore the status can be set to publish.': 'Alle wichtigen Daten sind vorhanden, diese Pflanze kann auf Publish gesetzt werden.',
  'If the plant can be published with the current state, depends on the settings of the publish method.': 'Ob die Planze mit diesem Status veröffentlicht werden kann, ist abhängig von den Einstellungen der Publish Methode.',
  'app-plants-permissions-read': 'Pflanze anzeigen',
  'app-plants-permissions-list': 'Pflanzen auflisten',
  'app-plants-permissions-create': 'Pflanze erstellen',
  'app-plants-permissions-edit': 'Pflanze bearbeiten',
  'app-plants-permissions-remove': 'Pflanze entfernen',
  'app-plants-permissions-settings': 'GFM App Einstellungen bearbeiten',
  'app-plants-permissions-history': 'Historie anzeigen',
  'app-gfm-incomplete': 'Daten erforderlich',
};
