export default {
  'app-partner': 'Partner',
  'app-partner-company-admin-edit': 'Partner Ubersicht',
  'app-partner-company-admin-edit-contract': 'Vertrag bearbeiten',
  'app-partner-company-admin-edit-contacts': 'Ansprechpartner bearbeiten',
  'app-partner-permissions-read': 'Partner anzeigen',
  'app-partner-permissions-create': 'Partner erstellen',
  'app-partner-permissions-edit': 'Partner bearbeiten',
  'app-partner-permissions-files': 'Partner Dateien bearbeiten',
  'app-partner-sample-project': 'Beispielprojekt',
  'app-partner-sample-project-description': 'Die ausgewaehlten Inhalte werden von dem Projekt lilaquadrat sample kopiert.',
  'add parter': 'Partner hinzufugen',
  'select with data should be copied from the sample project': 'Bitte auswählen was aus dem Beispielprojekt kopiert werden soll',
  APP_PARTNER_CONTRACT_CONFIRMED: 'Vertrag wurde bestatigt',
  APP_PARTNER_CONTRACT_UNCONFIRMED: 'Vertrag wurde nicht bestatigt',
  APP_PARTNER_CONTRACT_STATUS: 'Vertragsstatus',
  APP_PARTNER_CONFIRM_CONTRACT: 'Vertrag bestatigen',
  APP_PARTNER_UNCONFIRM_CONTRACT: 'Vertrag ablehnen',
  APP_PARTNER_CONTRACT_MISSING: 'Kein Vertrag vorhanden',
  APP_PARTNER_REMOVE_CONTRACT: 'Vertrag entfernen',
  APP_PARTNER_ACTIVATE: 'Partner aktivieren',
  APP_PARTNER_DEACTIVATE: 'Partner deaktivieren',
  APP_PARTNER_ADD: 'Partner anlegen',
  APP_PARTNER_NEW: 'Neuer Partner',
  app_partner_waiting_for_partner: 'Warte auf Partner',
  app_partner_waiting_for_provider: 'Warte auf lilaquadrat',
  app_partner_contract_not_confirmed: 'Bereit zur Bestatigung',
  app_partner_ready: 'Bereit zur Aktivierung',
  app_partner_new: 'Neu',
  app_partner_active: 'Aktiv',
};
