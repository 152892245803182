import { Route } from 'vue-router/types/router';

const handlFullscreen = (to: Route, store) => {

  let sidescreen: boolean = false;

  if (to.meta.sidescreen) sidescreen = true;

  if (!sidescreen) {

    to.matched.forEach((route) => {

      if (route.meta.sidescreen) sidescreen = true;

    });

  }

  if (sidescreen && !store.state.fullscreen) store.dispatch('fullscreen', true);
  if (!sidescreen && store.state.fullscreen) store.dispatch('fullscreen', false);

};

export default handlFullscreen;
