import dayjs from 'dayjs';
import 'dayjs/locale/de';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

dayjs.locale('de');

export default (value: Date, format?: string, fromNow?: boolean) => {

  if (!value) return '';

  return !fromNow ? dayjs(value).format(format || 'DD.MM.YYYY HH:mm') : dayjs(value).fromNow();

};
