import { Call } from '@libs/lila-call';
import { Auth } from '@libs/lila-auth';
import MainStore, { state } from '@store/main.store';
import GenericStore from '@interfaces/GenericStore.interface';
import { AppFilter, DataObject } from '@lilaquadrat/studio/lib/interfaces';
import StudioSDK from '@libs/StudioSDK';
import AppInterface from '@lilaquadrat/studio/lib/src/interfaces/App.interface';
import BaseCallData from '@interfaces/BaseCallData.interface';
import AppAppModel from '../models/app.model';
import AppAppstoreStoreState from '../interfaces/store.interface';

const AppStore: GenericStore<AppAppstoreStoreState> = {

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },

  namespaced: true,
  strict: true,

  state: {

    data: null,
    search: {
      tags: [],
      search: null,
    },

    admin: false,

    site: null,
    filterActive: false,
    single: null,

    appsToInstall: [],
    appsForOverlay: [],
    appsOverlayInstalled: false,

    project: {
      installable: null,
      installed: false,
    },

    company: {
      installable: null,
      installed: false,
    },


  },

  mutations: {

    data(appStoreState, data: DataObject<AppInterface>): void {

      appStoreState.data = data;

    },

    single(appStoreState, data: AppInterface): void {

      appStoreState.single = data;

    },

    installableProject(appStoreState, data: AppInterface[]): void {

      appStoreState.project.installable = data;

    },

    installedProject(appStoreState, installed: boolean): void {

      appStoreState.project.installed = installed;

    },

    installableCompany(appStoreState, data: AppInterface[]): void {

      appStoreState.company.installable = data;

    },

    installedCompany(appStoreState, installed: boolean): void {

      appStoreState.company.installed = installed;

    },

    installedOverlay(appStoreState, installed: boolean): void {

      appStoreState.appsOverlayInstalled = installed;

    },

    admin(appStoreState, admin: boolean): void {

      appStoreState.admin = admin;

    },

    setAppsForOverlay(mainState, data) {

      mainState.appsForOverlay = data;

    },

    setAppsToInstall(mainState, data) {

      mainState.appsToInstall = data;

    },


  },

  actions: {

    get(store, data: { settings: BaseCallData, query: { filter?: AppFilter, search?: string } }) {

      const sdk = new StudioSDK('apps', AppStore.sdkOptions());

      console.log(data.settings, data.query);

      return !data.settings.admin
        ? sdk.apps.list(+(data?.settings?.site || 1), data?.query?.filter, data?.query?.search)
        : sdk.apps.admin.list(+(data?.settings?.site || 1), data?.query?.filter, data?.query?.search);


    },

    list(store, data: { settings: BaseCallData, query: { filter?: AppFilter, search?: string } }) {

      const sdk = new StudioSDK('apps', AppStore.sdkOptions());

      return sdk.apps.list(+(data?.settings?.site || 1), data?.query?.filter, data?.query?.search);

    },

    apps(store, data: { settings: BaseCallData, query?: any }) {

      const urlArray = ['apps', data.settings.admin ? 'admin' : null, data.settings.company, data.settings.project, 'list', data.settings.site || '1'];
      const call = new Call<AppInterface>('appstore', MainStore.getters.endpoints);


      return call.get('api', `/${urlArray.filter((single) => single).join('/')}`, data.query, Auth.getAuthHeader());

    },

    getActive(store, data: { company: string, project?: string }) {

      const sdk = new StudioSDK('apps', AppStore.sdkOptions());

      return sdk.apps.active(data)
        .then((response) => {

          this.commit(data.company && data.project ? 'Appstore/installableProject' : 'Appstore/installableCompany', response.data.data);
          return response.data;

        });

    },

    listActive(store, data: { company: string, project?: string , search?: string}) {

      const sdk = new StudioSDK('apps', AppStore.sdkOptions());

      return sdk.apps.active(data);

    },

    single(store, data: { settings: BaseCallData, query?: any }) {

      const urlArray = ['apps', data.settings.admin ? 'admin' : null, data.settings.company, data.settings.project, data.settings.id];
      const call = new Call<AppInterface>('appstore', MainStore.getters.endpoints);

      return call.get('api', `/${urlArray.filter((single) => single).join('/')}`, data.query, Auth.getAuthHeader())
        .then((response) => {

          this.commit('Appstore/single', response.r);

        });

    },

    getEndpoint<M>(store, data: { settings: BaseCallData, query?: any }) {

      let urlArray = [];
      const call = new Call<DataObject<M>>('appstore', MainStore.getters.endpoints);

      urlArray.push(data.settings.endpoint);
      if (data.settings.admin) urlArray.push('admin');

      if (data.settings.company) urlArray.push(data.settings.company);

      if (data.settings.project) urlArray.push(data.settings.project);

      if (data.settings.add) {

        urlArray = [...urlArray, ...data.settings.add];

      }

      return call.get('api', `/${urlArray.filter((single) => single).join('/')}`, data.query, Auth.getAuthHeader());

    },

    save(store, data) {

      const urlArray = ['apps', data.admin ? 'admin' : null, state.Company.company];
      const call = new Call<void>('appstore', MainStore.getters.endpoints);

      if (data.data._id) {

        urlArray.push(data.data._id);

        delete data.data._id;
        delete data.data.id;

        return call.put<AppAppModel>('api', `/${urlArray.filter((single) => single).join('/')}`, data.data, Auth.getAuthHeader());

      }


      return call.post('api', `/${urlArray.filter((single) => single).join('/')}`, data.data, Auth.getAuthHeader());


    },

    toggle(store, data) {

      const sdk = new StudioSDK('apps', AppStore.sdkOptions());

      return sdk.apps.toggle(data);

    },

    remove(store, id) {

      const sdk = new StudioSDK('apps', AppStore.sdkOptions());

      return sdk.apps.admin.remove(id);

    },

  },

};

export default AppStore;
