import InstallableApp from '@interfaces/InstallableApp.interface';
import LoadElements from '@mixins/loadElements';
import routes from './routes';
import translations from './translations/translations';

LoadElements.extensions(require.context('./extensions', true, /[a-z-]\w+\.extension\.(ts|vue)$/, 'lazy'), 'history');

const app: InstallableApp = {
  name: 'history',
  publisher: 'lilaquadrat',
  translations,
  routes,
  store: 'AppHistory',
};

export default app;
