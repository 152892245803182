// import ModuleModel from '@models/Module.model';
// import TextblockModel from '@models/Textblock.model';
// import PicturePartialModel from '@models/PicturePartialModel';
// import modules from '@apps/editor/editor-modules';
// import LinkPartialModel from '@models/Link.model';

// export default class BlogIntroModuleModel extends ModuleModel {

//   type: keyof typeof modules = 'blog-intro-module';

//   uuid = true;

//   author = '';

//   date = '';

//   textblock = TextblockModel;

//   picture = PicturePartialModel;

//   link = LinkPartialModel;

// }

import Models, { ModelDeclarationExtended, ModelFunctions, ModelOptions } from '@libs/Models.class';
import Link from '@models/Link.model';
import Module from '@models/Module.model';
import PicturePartial from '@models/PicturePartial.model';
import Textblock from '@models/Textblock.model';


export default interface BlogIntroModule extends Module {
  author: string
  date: string
  textblock: Textblock
  picture: PicturePartial
  link: Link
}

const declaration: ModelDeclarationExtended<BlogIntroModule, Module> = {
  author: { type: 'string' },
  date: { type: 'string' },
  textblock: { model: 'textblock' },
  picture: { model: 'picture-partial' },
  link: { model: 'link' },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('blog-intro-module', declaration, functions, options);