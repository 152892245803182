import Models, { ModelDeclaration } from '@libs/Models.class';
import { GenericData } from '@lilaquadrat/interfaces';
import Link from '@models/Link.model';
import Textblock from '../interfaces/textblock.interface';
import Picture from '../interfaces/picture.interface';

export default interface EventsListElement {
  textblock: Textblock
  artist: string
  name: string
  location: string
  callToAction: Link
  link: Link
  genericData: GenericData
  start: Date
  end: Date
  picture: Picture
}

const declaration: ModelDeclaration<EventsListElement> = {
  textblock: { model: 'textblock' },
  artist: { type: 'string' },
  name: { type: 'string' },
  location: { type: 'string' },
  picture: { model: 'picture-partial' },
  genericData: { model: 'generic-data' },
  start: { type: 'Date' },
  end: { type: 'Date' },
  callToAction: { model: 'link' },
  link: { model: 'link' },
};

Models.register('events-list-element', declaration);