import LoadElements from '@mixins/loadElements';
import InstallableApp from '../../interfaces/InstallableApp.interface';
import routes from './routes';
import translations from './translations/translations';
// LoadElements.appComponents(require.context('./modules', true, /[a-z-]\w+\.module\.(editor\.)?vue$/, 'lazy'), 'modules', 'hostingadmin');
import './models/command.model';
import './models/settings.model';

LoadElements.appComponents(require.context('./partials', true, /[a-z]\w+\.partial\.(ts|vue)$/, 'lazy'), 'partials', 'hostingadmin');

const app: InstallableApp = {
  name: 'hostingadmin',
  publisher: 'lilaquadrat',
  translations,
  routes,
  store: 'AppHostingAdmin',
};

export default app;
