export default {
  'app-appstore': 'Appstore',
  'app-settings-model': 'App Einstellungen',
  'new app': 'Neues App',
  APP_PROJECT_SUPPORT: 'Auf Projekteebene verfügbar',
  APP_COMPANY_SUPPORT: 'Auf Firmenebene verfügbar',
  APP_REMOVE: 'App entfernen',
  APP_DESCRIPTION: 'Beschreibung',
  APP_NAME: 'App Name',
  APP_ADD: 'App hinzufügen',
  NO_APPS_AVAILABLE: 'Keine verfügbaren Apps',
  'app-app-permissions-read': 'Apps anzeigen',
  'app-app-permissions-create': 'App anlegen',
  'app-app-permissions-edit': 'App bearbeiten',
  'app-app-permissions-remove': 'App entfernen',
  'app-app-permissions-admin': 'App Admin',
  'app-app-permissions-toggle': 'Apps aktivieren/deaktivieren',
  'app-app-permissions-history': 'Historie anzeigen',
  'app-app-attributes': 'Eigenschaften',
  'app-app-unavailable': 'Nicht Verfügbar',
  'app-app-admin': 'Admin',
  'app-app-internal': 'Interne Preview',
  'app-app-preview': 'Public Preview',
  'app-app-public': 'Verfügbar',
  'app-app-paid': 'kostenpflichtig',
  'app-app-active': 'Aktiv',
  'app-app-used-models': 'Model die vom App genutzt werden',
  'app-app-state': 'Status',
  'app-app-start-app': 'App starten',
  'app-app-apps-for-company-title': 'Apps auf Firmenebene',
  'app-app-apps-for-company-description': 'Diese Apps können auf Firmenebene installiert und benutzt werden.',
  'app-app-apps-for-project-title': 'Apps für Projekte',
  'app-app-apps-for-project-description': 'Apps die hier aktiviert werden, stehen allen Projekten dieser Firma via Appstore zur installation zur Verfügung.'
};
