// import PartialModel from '@libs/lila-model';

// export default class AppEditorDataSettingsModel extends PartialModel {

//   noSSR: boolean = false;

//   noRendering: boolean = false;

//   title: string = '';

//   description: string = '';

//   url: string = '';

//   filename: string[] = [];

//   useLayout: string = '';

//   mode: string = '';

// }

import Models, { ModelDeclaration } from '@libs/Models.class';

export default interface EditorDataHtmlSettings {
  title: string
  description: string
  url: string
  useLayout: string
  mode: string
  filename: string[]
  noSSR: boolean
  noRendering: boolean
}

const declaration: ModelDeclaration<EditorDataHtmlSettings> = {
  title: { type: 'string' },
  description: { type: 'string' },
  url: { type: 'string' },
  useLayout: { type: 'string' },
  mode: { type: 'string', default: 'presentation' },
  filename: { type: 'array', contains: { type: 'string' } },
  noSSR: { type: 'boolean', default: false },
  noRendering: { type: 'boolean', default: false },
};


Models.register('editor-data-html-settings', declaration);