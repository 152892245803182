import LoadElements from '@mixins/loadElements';
import InstallableApp from '@interfaces/InstallableApp.interface';
import routes from './routes';
import translations from './translations/translations';

LoadElements.appComponents(require.context('./partials', true, /[a-z]\w+\.partial\.(ts|vue)$/, 'lazy'), 'partials', 'gfm');
LoadElements.appComponents(require.context('./modules', true, /[a-z-]\w+\.module\.(editor\.)?vue$/, 'lazy'), 'modules', 'gfm');


const app: InstallableApp = {
  name: 'gfm',
  publisher: 'lilaquadrat',
  routes,
  translations,
  store: 'AppGfmMain',
};

export default app;
