import GenericStore from '@interfaces/GenericStore.interface';
import StudioSDK, { SDKResponse } from '@libs/StudioSDK';
import { Domain, Hosting } from '@lilaquadrat/studio/lib/interfaces';
import MainStore, { state } from '@store/main.store';
import { AppHostingSettings } from '@lilaquadrat/interfaces';
import AppHostingMainState from '../interfaces/main-state.interface';

const AppHostingStore: GenericStore<AppHostingMainState> = {

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },

  namespaced: true,
  strict: true,

  state: {
    single: null,
    domains: [],
    singleDomain: null,
  },

  mutations: {

    single(appState, single: Hosting) {

      appState.single = single;

    },

    domains(appState, domains: Domain[]) {

      appState.domains = domains;

    },

    singleDomain(appState, domain: Domain) {

      appState.singleDomain = domain;

    },

  },

  actions: {

    get(store) {

      const sdk = new StudioSDK('hosting-app', AppHostingStore.sdkOptions());

      return sdk.hosting.single();

    },

    async refreshSingle(store, data: {company: string, project: string}) {

      const sdk = new StudioSDK('hosting-app', { ...AppHostingStore.sdkOptions(), company: data.company, project: data.project });

      StudioSDK.flushCache('hosting', 'single');

      const single = await sdk.hosting.single();

      return store.commit('single', single.data);

    },

    toggle(store, data: {company: string, project: string, type: 'activate' | 'deactivate'}) {

      const sdk = new StudioSDK('hosting-app', { ...AppHostingStore.sdkOptions(), company: data.company, project: data.project });

      return data.type === 'activate'
        ? sdk.hosting.activate()
        : sdk.hosting.deactivate();

    },

    getDomains(store, data: {settings: BaseCallData, query?: any}) {

      const sdk = new StudioSDK('hosting-app', AppHostingStore.sdkOptions());

      return sdk.hosting.domains.list(+data.settings.site);

    },

    singleDomain(store, data: {_id: string, settings: BaseCallData}) {

      const sdk = new StudioSDK('hosting-app', AppHostingStore.sdkOptions());

      return sdk.hosting.domains.single(data._id);

    },

    addDomain(store, data: Domain) {

      const sdk = new StudioSDK('hosting-app', AppHostingStore.sdkOptions());

      return sdk.hosting.domains.create(data);

    },

    updateDomain(store, data: {data: Domain, _id: string}) {

      const sdk = new StudioSDK('hosting-app', AppHostingStore.sdkOptions());

      return sdk.hosting.domains.update(data._id, data.data);

    },

    removeDomain(store, _id: string) {

      const sdk = new StudioSDK('hosting-app', AppHostingStore.sdkOptions());

      return sdk.hosting.domains.remove(_id);

    },

    validateDomain(store, _id: string) {

      const sdk = new StudioSDK('hosting-app', AppHostingStore.sdkOptions());

      return sdk.hosting.domains.validate(_id);

    },

    updateSettings(store, settings: AppHostingSettings) {

      const sdk = new StudioSDK('hosting-app', AppHostingStore.sdkOptions());

      return sdk.hosting.updateSettings(settings);

    },

    /**
     * This action will be called, when the app will be activated
     */
    async activate(store, params) {

      const single: SDKResponse<Hosting> = await store.dispatch('get');

      store.commit('single', single.data);

    },
  },

};

export default AppHostingStore;
