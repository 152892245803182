import GenericStore from '@interfaces/GenericStore.interface';
import MainStore, { state } from '@store/main.store';
import StudioSDK from '@libs/StudioSDK';
import { Customers, DataObject } from '@lilaquadrat/studio/lib/interfaces';
import AppCustomersMainState from '../interfaces/main-state.interface';

const AppCustomersStore: GenericStore<AppCustomersMainState> = {
  namespaced: true,
  strict: true,

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },

  state: {

    data: {},

    search: {
      tags: [],
      search: null,
      type: null,
      sort: 'name',
      order: '1',
    },

    single: null,

  },

  mutations: {

    data(store, data: DataObject<Customers>) {

      store.data = data;

    },

    single(store, data: Customers) {

      store.single = data;

    },


    updateSearch(appCustomersState, update: { data: any, type: string }) {

      console.log(update, update.data.length, !update.data.length, appCustomersState.search);

      if (!update.data.length) {

        appCustomersState.search[update.type] = null;
        return;

      }

      console.log(update, appCustomersState.search);

      appCustomersState.search[update.type] = update.data;

    },

    setSearch(appCustomersState, search: { tags?: string[], search?: string, type?: string }) {

      appCustomersState.search = search;

    },

  },

  actions: {

    get(store, data: {settings: BaseCallData, query?: {search?: string, tags?: string[], type?: string, sort?: number, order?: string}}) {

      const sdk = new StudioSDK('customers-app', AppCustomersStore.sdkOptions());

      return sdk.customers.list(+data.settings.site, data.query.search, data.query.tags, data.query.type, data.query.sort, data.query.order);

    },

    single(store, data: {settings: BaseCallData, _id?: string}) {

      const sdk = new StudioSDK('customers-app', AppCustomersStore.sdkOptions());

      return sdk.customers.single(data._id);

    },

    update(store, data: {data: Customers, id: string}) {

      const sdk = new StudioSDK('customers-app', AppCustomersStore.sdkOptions());

      return sdk.customers.update(data.id, data.data);

    },

    add(store, data: Customers) {

      const sdk = new StudioSDK('customers-app', AppCustomersStore.sdkOptions());

      return sdk.customers.add(data);

    },

    remove(store, id: string) {

      const sdk = new StudioSDK('customers-app', AppCustomersStore.sdkOptions());

      return sdk.customers.remove(id);

    },

    /**
     * This action will be called, when the app will be activated
     */
    activate() {

      return Promise.resolve(true);

    },
  },

};

export default AppCustomersStore;
