import { Call } from '@libs/lila-call';
import { Auth } from '@libs/lila-auth';
import MainStore from '@store/main.store';
import GenericStore from '@interfaces/GenericStore.interface';
import { DataObject } from '@lilaquadrat/studio/lib/interfaces';

const AppGfmMainStore: GenericStore<any> = {
  namespaced: true,
  strict: true,

  state: {

    settings: {},
    project: null,
    data: {
      sites: {},
    },
    search: {
      tags: [],
      search: null,
    },

    site: null,
    filterActive: false,

    noticeCache: {},
  },

  mutations: {

    data(state, data) {

      state.data = data;

    },

    setSearch(state, search: { tags?: string[], search?: string }) {

      if (search.tags) {

        if (typeof search.tags === 'string') {

          state.search.tags = [search.tags];

        } else {

          state.search.tags = search.tags;

        }

      } else {

        state.search.tags = [];

      }

      if (search.search) {

        state.search.search = search.search;

      } else {

        delete state.search.search;

      }

    },

    updateSearch(state, update: { data: any, type: string }) {

      if (!update.data.length) {

        delete state.search[update.type];
        return;

      }


      state.search[update.type] = update.data;

    },

    setSite(state, site: number) {

      state.site = site || 0;

    },

    toggleFilter(state, active: boolean) {

      state.filterActive = active;

    },

    addNoticeCache(state, data: {key: string, data: any}) {

      state.noticeCache[data.key] = data.data;
      state.noticeCache = { ...state.noticeCache };

    },

    settings(state, settings) {

      console.log(settings);
      state.settings = settings;

    },
  },

  actions: {

    get(store, data: {settings: BaseCallData, query?: any}) {

      const urlArray = ['universal', data.settings.company, data.settings.project, 'gfm', 'plants', 'list'];
      const call = new Call<DataObject<Gfm>>('gfm', MainStore.getters.endpoints);

      if (data.settings.site) {

        urlArray.push(data.settings.site);

      }
      // `/data/${data.settings.company}/${data.settings.project}`

      return call.get('api', `/${urlArray.join('/')}`, data.query, Auth.getAuthHeader());

    },

    getSettings(store, data) {

      console.log('GET SETTINGS', store.state.settings?.app);

      const call = new Call('universal', MainStore.getters.endpoints);

      return call.get('api', `/universal/${data.company}/${data.project}/gfm/plants/settings`, {}, Auth.getAuthHeader());

    },

    settings(store, data) {

      const call = new Call('universal', MainStore.getters.endpoints);

      return call.put('api', `/universal/${data.company}/${data.project}/gfm/plants/settings`, data.settings, Auth.getAuthHeader())
        .then(() => store.dispatch('getSettings', data))
        .then((settings) => store.commit('settings', settings.r));

    },

    activate(store, params) {

      console.log('CALL ACTIVATE');

      if (!store.state.settings?.editorUrl) {

        return store.dispatch('getSettings', params)
          .then((settings) => store.commit('settings', settings.r));

      }

      return Promise.resolve(true);

    },

  },

};

export default AppGfmMainStore;
