import { UnifiedRouteGuard } from '@libs/lila-auth';

const loadScreen = (screen: string) => () => import(
  /* webpackChunkName: "app-lists" */
  `./screens/${screen}`
).then((m) => m.default);

export default [
  {
    path: '/:company/:project/a/lists',
    name: 'app-lists-project-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home.screen.vue'),
    meta: {
      animationGroup: 'lists',
      app: 'lists',
      parent: true,
      rememberSettings: true,
    },
    children: [
      {
        path: 'add',
        component: loadScreen('single.screen.vue'),
        name: 'app-lists-project-add',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-lists-project-add',
          app: 'lists',
        },
      },
    ],
  },
  {
    path: '/:company/:project/a/lists/:id/:site?',
    name: 'app-lists-project-single-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home-single-list.screen.vue'),
    meta: {
      animationGroup: 'lists',
      app: 'list-participants',
      parent: true,
      rememberSettings: true,
      addParams: ['id']
    },
    children: [
      {
        path: 'edit',
        component: loadScreen('single.screen.vue'),
        name: 'app-lists-project-single-edit',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-lists-project-single-edit',
          app: 'list-participants',
        },
      },
      {
        path: 'participant/:participantId',
        component: loadScreen('participant.screen.vue'),
        name: 'app-lists-project-participant',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-lists-project-participant',
          app: 'list-participants',
        },
      },
    ],
  },
];
