export default {
  'app-history': 'Historie',
  'app-history-not-applicable': 'Nicht relevant',
  'app-history-change': 'geändert',
  'app-history-create': 'erstellt',
  'app-history-recreate': 'wiederhergestellt',
  'app-history-delete': 'gelöscht',
  'app-history-copy': 'kopiert',
  'app-history-permissions-read': 'Historie anzeigen',
};
