// import PartialModel from '@libs/lila-model';

// export default abstract class ModuleIndexModel extends PartialModel {

//   anchor: string = '';

//   title: string = '';

// }

import Models, { ModelDeclaration, ModelFunctions } from '@libs/Models.class';

export default interface ModuleIndex {
  anchor: string
  title: string
}

const declaration: ModelDeclaration<ModuleIndex> = {
  anchor: { type: 'string' },
  title: { type: 'string' },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};

Models.register('module-index', declaration, functions);