// import PartialModel from '@libs/lila-model';

// export default class DomainSecretModel extends PartialModel {

//   key: string = '';

//   id: string = '';

// }

import Models, { ModelDeclaration } from '@libs/Models.class';

export default interface DomainSecret {
  key: string
  id: string
}

const declaration: ModelDeclaration<DomainSecret> = {
  key: { type: 'string' },
  id: { type: 'string' },
};

Models.register('domain-secret', declaration);
