import InstallableApp from '@interfaces/InstallableApp.interface';
import LoadElements from '@mixins/loadElements';
import routes from './routes';
import translations from './translations/translations';


LoadElements.appComponents(require.context('./modules', true, /[a-z]\w+\.module\.(ts|vue)$/, 'lazy'), 'modules', 'learn');
LoadElements.extensions(require.context('./extensions', true, /[a-z-]\w+\.extension\.(ts|vue)$/, 'lazy'), 'learn');

const app: InstallableApp = {
  name: 'learn',
  publisher: 'lilaquadrat',
  translations,
  routes,
  store: 'AppLearn',
};

export default app;
