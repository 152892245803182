import LoadElements from '@mixins/loadElements';
import InstallableApp from '../../interfaces/InstallableApp.interface';
import routes from './routes';
import translations from './translations/translations';

import './models/mailfrom.model';

LoadElements.extensions(require.context('./extensions', true, /[a-z-]\w+\.extension\.(ts|vue)$/, 'lazy'), 'publishadmin');

const app: InstallableApp = {
  name: 'publishadmin',
  publisher: 'lilaquadrat',
  translations,
  routes,
  store: 'AppPublishadmin',
};

export default app;
