import { SDKResponse } from "@libs/StudioSDK";

const mockJs: Record<string, SDKResponse<unknown>> = {
    '/apps/company/project/active': {
        data: {
            data: [
                {
                    _id: "62b19f5289cda550b9e152b6",
                    id: "editor",
                    name: "editor",
                    companyAvailable: false,
                    projectAvailable: true,
                    description: "Editor App",
                    available: true,
                    history: {
                        version: 0,
                        created: "2022-06-21T10:37:06.589Z",
                        source: "setup"
                    },
                    active: true
                },
                {
                    _id: "62b19f5289cda550b9e152b4",
                    id: "media",
                    name: "media",
                    companyAvailable: false,
                    projectAvailable: true,
                    description: "Media App",
                    available: true,
                    history: {
                        version: 0,
                        created: "2022-06-21T10:37:06.584Z",
                        source: "setup"
                    },
                    active: true
                },
                {
                    _id: "62b19f5289cda550b9e152b5",
                    id: "history",
                    name: "history",
                    companyAvailable: true,
                    projectAvailable: true,
                    description: "History App",
                    available: true,
                    history: {
                        version: 0,
                        created: "2022-06-21T10:37:06.586Z",
                        source: "setup"
                    },
                    active: true
                },
                {
                    _id: "62b19f5289cda550b9e152b3",
                    id: "publish",
                    name: "publish",
                    companyAvailable: false,
                    projectAvailable: true,
                    description: "Publish App",
                    available: true,
                    history: {
                        version: 0,
                        created: "2022-06-21T10:37:06.578Z",
                        source: "setup"
                    },
                    active: true
                },
            ]
        },
        status: 200,
    },
    '/apps/company/homepage/active': {
        data: {
            "data": [
                {
                    "_id": "6137273ae6726f40f118c5fc",
                    "id": "editor",
                    "name": "editor",
                    "available": "public",
                    "projectAvailable": true,
                    "active": true
                },
                {
                    "_id": "6137274ee6726f40f118c5fe",
                    "id": "media",
                    "name": "media",
                    "available": "public",
                    "projectAvailable": true,
                    "active": true
                },
                {
                    "_id": "6137276ee6726f40f118c602",
                    "id": "publish",
                    "name": "publish",
                    "available": "public",
                    "projectAvailable": true,
                    "models": ["publishMethods"],
                    "active": true
                },
                {
                    "_id": "6137275ce6726f40f118c600",
                    "id": "history",
                    "name": "history",
                    "available": "public",
                    "companyAvailable": true,
                    "projectAvailable": true,
                    "active": true
                },
                {
                    "_id": "6203c958b0df5946b9bf3e74",
                    "id": "learn",
                    "name": "learn",
                    "companyAvailable": true,
                    "projectAvailable": true,
                    "available": "public",
                    "active": true
                },
                {
                    "_id": "631ca6f28b07782e0409df74",
                    "id": "hosting",
                    "name": "hosting",
                    "available": "internal",
                    "projectAvailable": true,
                    "models": ["domains"],
                    "active": true
                },
                {
                    "_id": "63ad60b2154a872c513346ce",
                    "id": "tracker",
                    "name": "tracker",
                    "available": "internal",
                    "projectAvailable": true,
                    "active": true
                },
                {
                    "_id": "652fbef5e80e8807396a5062",
                    "id": "lists",
                    "name": "lists",
                    "description": "Listen",
                    "available": "preview",
                    "models": ["listsParticipants"],
                    "projectAvailable": true,
                    "active": true
                },
            ],
            "all": 11,
            "count": 8
        }, status: 200
    },

    '/apps/company/active': {
        data: {
            "data": [
                // history und learn sind auch auf unserer homepage noch leer

                {
                    "_id": "6137275ce6726f40f118c600",
                    "id": "history",
                    "name": "history",
                    "available": "public",
                    "companyAvailable": true,
                    "projectAvailable": true,
                    "active": true
                },
                {
                    "_id": "6203c958b0df5946b9bf3e74",
                    "id": "learn",
                    "name": "learn",
                    "companyAvailable": true,
                    "projectAvailable": true,
                    "available": "public",
                    "active": true
                },
                {
                    "_id": "643559cc48c052560f2a19d3",
                    "id": "customers",
                    "name": "customers",
                    "available": "preview",
                    "companyAvailable": true,
                    "active": true
                },
                {
                    "_id": "6544ff3d7a5667fe80b33eba",
                    "id": "hostingadmin",
                    "name": "hosting-admin",
                    "available": "admin",
                    "companyAvailable": true,
                    "active": true
                },
                {
                    "_id": "6628c555280f808eba97f966",
                    "id": "publishadmin",
                    "name": "publishadmin",
                    "available": "admin",
                    "companyAvailable": true,
                    "active": true
                }
            ],
            "all": 11,
            "count": 5
        }, status: 200
    },

    '/apps/company/homepage/list/1': {
        data: {
            "data": [
                {
                    "_id": "6137273ae6726f40f118c5fc",
                    "id": "editor",
                    "name": "editor",
                    "available": "public",
                    "projectAvailable": true,
                    "active": true
                },
                {
                    "_id": "6137274ee6726f40f118c5fe",
                    "id": "media",
                    "name": "media",
                    "available": "public",
                    "projectAvailable": true,
                    "active": true
                },
                {
                    "_id": "6137276ee6726f40f118c602",
                    "id": "publish",
                    "name": "publish",
                    "available": "public",
                    "projectAvailable": true,
                    "models": ["publishMethods"],
                    "active": true
                },
                {
                    "_id": "6137275ce6726f40f118c600",
                    "id": "history",
                    "name": "history",
                    "available": "public",
                    "companyAvailable": true,
                    "projectAvailable": true,
                    "active": true
                },
                {
                    "_id": "6203c958b0df5946b9bf3e74",
                    "id": "learn",
                    "name": "learn",
                    "available": "public",
                    "companyAvailable": true,
                    "projectAvailable": true,
                    "active": true
                }
            ],
            "all": 11,
            "sites": {
                "first": 1,
                "current": 1,
                "last": 1,
                "range": [1, 5]
            },
            "count": 5
        }
        , status: 200
    },

    //content

    '/content/lernen/searchFooter': {
        "data": {
            "_id": "620a39cec5c361853b8536cd",
            "settings": {
                "url": "searchFooter",
                "mode": "presentation"
            },
            "id": "search-footer",
            "state": "publish",
            "modules": [
                {
                    "uuid": "49da240b-6374-41b3-a5b6-caf312539bfb",
                    "type": "footer-module",
                    "legal": "Lernen Footer"
                }
            ],
            "company": "lilaquadrat",
            "project": "lernen",
            "history": {
                "version": 1,
                "created": "2022-02-14T11:15:26.322Z",
                "source": "editor",
                "updated": "2022-02-14T11:15:50.037Z"
            },
            "childData": {
                "data": {}
            }
        },
        "status": 200
    },
    '/content/lernen/searchHome': {
        data: {
            "_id": "6203cdfbb0df5946b9bf3e86",
            "settings": {
                "url": "searchHome",
                "mode": "presentation"
            },
            "id": "search_home",
            "state": "publish",
            "company": "lilaquadrat",
            "project": "lernen",
            "history": {
                "version": 14,
                "created": "2022-02-09T14:21:47.611Z",
                "source": "editor",
                "updated": "2022-08-17T06:24:16.014Z"
            },
            "modules": [
                {
                    "uuid": "5872c9b8-2abc-450d-9d3f-5e92ad6d5b1e",
                    "type": "text-module",
                    "headline": "STUDIO - Lernen.",
                    "intro": "Hier finden Sie Erklärungen zur Suche, wie diese funktioniert und erste, wichtige Links.",
                    "text": [
                        "Egal, ob Sie schon Fachkenntnisse besitzen oder mit STUDIO gerade erst anfangen: unser praxisorientiertes Lerntool und unsere Kurse helfen Ihnen dabei, selbstbewusst und in Ihrer eigenen Geschwindigkeit zu arbeiten."
                    ]
                },
                {
                    "uuid": "b080a429-e9fc-4139-811d-2e7b0b80038e",
                    "type": "text-module",
                    "intro": "Unsere integrierte Suche und die Hilfeseiten können einfach per Klick auf die Glühbirne am oberen rechten Bildschirmrand aufgerufen werden - Hilfe wird passend zur aktuellen Seite automatisch angezeigt."
                },
                {
                    "uuid": "9c9ce8ff-e155-4f7d-bde8-aa4d3a9f478c",
                    "id": "Häufig gesuchte Topics",
                    "type": "text-module",
                    "subline": "Häufig gesuchte Topics:",
                    "links": {
                        "value": [
                            {
                                "text": "link1",
                                "link": "/",
                                "attributes": ["static"]
                            },
                            {
                                "text": "link#",
                                "link": "/",
                                "attributes": ["static"]
                            },
                            {
                                "text": "link#3",
                                "link": "/",
                                "attributes": ["static"]
                            }
                        ]
                    }
                },
                {
                    "uuid": "b7d8af0d-3a36-4af8-a581-79da72f918a5",
                    "id": "Kursübersicht",
                    "variant": ["centerText", "variant1"],
                    "type": "picturegroup-module",
                    "elements": [
                        {
                            "picture": {
                                "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/pexels-simon-berger-1323550.jpg",
                                "file": {
                                    "id": "62f642dac213eee3698bca32",
                                    "filename": "pexels-simon-berger-1323550.jpg",
                                    "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/pexels-simon-berger-1323550.jpg",
                                    "thumb": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/250_pexels-simon-berger-1323550.jpg"
                                }
                            },
                            "link": {
                                "link": "/kurs/editor/firma/uebersicht",
                                "attributes": ["event"]
                            },
                            "textblock": {
                                "headline": "KURS: Firma",
                                "text": [
                                    "Firma anlegen",
                                    "Ich habe Zugriff aber noch keine Firma – woher bekomme ich die? (Partner kontaktieren etc.)",
                                    "Stammdatenverwaltung (Änderungen nur über Partner möglich)",
                                    "Kontakt zum Partner",
                                    "Projektverwaltung",
                                    "Mitarbeiterverwaltung (APP) - Übersichtsseite TEAM",
                                    "Lizenzverwaltung (APP) - wann hat wer was gekauft, Laufzeiten etc.",
                                    "App-Verwaltung"
                                ]
                            }
                        },
                        {
                            "picture": {
                                "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/pexels-mo-eid-9002742.jpg",
                                "file": {
                                    "id": "62f642c5c213eee3698bca30",
                                    "filename": "pexels-mo-eid-9002742.jpg",
                                    "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/pexels-mo-eid-9002742.jpg",
                                    "thumb": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/250_pexels-mo-eid-9002742.jpg"
                                }
                            },
                            "link": {
                                "link": "kurs/editor/account/uebersicht",
                                "attributes": ["event"]
                            },
                            "textblock": {
                                "headline": "KURS: mein Account",
                                "text": [
                                    "Wie erstelle ich meinen Account / registriere ich mich",
                                    "Übersicht der Funktionen (Screenshots): Datensatz anlegen, Medien, Projekteinstellungen; Meine Seite / Firmenseite / Projektseite (Screenshots)",
                                    "Wie werde ich einer Firma zugewiesen? (ohne Video, evtl. Screenshots --> Verweis auf Video wie das ein Admin in der Firma machen würde, siehe Kursname Firma)",
                                    "Wie werde ich einem Projekt zugewiesen? (ohne Video, evtl. Screenshots --> Verweis auf Video wie das ein Admin in der Firma machen würde)",
                                    "Wie kann ich mein Passwort ändern",
                                    "Ich habe mein Passwort vergessen",
                                    "Wie kann ich meine E-Mail-Adresse ändern",
                                    "Voraussetzungen zur Benutzung der Software (Browser etc.)",
                                    "Kontakt bei Problemen und Fragen (--> mein Partner)",
                                    "Ich habe Zugriff aber noch keine Firma – woher bekomme ich die? (Partner kontaktieren etc.)",
                                    "Ich bin unzufrieden mit Software/Wiederverkäufer",
                                    "Vorhandensein einer Firma & eines Projekts um beginnen zu können",
                                    "App-Verwaltung"
                                ]
                            }
                        },
                        {
                            "picture": {
                                "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/thumb2-4k-abstract-landscape-sunset-mountains-canyon.jpg",
                                "file": {
                                    "id": "62f646bec213eee3698bca36",
                                    "filename": "thumb2-4k-abstract-landscape-sunset-mountains-canyon.jpg",
                                    "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/thumb2-4k-abstract-landscape-sunset-mountains-canyon.jpg",
                                    "thumb": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/250_thumb2-4k-abstract-landscape-sunset-mountains-canyon.jpg"
                                }
                            },
                            "textblock": {
                                "headline": "Kurs: Medien",
                                "text": [
                                    "Wie werde ich einer Firma zugewiesen? (ohne Video, evtl. Screenshots --> Verweis auf Video wie das ein Admin in der Firma machen würde, siehe Kursname Firma)",
                                    "Wie werde ich einem Projekt zugewiesen? (ohne Video, evtl. Screenshots --> Verweis auf Video wie das ein Admin in der Firma machen würde)",
                                    "Wie werde ich einer Firma zugewiesen? (ohne Video, evtl. Screenshots --> Verweis auf Video wie das ein Admin in der Firma machen würde, siehe Kursname Firma)",
                                    "Wie werde ich einem Projekt zugewiesen? (ohne Video, evtl. Screenshots --> Verweis auf Video wie das ein Admin in der Firma machen würde)"
                                ]
                            }
                        },
                        {
                            "picture": {
                                "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/pexels-mo-eid-9002742.jpg",
                                "file": {
                                    "id": "62f642c5c213eee3698bca30",
                                    "filename": "pexels-mo-eid-9002742.jpg",
                                    "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/pexels-mo-eid-9002742.jpg",
                                    "thumb": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/250_pexels-mo-eid-9002742.jpg"
                                }
                            }
                        },
                        {
                            "picture": {
                                "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/thumb2-4k-abstract-landscape-sunset-mountains-canyon.jpg",
                                "file": {
                                    "id": "62f646bec213eee3698bca36",
                                    "filename": "thumb2-4k-abstract-landscape-sunset-mountains-canyon.jpg",
                                    "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/thumb2-4k-abstract-landscape-sunset-mountains-canyon.jpg",
                                    "thumb": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/250_thumb2-4k-abstract-landscape-sunset-mountains-canyon.jpg"
                                }
                            }
                        },
                        {
                            "picture": {
                                "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/pexels-simon-berger-1323550.jpg",
                                "file": {
                                    "id": "62f642dac213eee3698bca32",
                                    "filename": "pexels-simon-berger-1323550.jpg",
                                    "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/pexels-simon-berger-1323550.jpg",
                                    "thumb": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/250_pexels-simon-berger-1323550.jpg"
                                }
                            }
                        },
                        {
                            "picture": {
                                "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/thumb2-4k-abstract-landscape-sunset-mountains-canyon.jpg",
                                "file": {
                                    "id": "62f646bec213eee3698bca36",
                                    "filename": "thumb2-4k-abstract-landscape-sunset-mountains-canyon.jpg",
                                    "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/thumb2-4k-abstract-landscape-sunset-mountains-canyon.jpg",
                                    "thumb": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/250_thumb2-4k-abstract-landscape-sunset-mountains-canyon.jpg"
                                }
                            }
                        },
                        {
                            "picture": {
                                "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/pexels-simon-berger-1323550.jpg",
                                "file": {
                                    "id": "62f642dac213eee3698bca32",
                                    "filename": "pexels-simon-berger-1323550.jpg",
                                    "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/pexels-simon-berger-1323550.jpg",
                                    "thumb": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/250_pexels-simon-berger-1323550.jpg"
                                }
                            }
                        },
                        {
                            "picture": {
                                "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/pexels-mo-eid-9002742.jpg",
                                "file": {
                                    "id": "62f642c5c213eee3698bca30",
                                    "filename": "pexels-mo-eid-9002742.jpg",
                                    "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/pexels-mo-eid-9002742.jpg",
                                    "thumb": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/250_pexels-mo-eid-9002742.jpg"
                                }
                            }
                        }
                    ],
                    "textblock": {
                        "headline": "Module"
                    }
                },
                {
                    "uuid": "6e99cbbd-e3c2-41f8-80e9-d032f8fb0a15",
                    "type": "gallery-module",
                    "elements": [
                        {
                            "picture": {
                                "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/pexels-david-yu-2684383.jpg",
                                "file": {
                                    "id": "62f6470ac213eee3698bca42",
                                    "filename": "pexels-david-yu-2684383.jpg",
                                    "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/pexels-david-yu-2684383.jpg",
                                    "thumb": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/250_pexels-david-yu-2684383.jpg"
                                }
                            }
                        },
                        {
                            "uuid": "b0f62880-54f4-4553-a3c7-a5baadd0fd7f",
                            "picture": {
                                "uuid": "b1f62baf-89b2-4e50-bb22-b0e1b9997524",
                                "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/pexels-mo-eid-9002742.jpg",
                                "file": {
                                    "uuid": "f1d7ffd5-3f96-4fa8-aabb-dc7424abd2f7",
                                    "id": "62f642c5c213eee3698bca30",
                                    "filename": "pexels-mo-eid-9002742.jpg",
                                    "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/pexels-mo-eid-9002742.jpg",
                                    "thumb": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/250_pexels-mo-eid-9002742.jpg"
                                },
                                "source": [
                                    {
                                        "uuid": "ecc1b84d-5e11-4bd9-84b7-4d1a10360291",
                                        "file": {
                                            "uuid": "4bfc95a5-681a-43bd-abee-6210465b009a"
                                        }
                                    },
                                    {
                                        "uuid": "d9f6eb44-51ba-4696-8e68-f7416bf1fe48",
                                        "file": {
                                            "uuid": "25e6c6ab-87d1-4c8c-9668-693b6720619d"
                                        }
                                    },
                                    {
                                        "uuid": "10a40175-a1c1-4eb4-bdd7-98e6f3fb00ae",
                                        "file": {
                                            "uuid": "b66ad24e-4771-4e50-84a4-f0376130c706"
                                        }
                                    }
                                ]
                            },
                            "textblock": {
                                "uuid": "950269a7-1da2-45ba-a62c-9f59ccb824a7"
                            }
                        },
                        {
                            "picture": {
                                "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/pexels-simon-berger-1323550.jpg",
                                "file": {
                                    "id": "62f642dac213eee3698bca32",
                                    "filename": "pexels-simon-berger-1323550.jpg",
                                    "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/pexels-simon-berger-1323550.jpg",
                                    "thumb": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/250_pexels-simon-berger-1323550.jpg"
                                }
                            }
                        },
                        {
                            "uuid": "39a81f2b-c147-468e-b3f3-7a9ac4297a1d",
                            "picture": {
                                "uuid": "0138cfa5-4332-4705-90ab-802e8841149f",
                                "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/thumb2-4k-abstract-landscape-sunset-mountains-canyon.jpg",
                                "file": {
                                    "uuid": "0e1ad992-f8f9-4436-b0a0-be22f2eea71b",
                                    "id": "62f646bec213eee3698bca36",
                                    "filename": "thumb2-4k-abstract-landscape-sunset-mountains-canyon.jpg",
                                    "src": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/thumb2-4k-abstract-landscape-sunset-mountains-canyon.jpg",
                                    "thumb": "https://cdn2.lilaquadrat.de/lilaquadrat/lernen/250_thumb2-4k-abstract-landscape-sunset-mountains-canyon.jpg"
                                },
                                "source": [
                                    {
                                        "uuid": "599e22ea-161f-4400-b883-e2df17457f8e",
                                        "file": {
                                            "uuid": "8941bab4-c792-449e-bbd7-351f4f4df355"
                                        }
                                    },
                                    {
                                        "uuid": "84025b6a-bfae-49b7-8c17-bd9f0b57761c",
                                        "file": {
                                            "uuid": "e415331f-a74e-4608-a943-bd01ef8e7782"
                                        }
                                    },
                                    {
                                        "uuid": "845e365f-f6e6-4932-b6e4-d9745edf76e4",
                                        "file": {
                                            "uuid": "ea2b556c-86bc-4772-acfc-0cfb33d57d4c"
                                        }
                                    }
                                ]
                            },
                            "textblock": {
                                "uuid": "c25e6580-cc7e-444d-b71e-d981c2e4efd4"
                            }
                        }
                    ]
                }
            ],
            "childData": {
                "data": {}
            }
        },
        status: 200
    },

    '/companies/company': {
        data: {
            _id: "62b19f5289cda550b9e152c2",
            id: "company",
            name: "company",
            apps: [
                "editor",
                "media",
                "publish",
                "history",
                "learn",
                "hosting",
                "tracker",
                "customers",
                "lists",
                "hostingadmin",
                "publishadmin"
            ],
            attributes: [
                "partner",
                "admin",
                'learn',
                "previewInternal",
                "previewPublic"
            ],
            partner: "company",
            streetNumber: "122",
            zipcode: "92224",
            city: "Musterstadt",
            country: "deu",
            history: {
                version: 8,
                created: "2022-06-21T10:37:06.883Z",
                source: "setup",
            }
        },
        status: 200,

    },
    '/companies/company/team': {
        data: {

        }
        ,
        status: 200
    },
    '/companies/company/publish': {
        data: {

        }
        ,
        status: 200
    },

    '/company/project/1': {
        data: {
            data: [

                {
                    _id: "62b19f5289cda550b9e152bc",
                    company: "company",
                    filename: "eflashwq.mp4",
                    id: "eflashwq.mp4",
                    mimetype: "video/mp4",
                    tags: [
                        "type:video/mp4", "video"
                    ],
                    project: "project",
                    video: true,
                    size: 1594640,
                    info: {
                        width: 1200,
                        height: 650,
                        ratio: 1.8461538461538463
                    },
                    history: {
                        version: 0,
                        created: "2022-06-21T10:37:06.841Z",
                        source: "setup"
                    }
                },
                {
                    _id: "62b19f5289cda550b9e152bb",
                    company: "company",
                    filename: "weihnachtsminion.jpg",
                    id: "weihnachtsminion.jpg",
                    mimetype: "image/jpeg", "tags": [
                        "type:image/jpeg", "image"
                    ],
                    project: "project",
                    image: true,
                    size: 715142,
                    info: {
                        width: 2815,
                        height: 3071,
                        ratio: 0.9166395310973624
                    },
                    history: {
                        version: 0,
                        created: "2022-06-21T10:37:06.838Z",
                        source: "setup"
                    }
                }
            ],
            all: 2,
            sites: {
                first: 1,
                current: 1,
                last: 1,
                range: [1, 2]
            },
            count: 2
        },
        status: 200,
    },
    '/company/homepage': {
        data: {
            "data": [
                {
                    "_id": "66c207c50a3ac0b05f1a4d9f",
                    "company": "lilaquadrat",
                    "filename": "bluecar.jpg",
                    "id": "bluecar.jpg",
                    "mimetype": "image/jpeg",
                    "tags": ["type:image/jpeg", "image"],
                    "versions": [
                        {
                            "width": 3797,
                            "height": 5292,
                            "ratio": 0.7174981103552532,
                            "size": 2441740,
                            "filename": "untouched_bluecar.jpg",
                            "name": "untouched"
                        },
                        {
                            "width": 179,
                            "height": 250,
                            "ratio": 0.716,
                            "size": 9729,
                            "filename": "250_bluecar.jpg",
                            "name": "thumbnail_250"
                        },
                        {
                            "width": 36,
                            "height": 50,
                            "ratio": 0.72,
                            "size": 1040,
                            "filename": "50_bluecar.jpg",
                            "name": "thumbnail_50"
                        }
                    ],
                    "project": "homepage",
                    "image": true,
                    "size": 2694414,
                    "info": {
                        "width": 3797,
                        "height": 5292,
                        "ratio": 0.7174981103552532
                    },
                    "history": {
                        "version": 0,
                        "created": "2024-08-18T14:40:05.499Z",
                        "source": "media"
                    }
                },
                {
                    "_id": "66be18ae0a3ac0b05f1a4d99",
                    "company": "lilaquadrat",
                    "filename": "kuba.jpg",
                    "id": "kuba.jpg",
                    "mimetype": "image/jpeg",
                    "tags": ["type:image/jpeg", "image"],
                    "versions": [
                        {
                            "width": 3000,
                            "height": 2000,
                            "ratio": 1.5,
                            "size": 343559,
                            "filename": "untouched_kuba.jpg",
                            "name": "untouched"
                        },
                        {
                            "width": 375,
                            "height": 250,
                            "ratio": 1.5,
                            "size": 15800,
                            "filename": "250_kuba.jpg",
                            "name": "thumbnail_250"
                        },
                        {
                            "width": 75,
                            "height": 50,
                            "ratio": 1.5,
                            "size": 1469,
                            "filename": "50_kuba.jpg",
                            "name": "thumbnail_50"
                        }
                    ],
                    "project": "homepage",
                    "image": true,
                    "size": 375136,
                    "info": {
                        "width": 3000,
                        "height": 2000,
                        "ratio": 1.5
                    },
                    "history": {
                        "version": 0,
                        "created": "2024-08-15T15:03:10.052Z",
                        "source": "media"
                    }
                },
            ]
        }
        , status: 200
    },
    '/company/homepage/bluecar.jpg/file': {
        data:
        {
            "_id": "66c207c50a3ac0b05f1a4d9f",
            "company": "company",
            "filename": "bluecar.jpg",
            "id": "bluecar.jpg",
            "mimetype": "image/jpeg",
            "tags": [
                "type:image/jpeg",
                "image"
            ],
            "versions": [
                {
                    "width": 3797,
                    "height": 5292,
                    "ratio": 0.7174981103552532,
                    "size": 2441740,
                    "filename": "untouched_bluecar.jpg",
                    "name": "untouched"
                },
                {
                    "width": 179,
                    "height": 250,
                    "ratio": 0.716,
                    "size": 9729,
                    "filename": "250_bluecar.jpg",
                    "name": "thumbnail_250"
                },
                {
                    "width": 36,
                    "height": 50,
                    "ratio": 0.72,
                    "size": 1040,
                    "filename": "50_bluecar.jpg",
                    "name": "thumbnail_50"
                }
            ],
            "project": "homepage",
            "image": true,
            "size": 2694414,
            "info": {
                "width": 3797,
                "height": 5292,
                "ratio": 0.7174981103552532
            },
            "history": {
                "version": 0,
                "created": "2024-08-18T14:40:05.499Z",
                "source": "media"
            },
            "baseContent": {}
        }
        , status: 200
    },
    '/company/homepage/1': { data: {}, status: 200 },
    '/company/homepage/stats': { data: { "files": 702, "used": 722252742 }, status: 200 },
    '/company/project/stats': {
        data:
        {
            files: 2,
            used: 2309782
        },
        status: 200,
    },
    '/company/homepage/kuba.jpg/file': {
        data: {
            "_id": "66be18ae0a3ac0b05f1a4d99",
            "company": "company",
            "filename": "kuba.jpg",
            "id": "kuba.jpg",
            "mimetype": "image/jpeg",
            "tags": [
                "type:image/jpeg",
                "image"
            ],
            "versions": [
                {
                    "width": 3000,
                    "height": 2000,
                    "ratio": 1.5,
                    "size": 343559,
                    "filename": "untouched_kuba.jpg",
                    "name": "untouched"
                },
                {
                    "width": 375,
                    "height": 250,
                    "ratio": 1.5,
                    "size": 15800,
                    "filename": "250_kuba.jpg",
                    "name": "thumbnail_250"
                },
                {
                    "width": 75,
                    "height": 50,
                    "ratio": 1.5,
                    "size": 1469,
                    "filename": "50_kuba.jpg",
                    "name": "thumbnail_50"
                }
            ],
            "project": "homepage",
            "image": true,
            "size": 375136,
            "info": {
                "width": 3000,
                "height": 2000,
                "ratio": 1.5
            },
            "history": {
                "version": 0,
                "created": "2024-08-15T15:03:10.052Z",
                "source": "media"
            },
            "baseContent": {}
        },
        status: 200
    },
    '/tracker/company/homepage': {
        data: {}, status: 200
    },
    '/tracker/company/homepage/list': {data:{
        "data": [
          {
            "_id": "64edda549395d030175d4be1",
            "name": "Test",
            "mode": "timeframe",
            "startTime": "2023-08-26T06:49:14.589Z",
            "endTime": "2023-08-29T11:45:24.542Z",
            "currentTime": 276969021,
            "category": "worktime",
            "company": "company",
            "project": "project",
            "user": "waad|cRqWgSlAjDLy9BtRqr5_ap9TnuUHFqQO1b8m7IahWPk"
          }
        ],
        "all": 6,
        "sites": {
          "first": 1,
          "current": 1,
          "last": 1,
          "range": [1, 6]
        },
        "count": 6
      }
      , status: 200},
      //one example
    '/tracker/company/homepage/64edda549395d030175d4be1': {
        data: {
        "_id": "64edda549395d030175d4be1",
        "name": "Test",
        "mode": "timeframe",
        "startTime": "2023-08-26T06:49:14.589Z",
        "endTime": "2023-08-29T11:45:24.542Z",
        "currentTime": 276969021,
        "category": "worktime",
        "company": "lilaquadrat",
        "project": "homepage",
        "user": {
          "id": "waad|cRqWgSlAjDLy9BtRqr5_ap9TnuUHFqQO1b8m7IahWPk",
          "prename": "Vorname",
          "lastname": "Nachname",
          "email": "v.nachname@company.de"
        },
        "history": {
          "version": 0,
          "created": "2023-08-29T11:45:24.750Z",
          "source": "time"
        }
      }
         , status: 200},

    //customers 
    '/customers/company/list': {
        data: {},
        status: 200
    },

    //editor
    '/editor/company/homepage/settings': {
        data: {
            "_id": "613726bd60bef370ec25db66",
            "settings": {
                "editorUrl": "",
                "title": {
                    "full": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.",
                    "short": "-Lorem ipsum dolor sit amet .1"
                },
                "description": {
                    "full": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore ",
                    "short": "Lorem ipsum dolor - sit amet."
                },
                "preloadImages": true,
                "breakpointTablet": "only screen and (min-width: 600px) and (max-width: 899px)",
                "breakpointDesktop": "only screen and (min-width: 900px)",
                "breakpointWide": "only screen and (min-width: 1200px)"
            },
            "project": "project",
            "company": "company",
            "app": "editor",
            "history": {
                "version": 16,
                "source": "editor",
                "updated": "2023-11-22T12:01:54.330Z"
            }
        },
        status: 200
    },
    '/editor/company/homepage/list/1': {
        data: {
            "data": [
                //   {
                //     "_id": "5ece503f1f44c53928876351",
                //     "settings": {
                //       "url": "urkl",
                //       "mode": "presentation",
                //       "filename": [
                //         "home",
                //         "startseite",
                //         "start",
                //         "index"
                //       ]
                //     },
                //     "company": "lilaquadrat",
                //     "project": "homepage",
                //     "state": "publish",
                //     "partial": false,
                //     "id": "home",
                //     "description": "",
                //     "history": {
                //       "version": 135,
                //       "source": "editor",
                //       "updated": "2023-11-14T15:31:03.877Z"
                //     },
                //     "tags": [
                //       "category:kategorie",
                //       "category:eins",
                //       "category:zwei"
                //     ]
                //   },
                //   {
                //     "_id": "5eeb38f90a3fb0c2e207caa5",
                //     "settings": {
                //       "title": "Lizenzen",
                //       "mode": "presentation",
                //       "filename": [
                //         "lizenz",
                //         "lizenzen",
                //         "license",
                //         "preise"
                //       ]
                //     },
                //     "company": "lilaquadrat",
                //     "project": "homepage",
                //     "state": "publish",
                //     "partial": false,
                //     "id": "lizenzen",
                //     "history": {
                //       "version": 18,
                //       "source": "editor",
                //       "updated": "2024-05-14T11:48:50.670Z"
                //     }
                //   },
                //   {
                //     "_id": "5eeb39fb0a3fb0ef8807cab1",
                //     "settings": {
                //       "title": "Kontakt",
                //       "useLayout": "6453adfde2db2fda43a08c2e",
                //       "mode": "presentation",
                //       "filename": [
                //         "contact",
                //         "lizenzkaufen",
                //         "kontakt"
                //       ]
                //     },
                //     "company": "lilaquadrat",
                //     "project": "homepage",
                //     "state": "publish",
                //     "partial": false,
                //     "id": "kontakt",
                //     "description": "",
                //     "history": {
                //       "version": 27,
                //       "source": "editor",
                //       "updated": "2023-08-17T13:46:55.175Z"
                //     }
                //   },
                //   {
                //     "_id": "5ef5a0f53a7883b1230af1ea",
                //     "settings": {
                //       "title": "Kunden",
                //       "filename": [
                //         "kunden",
                //         "clients",
                //         "references",
                //         "referenzen"
                //       ]
                //     },
                //     "tags": [],
                //     "company": "lilaquadrat",
                //     "project": "homepage",
                //     "state": "publish",
                //     "partial": false,
                //     "id": "kunden",
                //     "history": {
                //       "version": 8,
                //       "source": "editor",
                //       "updated": "2020-07-31T17:26:13.951Z"
                //     }
                //   },
                //   {
                //     "_id": "5ef5a26d3a7883591a0af1f6",
                //     "settings": {
                //       "mode": "presentation"
                //     },
                //     "company": "lilaquadrat",
                //     "project": "homepage",
                //     "state": "draft",
                //     "partial": true,
                //     "id": "top-menu",
                //     "description": "",
                //     "childData": {
                //       "index": [""]
                //     },
                //     "history": {
                //       "version": 30,
                //       "source": "editor",
                //       "updated": "2023-07-18T11:57:46.945Z"
                //     }
                //   },
                //   {
                //     "_id": "5ef738873a788317450af212",
                //     "settings": {
                //       "title": "Beispiele",
                //       "filename": [
                //         "beispiele"
                //       ]
                //     },
                //     "tags": [],
                //     "company": "lilaquadrat",
                //     "project": "homepage",
                //     "state": "publish",
                //     "partial": false,
                //     "id": "beispiele",
                //     "history": {
                //       "version": 5,
                //       "source": "editor",
                //       "updated": "2020-07-23T07:51:46.196Z"
                //     }
                //   },
                //   {
                //     "_id": "5ef73c7d3a788337a80af223",
                //     "settings": {
                //       "mode": "presentation"
                //     },
                //     "company": "lilaquadrat",
                //     "project": "homepage",
                //     "state": "draft",
                //     "partial": true,
                //     "id": "lizenzen-vergleich",
                //     "description": "",
                //     "history": {
                //       "version": 25,
                //       "source": "editor",
                //       "updated": "2023-05-05T14:25:04.171Z"
                //     }
                //   },
                //   {
                //     "_id": "5ef86786e33aa87439b22da8",
                //     "settings": {
                //       "title": "Transparent",
                //       "mode": "presentation",
                //       "filename": [
                //         "kontrolle",
                //         "control",
                //         "transparent",
                //         "transparenz"
                //       ]
                //     },
                //     "company": "lilaquadrat",
                //     "project": "homepage",
                //     "state": "publish",
                //     "partial": false,
                //     "id": "transparent",
                //     "description": "",
                //     "history": {
                //       "version": 39,
                //       "source": "editor",
                //       "updated": "2024-05-14T11:49:22.258Z"
                //     }
                //   },
                //   {
                //     "_id": "5ef86816e33aa8475fb22daf",
                //     "settings": {
                //       "title": "Einfach",
                //       "filename": [
                //         "performance",
                //         "einfach",
                //         "einfachheit",
                //         "simplicity"
                //       ],
                //       "mode": "presentation"
                //     },
                //     "company": "lilaquadrat",
                //     "project": "homepage",
                //     "state": "publish",
                //     "partial": false,
                //     "id": "einfach",
                //     "description": "",
                //     "childData": {
                //       "index": [""]
                //     },
                //     "history": {
                //       "version": 28,
                //       "source": "editor",
                //       "updated": "2021-11-16T15:40:42.899Z"
                //     }
                //   },
                //   {
                //     "_id": "5ef8687ee33aa85f7fb22db6",
                //     "settings": {
                //       "title": "Sicher",
                //       "filename": [
                //         "sicherheit",
                //         "safety",
                //         "sicher"
                //       ],
                //       "mode": "presentation"
                //     },
                //     "company": "lilaquadrat",
                //     "project": "homepage",
                //     "state": "publish",
                //     "partial": false,
                //     "id": "sicher",
                //     "description": "",
                //     "history": {
                //       "version": 20,
                //       "source": "editor",
                //       "updated": "2022-03-18T10:17:25.335Z"
                //     }
                //   },
                //   {
                //     "_id": "5ef868efe33aa85ec4b22dbf",
                //     "settings": {
                //       "title": "Nachhaltig",
                //       "filename": [
                //         "nachhaltigkeit",
                //         "sustainability",
                //         "nachhaltig"
                //       ],
                //       "mode": "presentation"
                //     },
                //     "company": "lilaquadrat",
                //     "project": "homepage",
                //     "state": "publish",
                //     "partial": false,
                //     "id": "nachhaltig",
                //     "description": "",
                //     "history": {
                //       "version": 21,
                //       "source": "editor",
                //       "updated": "2021-09-07T14:35:34.023Z"
                //     }
                //   },
                //   {
                //     "_id": "5ef86981e33aa843f5b22dc8",
                //     "settings": {
                //       "title": "Sichtbar",
                //       "filename": [
                //         "sichtbar",
                //         "veroeffentlichen",
                //         "publishen",
                //         "publish"
                //       ],
                //       "mode": "presentation"
                //     },
                //     "tags": [],
                //     "company": "lilaquadrat",
                //     "project": "homepage",
                //     "state": "publish",
                //     "partial": false,
                //     "id": "sichtbar",
                //     "description": "",
                //     "history": {
                //       "version": 19,
                //       "source": "editor",
                //       "updated": "2020-12-10T18:10:56.368Z"
                //     }
                //   },
                //   {
                //     "_id": "5ef8bd12e33aa8968ab22e18",
                //     "settings": {
                //       "mode": "presentation"
                //     },
                //     "tags": [],
                //     "company": "lilaquadrat",
                //     "project": "homepage",
                //     "state": "draft",
                //     "partial": true,
                //     "id": "kontakt-action",
                //     "description": "",
                //     "history": {
                //       "version": 7,
                //       "source": "editor",
                //       "updated": "2020-12-10T18:04:22.450Z"
                //     }
                //   },
                //   {
                //     "_id": "5efb32fccd952ebb6d954b60",
                //     "settings": {
                //       "title": "Key-features",
                //       "filename": [
                //         "key-features",
                //         "fakten",
                //         "facts"
                //       ],
                //       "mode": "presentation"
                //     },
                //     "tags": [],
                //     "company": "lilaquadrat",
                //     "project": "homepage",
                //     "state": "publish",
                //     "partial": false,
                //     "id": "key-features",
                //     "description": "",
                //     "history": {
                //       "version": 21,
                //       "source": "editor",
                //       "updated": "2020-12-10T18:07:10.649Z"
                //     }
                //   },
                //   {
                //     "_id": "5f04817cc0060a42899e96fc",
                //     "settings": {
                //       "mode": "presentation"
                //     },
                //     "tags": [],
                //     "company": "lilaquadrat",
                //     "project": "homepage",
                //     "state": "draft",
                //     "partial": true,
                //     "id": "lizenzen-uebersicht",
                //     "description": "",
                //     "childData": {
                //       "index": [""]
                //     },
                //     "history": {
                //       "version": 18,
                //       "source": "editor",
                //       "updated": "2021-09-02T10:13:48.425Z"
                //     }
                //   },
                //   {
                //     "_id": "5f1d6d4c4fa5bb6c3bce8c0a",
                //     "settings": {
                //       "title": "Impressum",
                //       "mode": "presentation",
                //       "filename": [
                //         "impressum"
                //       ]
                //     },
                //     "company": "lilaquadrat",
                //     "project": "homepage",
                //     "state": "publish",
                //     "partial": false,
                //     "id": "Impressum",
                //     "description": "",
                //     "history": {
                //       "version": 4,
                //       "source": "editor",
                //       "updated": "2024-04-17T07:01:31.893Z"
                //     }
                //   },
                //   {
                //     "_id": "5f1d6dc44fa5bb7431ce8c10",
                //     "settings": {
                //       "title": "Datenschutz",
                //       "filename": [
                //         "datenschutz"
                //       ],
                //       "mode": "presentation"
                //     },
                //     "company": "lilaquadrat",
                //     "project": "homepage",
                //     "state": "publish",
                //     "partial": false,
                //     "id": "datenschutz",
                //     "description": "",
                //     "history": {
                //       "version": 8,
                //       "source": "editor",
                //       "updated": "2021-09-07T14:26:42.996Z"
                //     }
                //   },
                //   {
                //     "_id": "5f2297794fa5bb17e4ce8cb1",
                //     "settings": {
                //       "filename": [
                //         "partnerseite"
                //       ],
                //       "mode": "presentation"
                //     },
                //     "tags": [],
                //     "company": "lilaquadrat",
                //     "project": "homepage",
                //     "state": "draft",
                //     "partial": false,
                //     "id": "partnerseite",
                //     "description": "",
                //     "history": {
                //       "version": 5,
                //       "source": "editor",
                //       "updated": "2021-01-15T09:08:49.378Z"
                //     }
                //   },
                //   {
                //     "_id": "5f244a6b4fa5bb40c6ce8cc1",
                //     "settings": {
                //       "mode": "presentation"
                //     },
                //     "company": "lilaquadrat",
                //     "project": "homepage",
                //     "state": "draft",
                //     "partial": true,
                //     "id": "footer",
                //     "description": "",
                //     "history": {
                //       "version": 13,
                //       "source": "editor",
                //       "updated": "2023-10-24T08:10:15.551Z"
                //     },
                //     "genericData": {
                //       "lists": [
                //         "652fdc75e80e8807396a5079"
                //       ]
                //     }
                //   },
                {
                    "_id": "5f3e5ebc43f9bef6e038d7e3",
                    "settings": {
                        "filename": [
                            "lernen"
                        ],
                        "mode": "presentation"
                    },
                    "tags": [],
                    "company": "company",
                    "project": "project",
                    "state": "publish",
                    "partial": false,
                    "id": "lernen",
                    "description": "",
                    "history": {
                        "version": 23,
                        "source": "editor",
                        "updated": "2021-01-05T13:32:20.255Z"
                    }
                }
            ],
            "all": 115,
            "sites": {
                "first": 1,
                "current": 1,
                "last": 6,
                "range": [
                    1,
                    20
                ]
            },
            "count": 20
        },
        status: 200
    },
    '/editor/company/homepage/lernen': {
        data: {
            "_id": "5f3e5ebc43f9bef6e038d7e3",
            "settings": {
                "filename": [
                    "lernen"
                ],
                "mode": "presentation"
            },
            "modules": [
                {
                    "headline": "DIGITALISIERUNG IST DOCH SO GESTERN.",
                    "subline": "",
                    "intro": "Was früher eine Woche dauerte, ist mit STUDIO in 20 Minuten erledigt. Unsere innovative Software ist die Antwort auf alle Fragen im Online-Bereich: Leicht zu bedienen, nachhaltig wirkungsvoll und mit höchsten Sicherheitsstandards.",
                    "text": [
                        "Homepage, Blog, Verwaltung der Social Media Kanäle, interne & externe Kommunikation, Datensicherung, Hosting, E-Mail-Adressen: Das alles – und noch viel mehr – kann STUDIO. Kann jeder!",
                        "STUDIO ist mehr als eine Software: Damit kann jeder schnell und einfach Veränderungen vornehmen – und zwar genau dann, wenn der Markt es erfordert, flexibel mit jedem Endgerät. Das macht Teams moderner und agiler als sie es je für möglich gehalten hätten."
                    ],
                    "links": [
                        {
                            "text": "",
                            "link": {
                                "link": ""
                            }
                        }
                    ],
                    "id": "Digitalisierung ist doch so gestern.",
                    "type": "text-module",
                    "changed": "true"
                },
                {
                    "id": "",
                    "variant": [
                        "height50"
                    ],
                    "type": "emotion-module",
                    "background": {
                        "src": "https://cdn3.lilaquadrat.de/lilaquadrat/homepage/7Y3A1158-20200620-final.jpg",
                        "alt": "",
                        "file": {
                            "id": "5f0f13d8e5b1c25cdbfc52a8",
                            "filename": "7Y3A1158-20200620-final.jpg",
                            "src": "https://cdn3.lilaquadrat.de/lilaquadrat/homepage/7Y3A1158-20200620-final.jpg",
                            "thumb": "https://cdn3.lilaquadrat.de/lilaquadrat/homepage/250_7Y3A1158-20200620-final.jpg"
                        },
                        "source": [
                            {
                                "media": "",
                                "src": "",
                                "file": {
                                    "id": "",
                                    "filename": "",
                                    "src": "",
                                    "thumb": ""
                                }
                            },
                            {
                                "media": "",
                                "src": "",
                                "file": {
                                    "id": "",
                                    "filename": "",
                                    "src": "",
                                    "thumb": ""
                                }
                            },
                            {
                                "media": "",
                                "src": "",
                                "file": {
                                    "id": "",
                                    "filename": "",
                                    "src": "",
                                    "thumb": ""
                                }
                            }
                        ],
                        "changed": "true"
                    },
                    "video": {
                        "src": "",
                        "alt": "",
                        "source1": {
                            "media": "",
                            "source": ""
                        },
                        "source2": {
                            "media": "",
                            "source": ""
                        },
                        "source3": {
                            "media": "",
                            "source": ""
                        },
                        "file": {
                            "id": "",
                            "filename": "",
                            "src": "",
                            "thumb": ""
                        },
                        "poster": ""
                    },
                    "textblock": {
                        "headline": "",
                        "subline": "",
                        "intro": "",
                        "links": [
                            {
                                "text": "",
                                "link": {
                                    "link": ""
                                }
                            }
                        ]
                    },
                    "picture": {
                        "src": "",
                        "alt": "",
                        "file": {
                            "id": "",
                            "filename": "",
                            "src": "",
                            "thumb": ""
                        },
                        "source": [
                            {
                                "media": "",
                                "src": "",
                                "file": {
                                    "id": "",
                                    "filename": "",
                                    "src": "",
                                    "thumb": ""
                                }
                            },
                            {
                                "media": "",
                                "src": "",
                                "file": {
                                    "id": "",
                                    "filename": "",
                                    "src": "",
                                    "thumb": ""
                                }
                            },
                            {
                                "media": "",
                                "src": "",
                                "file": {
                                    "id": "",
                                    "filename": "",
                                    "src": "",
                                    "thumb": ""
                                }
                            }
                        ]
                    },
                    "changed": "true"
                }
            ],
            "tags": [],
            "company": "lilaquadrat",
            "project": "homepage",
            "state": "publish",
            "partial": false,
            "id": "lernen",
            "description": "",
            "history": {
                "version": 23,
                "source": "editor",
                "updated": "2021-01-05T13:32:20.255Z"
            },
            "childData": {
                "data": {}
            },
            "genericData": {
                "data": []
            }
        }
        , status: 200
    },

    '/editor/company/project/settings': {
        data: {
            "_id": "613726bd60bef370ec25db66",
            "settings": {
                "editorUrl": "",
                "title": {
                    "full": "lilaquadrat STUDIO - Enterprise CMS. Willkommen in der neuen Online-Welt.",
                    "short": "- lilaquadrat STUDIO - Enterprise CMS.1"
                },
                "description": {
                    "full": "lilaquadrat STUDIO - Enterprise CMS. Willkommen in der neuen Online-Welt. Digitalisierung und Datenverwaltung sicher, transparent, einfach und nachhaltig.",
                    "short": "lilaquadrat STUDIO - Enterprise CMS."
                },
                "preloadImages": true,
                "breakpointTablet": "only screen and (min-width: 600px) and (max-width: 899px)",
                "breakpointDesktop": "only screen and (min-width: 900px)",
                "breakpointWide": "only screen and (min-width: 1200px)"
            },
            "project": "homepage",
            "company": "lilaquadrat",
            "app": "editor",
            "history": {
                "version": 16,
                "source": "editor",
                "updated": "2023-11-22T12:01:54.330Z"
            }
        }
        ,
        status: 200,
    },
    '/editor/company/project/list': {
        data: {
            data: [
                {
                    _id: "62b19f5289cda550b9e152bf",
                    id: "testedit",
                    state: "draft",
                    settings: {
                        mode: "presentation",
                    },
                    company: "company",
                    project: "project",
                    history: {
                        version: 1,
                        created: "2022-06-21T10:37:06.869Z",
                        source: "setup",
                        updated: "2022-06-22T08:12:41.931Z"
                    }
                }
            ],
            all: 1,
            sites: {
                first: 1,
                current: 1,
                last: 1,
                range: [1, 1]
            },
            count: 1,
        },
        status: 200,
    },
    '/editor/company/project/list/1': {
        data: {
            data: [
                {
                    _id: "62b19f5289cda550b9e152bf",
                    id: "testedit",
                    state: "draft",
                    settings: {
                        mode: "presentation",
                    },
                    company: "company",
                    project: "project",
                    history: {
                        version: 1,
                        created: "2022-06-21T10:37:06.869Z",
                        source: "setup",
                        updated: "2022-06-22T08:12:41.931Z"
                    }
                },
                {
                    _id: "64253bf83d7f819b95665b53",
                    id: "partial",
                    state: "draft",
                    partial: true,
                    settings: {
                        mode: "presentation",
                    },
                    company: "company",
                    project: "project",
                    history: {
                        version: 1,
                        created: "2022-06-21T10:37:06.869Z",
                        source: "setup",
                        updated: "2022-06-22T08:12:41.931Z"
                    }
                }
            ],
            all: 1,
            sites: {
                first: 1,
                current: 1,
                last: 1,
                range: [1, 1]
            },
            count: 1,
        },
        status: 200,
    },

    '/editor/company/project/internal/64253bf83d7f819b95665b53': {
        data: {
            "_id": "64253bf83d7f819b95665b53",
            "settings": {
                "mode": "presentation"
            },
            "tags": [
                "category:test"
            ],
            "id": "test",
            "state": "draft",
            "partial": true,
            "company": "company",
            "project": "project",
            "history": {
                "version": 1,
                "created": "2023-03-30T07:36:24.916Z",
                "source": "editor",
                "updated": "2023-03-30T07:38:35.006Z"
            },
            "modules": [
                {
                    "uuid": "298469f3-2443-4f27-a432-164481e4a384",
                    "id": "Menü",
                    "type": "menu-module",
                    "name": "Test",
                    "elements": [
                        {
                            "text": "Home",
                            "link": "/"
                        },
                        {
                            "uuid": "042bac30-37e6-45d5-98b7-f1d8dd174244",
                            "text": "Neu",
                            "link": "/",
                            "links": [
                                {
                                    "uuid": "11481a6f-a0bc-4fc5-a5da-23df76d1bc03"
                                }
                            ]
                        },
                        {
                            "uuid": "b43c1ab9-ed84-43fe-9251-6421bdcc396a",
                            "text": "Neu2",
                            "link": "/",
                            "links": [
                                {
                                    "uuid": "5cd69aea-f9e7-46cd-b1c3-a25778cd0950"
                                }
                            ]
                        }
                    ]
                }
            ],
            "childData": {
                "data": {}
            },
            "genericData": {
                "data": []
            }
        },
        status: 200,
    },

    '/editor/company/project/testedit': {
        data: {
            _id: "62b19f5289cda550b9e152bf",
            settings: {
                mode: "presentation"
            },
            id: "testedit",
            state: "draft",
            company: "company",
            project: "project",
            history: {
                version: 0,
                created: "2022-08-08T22:52:48.023Z",
                source: "editor"
            },
            modules: [{}],
            childData: {
                data: {}
            },
            genericData: {
                data: {}
            }
        },
        status: 200,
    },

    //hosting
    '/hosting/company/homepage': {
        data: {
            "_id": "6447eab60f53f42f5c046844",
            "company": "company",
            "project": "project",
            "port": 3,
            "active": true,
            "history": {
                "version": 2,
                "created": "2023-04-25T14:59:02.298Z",
                "source": "hosting-app",
                "updated": "2023-09-04T12:48:53.320Z"
            },
            "settings": {
                "ip": "40.91.220.209"
            }
        }
        , status: 200
    },
    '/hosting/admin/company/list/hosting/1': {
        data: {
            "data": [
                {
                    "_id": "637104516bacecab0b82f17f",
                    "company": "company",
                    "project": "project",
                    "port": 1,
                    "active": true,
                    "history": {
                        "version": 0,
                        "created": "2022-11-13T14:50:57.313Z",
                        "source": "hosting-app"
                    }
                },
                {
                    "_id": "637f8376eb1cf8a3b9e77c1a",
                    "company": "company",
                    "project": "project1",
                    "port": 2,
                    "active": true,
                    "history": {
                        "version": 0,
                        "created": "2022-11-24T14:45:10.561Z",
                        "source": "hosting-app"
                    }
                },
                {
                    "_id": "6447eab60f53f42f5c046844",
                    "company": "lilaquadrat",
                    "project": "homepage",
                    "port": 3,
                    "active": true,
                    "history": {
                        "version": 2,
                        "created": "2023-04-25T14:59:02.298Z",
                        "source": "hosting-app",
                        "updated": "2023-09-04T12:48:53.320Z"
                    }
                },
                {
                    "_id": "65d4a7d1e179836b13fc23c7",
                    "company": "crewadmin",
                    "project": "catest",
                    "port": 4,
                    "active": true,
                    "history": {
                        "version": 3,
                        "created": "2024-02-20T13:23:29.317Z",
                        "source": "hosting-app",
                        "updated": "2024-02-29T15:37:59.415Z"
                    },
                    "isApp": true
                },
            ],
            "all": 5,
            "sites": {
                "first": 1,
                "current": 1,
                "last": 1,
                "range": [1, 5]
            },
            "count": 5
        },
        status: 200
    },

    //hostings/cerificates
    '/certificatesActions/admin/company/list/1': {
        data: {
            "data": [
                {
                    "_id": "63778a0e26a990cd7716564d",
                    "domains": [
                        "sun2020.de"
                    ],
                    "type": "wildcard",
                    "renew": true,
                    "state": "success",
                    "history": {
                        "version": 2,
                        "created": "2022-11-18T13:35:10.740Z",
                        "source": "postman",
                        "updated": "2022-11-18T13:35:44.309Z"
                    }
                },
                {
                    "_id": "63778a30e2820edb07e51e49",
                    "planned": "2023-01-28T12:35:42.000Z",
                    "type": "wildcard",
                    "domains": [
                        "sun2020.de"
                    ],
                    "state": "success",
                    "renew": true,
                    "history": {
                        "version": 2,
                        "created": "2022-11-18T13:35:44.242Z",
                        "source": "publish",
                        "updated": "2023-01-28T12:41:26.160Z"
                    }
                },
                {
                    "_id": "637a57020cd3a84b9e1358ed",
                    "domains": [
                        "koeniglila.de"
                    ],
                    "type": "domain",
                    "renew": true,
                    "state": "failed",
                    "company": "company",
                    "project": "project",
                    "history": {
                        "version": 12,
                        "created": "2022-11-20T16:34:10.626Z",
                        "source": "hosting-app",
                        "updated": "2022-11-20T17:05:13.886Z"
                    }
                },
                {
                    "_id": "637ba4350cd3a84b9e1358f9",
                    "domains": [
                        "koeniglila.de"
                    ],
                    "type": "domain",
                    "renew": true,
                    "state": "success",
                    "company": "company",
                    "project": "project",
                    "history": {
                        "version": 8,
                        "created": "2022-11-21T16:15:49.991Z",
                        "source": "postman",
                        "updated": "2022-11-21T16:34:18.801Z"
                    }
                },
                {
                    "_id": "637f8376eb1cf8a3b9e77c1f",
                    "domains": [
                        "company-project1.sun2020.de"
                    ],
                    "type": "domain",
                    "renew": true,
                    "state": "failed",
                    "company": "company",
                    "project": "project1",
                    "history": {
                        "version": 12,
                        "created": "2022-11-24T14:45:10.707Z",
                        "source": "hosting-app",
                        "updated": "2022-11-24T15:15:36.753Z"
                    }
                },
                {
                    "_id": "63d517f68d0c979ab41b59b0",
                    "planned": "2023-04-09T11:41:24.000Z",
                    "type": "wildcard",
                    "domains": [
                        "sun2020.de"
                    ],
                    "state": "success",
                    "renew": true,
                    "history": {
                        "version": 14,
                        "created": "2023-01-28T12:41:26.111Z",
                        "source": "publish",
                        "updated": "2023-10-18T12:55:22.315Z"
                    }
                },
                {
                    "_id": "6447eab60f53f42f5c046846",
                    "domains": [
                        "lilaquadrat-homepage.sun2020.de"
                    ],
                    "type": "domain",
                    "renew": true,
                    "state": "failed",
                    "company": "lilaquadrat",
                    "project": "homepage",
                    "history": {
                        "version": 12,
                        "created": "2023-04-25T14:59:02.356Z",
                        "source": "hosting-app",
                        "updated": "2023-04-25T15:30:54.838Z"
                    }
                },
                {
                    "_id": "644a7d5697197ee06e407bdc",
                    "type": "wildcard",
                    "planned": "2023-04-09T11:41:24.000Z",
                    "domains": [
                        "sun2020.de"
                    ],
                    "state": "failed",
                    "renew": true,
                    "history": {
                        "version": 16,
                        "created": "2023-01-28T12:41:26.111Z",
                        "source": "publish",
                        "updated": "2023-05-02T13:59:38.574Z"
                    }
                },
                {
                    "_id": "64f5d2359395d030175d4bf7",
                    "domains": [
                        "lilaquadrat-homepage.sun2020.de"
                    ],
                    "type": "domain",
                    "renew": true,
                    "state": "failed",
                    "company": "lilaquadrat",
                    "project": "homepage",
                    "history": {
                        "version": 11,
                        "created": "2023-09-04T12:48:53.615Z",
                        "source": "hosting-app",
                        "updated": "2023-09-04T13:22:52.272Z"
                    }
                },
                {
                    "_id": "652fc8d2e80e8807396a5066",
                    "domains": [
                        "lilaquadrat-homepage.sun2020.de"
                    ],
                    "type": "domain",
                    "renew": true,
                    "state": "failed",
                    "company": "lilaquadrat",
                    "project": "homepage",
                    "history": {
                        "version": 12,
                        "created": "2023-10-18T12:00:18.207Z",
                        "source": "postman",
                        "updated": "2023-10-18T12:57:42.339Z"
                    }
                },
                {
                    "_id": "652fd5ba9e8370900759af62",
                    "planned": "2023-08-28T11:55:21.000Z",
                    "type": "wildcard",
                    "domains": [
                        "sun2020.de"
                    ],
                    "state": "success",
                    "renew": true,
                    "history": {
                        "version": 2,
                        "created": "2023-10-18T12:55:22.248Z",
                        "source": "publish",
                        "updated": "2023-10-18T13:10:43.929Z"
                    }
                },
                {
                    "_id": "652fd9539e8370900759af6b",
                    "planned": "2023-10-20T12:10:42.000Z",
                    "type": "wildcard",
                    "domains": [
                        "sun2020.de"
                    ],
                    "state": "failed",
                    "renew": true,
                    "history": {
                        "version": 20,
                        "created": "2023-10-18T13:10:43.886Z",
                        "source": "publish",
                        "updated": "2023-11-27T12:57:37.441Z"
                    }
                },
                {
                    "_id": "65466643d39f01024143e71d",
                    "domains": [
                        "koeniglila.de"
                    ],
                    "type": "domain",
                    "renew": true,
                    "state": "canceled",
                    "company": "company",
                    "project": "project",
                    "history": {
                        "version": 1,
                        "created": "2023-11-04T15:41:55.111Z",
                        "source": "hostingAdmin-app",
                        "updated": "2023-11-05T14:19:21.296Z"
                    }
                },
                {
                    "_id": "6547973c04153cc176b32bdb",
                    "domains": [
                        "koeniglila.de"
                    ],
                    "type": "domain",
                    "renew": true,
                    "state": "canceled",
                    "company": "company",
                    "project": "project",
                    "history": {
                        "version": 1,
                        "created": "2023-11-05T13:23:08.215Z",
                        "source": "hostingAdmin-app",
                        "updated": "2024-03-01T06:37:38.844Z"
                    }
                },
                {
                    "_id": "654797df04153cc176b32bdd",
                    "domains": [
                        "koeniglila.de"
                    ],
                    "type": "domain",
                    "renew": true,
                    "state": "canceled",
                    "company": "company",
                    "project": "project",
                    "history": {
                        "version": 1,
                        "created": "2023-11-05T13:25:51.185Z",
                        "source": "hostingAdmin-app",
                        "updated": "2024-03-01T06:37:31.315Z"
                    }
                },
                {
                    "_id": "65d4a7d1e179836b13fc23c9",
                    "domains": [
                        "crewadmin-catest.sun2020.de"
                    ],
                    "type": "domain",
                    "renew": true,
                    "state": "failed",
                    "company": "crewadmin",
                    "project": "catest",
                    "history": {
                        "version": 12,
                        "created": "2024-02-20T13:23:29.432Z",
                        "source": "hosting-app",
                        "updated": "2024-02-20T13:55:31.726Z"
                    }
                },
                {
                    "_id": "65e1778bc45e92d0bff49524",
                    "domains": [
                        "sun2020.de"
                    ],
                    "type": "wildcard",
                    "renew": true,
                    "state": "success",
                    "history": {
                        "version": 2,
                        "created": "2024-03-01T06:36:59.267Z",
                        "source": "hostingadmin-app",
                        "updated": "2024-03-01T06:40:01.165Z"
                    }
                },
                {
                    "_id": "65e17841b61b779a43e91a22",
                    "planned": "2024-05-11T05:40:00.000Z",
                    "type": "wildcard",
                    "domains": [
                        "sun2020.de"
                    ],
                    "state": "success",
                    "renew": true,
                    "history": {
                        "version": 2,
                        "created": "2024-03-01T06:40:01.055Z",
                        "source": "publish",
                        "updated": "2024-06-28T09:53:15.467Z"
                    }
                },
                {
                    "_id": "667e880b746d69471c3afd5f",
                    "planned": "2024-09-07T08:53:14.000Z",
                    "type": "wildcard",
                    "domains": [
                        "sun2020.de"
                    ],
                    "state": "new",
                    "renew": true,
                    "history": {
                        "version": 0,
                        "created": "2024-06-28T09:53:15.403Z",
                        "source": "publish"
                    }
                },
                {
                    "_id": "66b61ec094ad0154fbc8f6ef",
                    "domains": [
                        "puschkin-homepage2.sun2020.de"
                    ],
                    "type": "domain",
                    "renew": true,
                    "state": "failed",
                    "company": "puschkin",
                    "project": "homepage2",
                    "history": {
                        "version": 12,
                        "created": "2024-08-09T13:50:56.509Z",
                        "source": "hosting-app",
                        "updated": "2024-08-09T14:30:22.309Z"
                    }
                }
            ],
            "all": 20,
            "sites": {
                "first": 1,
                "current": 1,
                "last": 1,
                "range": [
                    1,
                    20
                ]
            },
            "count": 20
        },
        status: 200
    },

    //hosting/domains
    '/domains/company/homepage/list': {
        data: {
            "data": [
                {
                    "_id": "6447eab60f53f42f5c046842",
                    "company": "company",
                    "project": "project",
                    "domain": "company-project.sun2020.de",
                    "isDefault": true,
                    "active": true,
                    "validated": true,
                    "isMain": false
                }
            ],
            "all": 1,
            "sites": {
                "first": 1,
                "current": 1,
                "last": 1,
                "range": [
                    1,
                    1
                ]
            },
            "count": 1
        },
        status: 200
    },


    //lists
    '/lists/company/homepage/list': { data: {}, status: 200 },
    '/lists/company/project/645124e5acdf5e8b4041de8d': {
        data: {
            "_id": "645124e5acdf5e8b4041de8d",
            "name": "chime",
            "description": "Liste zum Test",
            "payment": "free",
            "state": "open",
            "mode": "reservation",
            "participants": {
                "max": 10,
                "confirmed": 6,
                "available": true
            },
            "categories": [
                {
                    "id": "standard",
                    "name": "Standard",
                    "price": {
                        "tax": 19
                    }
                }
            ],
            "agreements": [
                {
                    "contentId": "contact-dsgvo",
                    "predefined": true,
                    "text": "Ich stimme zu, dass meine persönlichen Daten gemäß der Datenschutz-Grundverordnung (DSGVO) verarbeitet werden dürfen. Ich habe die Datenschutzrichtlinie gelesen und verstanden.",
                    "required": true
                }
            ],
            "company": "lilaquadrat",
            "project": "homepage",
            "history": {
                "version": 0,
                "created": "2023-10-18T13:24:05.759Z",
                "source": "lists-app"
            }
        },
        status: 200,
    },


    '/lists/company/project/list/1': {
        data: {
            data: [
                {
                    "_id": "64cbb4d49f1177c7ce6bdf9e",
                    "name": "Mit DSGVO",
                    "payment": "free",
                    "state": "waiting",
                    "mode": "contact",
                    "agreements": [
                        {
                            "text": "beschreibung",
                            "contentId": "contact-dsgvo",
                            "required": true,
                            "predefined": true
                        },
                        {
                            "text": "sadasd",
                            "contentId": "layout-test"
                        }
                    ],
                    "company": "company",
                    "project": "project",
                    "participants": {
                        "confirmed": 0,
                        "available": true
                    }
                },
                {
                    "_id": "6464c12d074ca92142352b64",
                    "name": "Neue Liste Test",
                    "description": "gute beschreibung1212",
                    "payment": "optional",
                    "state": "open",
                    "mode": "contact",
                    "company": "company",
                    "project": "project",
                    "participants": {
                        "max": 15,
                        "confirmed": 5,
                        "available": true
                    },
                    "agreements": [
                        {
                            "contentId": "contact-dsgvo",
                            "predefined": true,
                            "text": "Ich stimme zu, dass meine persönlichen Daten gemäß der Datenschutz-Grundverordnung (DSGVO) verarbeitet werden dürfen. Ich habe die Datenschutzrichtlinie gelesen und verstanden.",
                            "required": true
                        },
                        {
                            "text": "adfasdf",
                            "contentId": "layout-test",
                            "required": true
                        },
                        {
                            "text": "sdrtfgrfgh",
                            "contentId": "pdftest"
                        }
                    ]
                },
                {
                    "_id": "645124e5acdf5e8b4041de8d",
                    "name": "chime",
                    "payment": "free",
                    "mode": "content",
                    "state": "open",
                    "participants": {
                        "max": 3,
                        "confirmed": 2,
                        "available": true
                    },
                    "company": "company",
                    "project": "project"
                },
                {
                    "_id": "64512528acdf5e8b4041de8f",
                    "name": "chime22",
                    "payment": "free",
                    "mode": "content",
                    "state": "open",
                    "company": "company",
                    "project": "project",
                    "participants": {
                        "confirmed": 0,
                        "available": true
                    }
                },
                {
                    "_id": "643d629f88ae5ac9d601d89a",
                    "name": "first list",
                    "payment": "required",
                    "mode": "content",
                    "state": "open",
                    "company": "company",
                    "project": "project",
                    "participants": {
                        "max": 8,
                        "unique": true,
                        "confirmed": 3,
                        "available": true
                    },
                    "description": "some description",
                    "agreements": [
                        {
                            "contentId": "contact-dsgvo",
                            "predefined": true,
                            "text": "Ich stimme zu, dass meine persönlichen Daten gemäß der Datenschutz-Grundverordnung (DSGVO) verarbeitet werden dürfen. Ich habe die Datenschutzrichtlinie gelesen und verstanden.",
                            "required": true
                        },
                        {
                            "text": "dsfdsf",
                            "contentId": "training"
                        }
                    ]
                }
            ],
            all: 5,
            sites: {
                first: 1,
                current: 1,
                last: 1,
                range: [
                    1,
                    5
                ]
            },
            count: 5
        },
        status: 202
    },

    //health
    '/health': {
        data: {},
        status: 202,
    },



    //projejcts
    '/projects/company/project': {
        data: {
            _id: "62b19f5289cda550b9e152ca",
            name: "homepage",
            id: "homepage",
            company: "company",
            apps: [
                "editor",
                "media",
                "history",
                "publish"
            ],
            history: {
                version: 0,
                created: "2022-06-21T10:37:06.958Z",
                source: "setup",
            }
        },
        status: 200,
    },
    '/projects/company/homepage': {
        data: {
            "_id": "5ece4eb31f44c57c81876349",
            "tags": [],
            "name": "Homepage",
            "company": [
                {
                    "name": "lilaquadrat",
                    "id": "lilaquadrat"
                }
            ],
            "apps": [
                "media",
                "publish",
                "history",
                "hosting",
                "learn",
                "tracker",
                "editor",
                "lists",
                "customers"
            ],
            "id": "homepage",
            "history": {
                "version": 21,
                "source": "system",
                "updated": "2023-11-16T12:43:14.415Z"
            }
        },
        status: 200
    },

    //me
    '/me/permissions': {
        data: [
            {
                "_id": "company",
                "projects": [
                    {
                        "company": "company",
                        "project": "homepage",
                        "scope": [
                            "editor:read",
                            "editor:create",
                            "editor:edit",
                            "editor:remove",
                            "media:read",
                            "media:create",
                            "media:edit",
                            "media:remove",
                            "publish:publish",
                            "publish:secret",
                            "publish:refreshSecret",
                            "publishMethods:read",
                            "publishMethods:create",
                            "publishMethods:edit",
                            "publishMethods:remove",
                            "history:read",
                            "hosting:read",
                            "hosting:edit",
                            "hosting:toggle",
                            "hosting:history",
                            "domains:read",
                            "domains:create",
                            "domains:edit",
                            "domains:remove",
                            "domains:history",
                            "tracker:list",
                            "tracker:read",
                            "tracker:create",
                            "tracker:edit",
                            "tracker:remove",
                            "tracker:history",
                            "tracker:admin",
                            "customers:list",
                            "customers:read",
                            "customers:create",
                            "customers:edit",
                            "customers:remove",
                            "customers:history",
                            "customers:admin",
                            "permissions:read",
                            "permissions:create",
                            "permissions:edit",
                            "permissions:remove",
                            "permissions:history",
                            "settings:read",
                            "settings:edit",
                            "settings:history",
                            "app:read",
                            "app:toggle",
                            "app:history",
                            "html:app"
                        ],
                        "name": "Homepage",
                        "id": "homepage"
                    }
                ],
                "name": "company",
                "scope": [
                    "project:read",
                    "project:create",
                    "project:edit",
                    "project:remove",
                    "project:copy",
                    "app:read",
                    "app:create",
                    "app:edit",
                    "app:remove",
                    "app:toggle",
                    "app:admin",
                    "settings:read",
                    "settings:edit",
                    "permissions:read",
                    "permissions:create",
                    "permissions:edit",
                    "permissions:remove",
                    "company:list",
                    "company:create",
                    "company:admin",
                    "partner:read",
                    "partner:create",
                    "partner:edit",
                    "partner:files",
                    "history:read",
                    "hosting:admin"
                ]
            }
        ]
        ,
        status: 200
    },

    //publish
    '/publish/methods/company/project/active/editor': {
        data: [
            {
                "_id": "641982c0a2aa90d29ed52d91",
                "type": "internal",
                "label": "",
                "availableForApps": ["editor"],
                "affectedStates": ["publish"],
                "availableForContentGroups": ["single", "category"]
            },
            {
                "_id": "6230a813c84d25bc7dbc9955",
                "type": "ftp",
                "label": "ftp",
                "availableForApps": ["editor"],
                "availableForContentGroups": ["single", "all", "category"]
            },
            {
                "_id": "6228c9d968c38568a2760c28",
                "type": "webhook",
                "label": "webhook3",
                "availableForApps": ["editor"],
                "availableForContentGroups": ["all", "single", "category"]
            }

        ],
        status: 200,
    },
    '/publish/company/project/affected': {
        data: { "affected": 76 },
        status: 200,
    },

    

};

export default mockJs;
