/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Call } from '@libs/lila-call';
import { Auth } from '@libs/lila-auth';
import MainStore from '@store/main.store';
import log from 'loglevel';
import { Editor, ListOfModels, History } from '@lilaquadrat/studio/lib/interfaces';
import GenericStore from '@interfaces/GenericStore.interface';

const AppEditorDataStore: GenericStore<any> = {
  namespaced: true,
  strict: true,

  state: {
    settings: {},
    crumbs: [],
    cache: null,
    content: {},
    editor: {},
    active: false,
    type: null,
    state: null,
    publishState: null,
    lastSaved: null,
    history: {},
    historySingle: null,
    layout: null,
    viewport: null,
    showHistoryCurrent: false,
    childData: {
      index: [],
      data: {},
    },
    childDataCache: {},
    base: {
      app: null,
      server: null,
    },
  },

  mutations: {

    add(state, data: { title: string, component: any }) {

      state.crumbs.push(data);

    },

    setCache(state, cacheString: string) {

      state.cache = cacheString;

    },

    remove(state, index: number) {

      const element = state.crumbs[index + 1];

      if (element) {

        element.unset();
        delete element.unset;

      }

      state.crumbs = state.crumbs.slice(0, index + 1);

    },

    clearCrumbs(state) {

      state.crumbs[0]?.unset();
      state.crumbs = [];

    },

    content(state, data) {

      state.content = data;

    },

    editor(state, data) {

      state.editor = data;

    },

    active(state, data) {


      state.active = data;

    },

    type(state, type: string) {

      state.type = type;

    },

    state(state, status: string) {

      state.state = status;

    },

    lastSaved(state, date: Date) {

      state.lastSaved = date;

    },

    viewport(state, viewport) {

      state.viewport = viewport;

    },

    publishState(state, data) {

      state.publishState = data;

    },

  },

  actions: {

    getContent(store, params: any) {

      const call = new Call<any>('gfm', MainStore.getters.endpoints);
      const queryArray = ['universal', params.company, params.project, 'gfm', 'plants', params.id];

      return call.get('api', `/${queryArray.join('/')}`, undefined, Auth.getAuthHeader())
        .then((data) => {

          store.commit('content', data.r);
          return data.r;

        })
        .catch((e) => {

          log.error(e);

        });

    },


    getEditorBase(store, params: any) {

      const call = new Call<Editor|History>('gfm', MainStore.getters.endpoints);
      const queryArray = [params.type === 'history' ? 'history' : 'editor', params.company, params.project, 'internal', params.id];

      return call.get('api', `/${queryArray.join('/')}`, undefined, Auth.getAuthHeader())
        .then((data) => data.r)
        .catch((e) => {

          log.error(e);

        });

    },

    toggleModule(store, params: {position: string, index: string | boolean}) {

      this.commit('AppGfm/active', params);
      this.commit('AppGfm/clearCrumbs');

    },

    create(store, data) {

      const call = new Call<void>('gfm', MainStore.getters.endpoints);

      return call.post('api', `/universal/${data.company}/${data.project}/gfm/plants`, { ...data.content }, Auth.getAuthHeader());

    },

    update(store, data) {

      const call = new Call<void>('gfm', MainStore.getters.endpoints);

      return call.put('api', `/universal/${data.company}/${data.project}/gfm/plants/${data.id}`, { ...data.content }, Auth.getAuthHeader());

    },

    delete(store, data) {

      const call = new Call<void>('gfm', MainStore.getters.endpoints);

      return call.delete('api', `/universal/${data.company}/${data.project}/gfm/plants/${data.id}`, Auth.getAuthHeader());

    },

  },

};

export default AppEditorDataStore;
