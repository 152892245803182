import LoadElements from '@mixins/loadElements';
import InstallableApp from '@interfaces/InstallableApp.interface';
import routes from './routes';
import translations from './translations/translations';

import './models/app.model';

LoadElements.extensions(require.context('./extensions', true, /[a-z-]\w+\.extension\.(ts|vue)$/, 'lazy'), 'appstore');

const app: InstallableApp = {
  name: 'appstore',
  publisher: 'lilaquadrat',
  translations,
  routes,
};

export default app;
