// import ModuleModel from '@models/Module.model';
// import modules from '@apps/editor/editor-modules';
// import AccordionModel from '@models/Accordion.model';
// import TextblockModel from '@models/Textblock.model';

// export default class FaqModuleModel extends ModuleModel {

//   type: keyof typeof modules = 'faq-module';

//   uuid = true;

//   textblock = TextblockModel;

//   accordion = AccordionModel;

// }

import Models, { ModelDeclarationExtended, ModelFunctions, ModelOptions } from '@libs/Models.class';
import Accordion from '@models/Accordion.model';
import Module from '@models/Module.model';
import Textblock from '@models/Textblock.model';

export default interface FaqModule extends Module {
  textblock: Textblock
  accordion: Accordion
}

const declaration: ModelDeclarationExtended<FaqModule, Module> = {
  textblock: { model: 'textblock' },
  accordion: { model: 'accordion' },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('faq-module', declaration, functions, options);