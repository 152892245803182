// import PartialModel from '@libs/lila-model';

// export default class CustomerCompanyModel extends PartialModel {

//   type = 'company';

//   uuid = false;

//   email: string = '';

//   streetNumber: string = '';

//   zipcode: string = '';

//   city: string = '';

//   country: string = '';

//   phone: string = '';

//   note: string = '';

//   name: string = '';

//   vat?: string = '';

//   register?: string = '';

//   legalRepresentative?: string = '';

//   internet?: string = '';

//   tags: string[] = [];

// }

import Models, { ModelDeclaration } from '@libs/Models.class';
import { Address } from '@lilaquadrat/studio/interfaces';

type AppCustomersCompany = {
  email: string
  phone: string
  note: string
  name: string
  vat: string
  register: string
  legalRepresentative: string
  internet: string
  tags: string[]
  type: string
} & Address;


const declaration: ModelDeclaration<AppCustomersCompany> = {
  email: { type: 'string' },
  osm_id: { type: 'number' },
  street: { type: 'string' },
  streetNumber: { type: 'string' },
  zipcode: { type: 'string' },
  country: { type: 'string' },
  addressAddition: { type: 'string' },
  city: { type: 'string' },
  phone: { type: 'string' },
  note: { type: 'string' },
  name: { type: 'string' },
  vat: { type: 'string' },
  register: { type: 'string' },
  legalRepresentative: { type: 'string' },
  internet: { type: 'string' },
  tags: { type: 'array', contains: { type: 'string' } },
  type: { type: 'string' },
};

Models.register('app-customers-company', declaration);

export default AppCustomersCompany;