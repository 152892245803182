import { UnifiedRouteGuard } from '@libs/lila-auth';

const loadScreen = (screen: string) => () => import(
  /* webpackChunkName: "app-media" */
  `./screens/${screen}`
).then((m) => m.default);

export default [
  {
    path: '/:company/:project/a/media/:site?',
    name: 'app-media-project-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home.screen.vue'),
    meta: {
      groups: ['media'],
      app: 'media',
      parent: true,
      rememberSettings: true,
    },
    children: [
      {
        path: 'e/:filename/edit',
        name: 'app-media-project-edit',
        components: {
          children: loadScreen('edit.screen.vue'),
        },
        meta: {
          groups: ['media', 'edit'],
          app: 'media',
        },
      },
      {
        path: 'upload',
        name: 'app-media-project-upload',
        components: {
          modal: loadScreen('upload.screen.vue'),
        },
        meta: {
          sidescreen: true,
          defaultRoute: 'app-media-project-upload',
          groups: ['media'],
          app: 'media',
        },
      },
    ],
  },
];
