import { UnifiedRouteGuard } from '@libs/lila-auth';

const loadScreen = (screen: string) => () => import(
  /* webpackChunkName: "app-publish" */
  `./screens/${screen}`
).then((m) => m.default);

export default [
  {
    path: '/:company/:project/a/publish/:site?',
    name: 'app-publish-project-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home.screen.vue'),
    meta: {
      animationGroup: 'publish',
      app: 'publish',
      parent: true,
    },
    children: [
      {
        path: 'methods',
        component: loadScreen('methods-overview.screen.vue'),
        meta: {
          sidescreen: true,
          defaultRoute: 'app-publish-methods-home',
          app: 'publish',
        },
        children: [
          {
            path: 'add',
            component: loadScreen('methods-single.screen.vue'),
            name: 'app-publish-methods-add',
            meta: {
              sidescreen: true,
              defaultRoute: 'app-publish-methods-home',
              app: 'publish',
            },
          },
          {
            path: ':id',
            component: loadScreen('methods-single.screen.vue'),
            name: 'app-publish-methods-edit',
            meta: {
              sidescreen: true,
              defaultRoute: 'app-publish-methods-home',
              app: 'publish',
            },
          },
          {
            path: ':site?',
            component: loadScreen('methods-home.screen.vue'),
            name: 'app-publish-methods-home',
            meta: {
              sidescreen: true,
              defaultRoute: 'app-publish-methods-home',
              app: 'publish',
            },
          },
        ],
      },
      {
        path: 'single/:id',
        component: loadScreen('single.screen.vue'),
        name: 'app-publish-project-single',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-publish-project-single',
          app: 'publish',
        },
      },
    ],
  },
];
