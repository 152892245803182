// import PartialModel from '@libs/lila-model';
// import PriceModel from './price.model';

// export default class CategoriesModel extends PartialModel {

//   uuid = false;

//   name = '';

//   available = true;

//   amount = 0;

//   price = PriceModel;

// }


// export interface List extends BasicDataInterface {
//   _id?: ObjectId;

//   company: string
//   project: string

//   name: string
//   description?: string
//   mode: 'contact' | 'content' | 'reservation'

//   start?: Date
//   end?: Date

//   payment: 'optional' | 'required' | 'free'

//   participants?: {
//     max?: number
//     confirmed?: number
//     reserved?: number
//     available?: number
//     unique?: boolean
//   }

//   state: 'open' | 'closed' | 'waiting'

//   categories: ListCategory[]
// }

import Models, { ModelDeclaration, } from '@libs/Models.class';
import { ListCategory } from '@lilaquadrat/studio/lib/interfaces';


const declaration: ModelDeclaration<ListCategory> = {
  id: { type: 'string' },
  name: { type: 'string' },
  description: { type: 'string' },
  amount: { type: 'number' },
  disabled: { type: 'boolean' },
  price: { model: 'app-lists-price' },
};

Models.register('app-lists-categories', declaration);
