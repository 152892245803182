/* eslint-disable max-len */
export default {
  'app-editor': 'Editor',
  'data-model': 'Editor Datensatz',
  'editor-model': 'Editor Datensatz',
  'app-editor-project-settings-overview': 'Einstellungen',
  'app-editor-project-settings-main': 'Allgemeine Einstellungen',
  'app-editor-project-settings-base': 'Renderer',
  'app-editor-project-settings': 'Editor Einstellungen',
  'app-editor-created': 'Erstellt',
  'app-editor-no-children-found': 'Keine passenden Einträge',
  'app-editor-project-history': 'Historie anzeigen',
  'app-editor-filename': 'Dateinamen',
  'app-editor-files-after-publish': 'Vorschau der Dateinamen und Ordner nach Publish:',
  'app-editor-language': 'Sprache',
  'app-editor-id': 'ID',
  'app-editor-layout-description': 'Dieser Datensatz ist die Grundlage für andere Datensätze und beinhaltet allgemeine Module wie z.B. Menü oder Footer.',
  'app-editor-partial-description': 'Dieser Datensatz ist ein Partial und wird in einem größeren Kontext genutzt.',
  'app-editor-drag-delete': 'Entfernen',
  'app-editor-drag-clone': 'Kopieren',
  'app-editor-accordion-openOnStart': 'Automatisch offene Elemente',
  'app-editor-accordion-openOnStart-none': 'Alle geschlossen',
  'app-editor-accordion-openOnStart-first': 'Nur das Erste',
  'app-editor-accordion-openOnStart-all': 'Alle offen',
  'app-editor-accordion-disableControls': 'Interaktion deaktivieren',
  'app-editor-accordion-disableControls-description': 'Die Steuerung der Elemente ist deaktiviert. Sie können nicht mehr geöffnet oder geschlossen werden.',
  'app-editor-accordion-multiOpen': 'Gleichzeitige Anzeige mehrerer Elemente',
  'app-editor-accordion-multiOpen-description': 'Mehrere Elemente können simultan geöffnet bleiben.',
  'app-editor-training-search': 'Suche nach Datensätzen',
  'if a language is defined, it will result in a subfolder. subfolders can be also added directly to the filename e.g. subfolder/filename': 'Wenn eine Sprache definiert ist, wird ein Unterordner angelegt. Unterordner konnen direkt uber den Dateinamen hinzugefugt werden z.B. unterordner/dateiname',
  'the .html ending is omitted from the filename and will be added automatically in the publish process': 'Die .html Endung kann/muss nicht angefugt werden, diese wird im Publish Prozess automatisch hinzugefugt.',
  'app-editor-app-renderer': 'App Renderer',
  'app-editor-server-renderer': 'Server Renderer',
  'app-editor-last-update': 'Letztes Update',
  'app-editor-no-update-yet': 'Bisher wurde kein Update durchgeführt.',
  'app-editor-check-for-update': 'Auf Updates prüfen',
  'app-editor-update-renderer': 'Renderer aktualisieren',
  'app-editor-no-update-available': 'Kein Update verfügbar',
  'app-editor-custom-renderer-used': 'Standard - Renderer wird NICHT genutzt',
  'app-editor-base-renderer-used': 'Standard - Renderer wird genutzt',
  'app-editor-custom-renderer-used-description': 'Der Standard - Renderer fur dieses Projekt wurde durch einen Custom - Renderer ersetzt. Dies kann nur durch einen Designer, der die nötigen Berechtigungene besitzt, geschehen.',
  'app-editor-reset-renderer': 'Standard - Renderer nutzen',
  'app-editor-reset-renderer-description': 'Durch den Wechsel zurück zum Standard - Renderer, gehen alle Inhaltlichen und Designänderungen verloren. Es kann jederzeit wieder auf einem Custom - Renderer gewechselt werden.',
  'app-editor-render-update-publish-notice': 'Nach einem Update oder Reset des Renderers müssen alle Datensätze neu veröffentlich werden.',
  'app-editor-init': 'wird initialisiert',
  'app-editor-content-loading': 'Inhalt wird aktualisiert',
  'app-editor-permissions-read': 'Datensätze anzeigen',
  'app-editor-permissions-create': 'Datensatz erstellen',
  'app-editor-permissions-edit': 'Datensatz bearbeiten',
  'app-editor-permissions-remove': 'Datensatz entfernen',
  'app-editor-permissions-history': 'Historie anzeigen',
  'app-html-permissions-app': 'Design aktualisieren',
  'app-editor-pdf-print-settings': 'PDF - und Druckeinstellungen',
  'app-editor-pdf-print-settings-description': 'Dateinamen',
  'app-editor-html-settings': 'HTML Einstellungen',
  'app-editor-html-settings-description': 'Titel, Beschreibung, Dateinamen',
  'app-editor-base-settings': 'Datensatz',
  'app-editor-base-settings-description': 'Tags, Beschreibung, Layout, Publish Status',
  'app-editor-variant-group-base': 'Allgemein',
  'app-editor-variant-group-pdf': 'Nur für PDF',
  'app-editor-variant-group-behaviour': 'Verhalten',
  'app-editor-pdf-versioning': 'Versionierung',
  'app-editor-pdf-filename': 'Dateiname für PDF',
  'app-editor-pdf-versioning-description': 'Der Dateiname wird immer die aktuelle Version des Datensatzes beinhalten. Ohne diese Einstellung wird die PDF bei jedem publish überschrieben',
  'app-editor-design-settings': 'Live-Vorschau',
  'app-editor-design-settings-description': 'Cookies',
  'app-editor-design-reset-cookies-description': 'Bestimmte Funktionen sind abhängig von Cookies. Um diese zu testen, können hier die Cookies in der Vorschau entfernt werden.',
  'app-editor-design-reset-cookies': 'Cookies entfernen',
  'app-editor-layout-position': 'Verhalten im Layout',
  'app-editor-layout-no-position': 'Keine spezifische Position ausgewählt',
  'app-editor-layout-position-first': 'Immer an erster Stelle anzeigen',
  'app-editor-layout-position-last': 'Immer an letzter Stelle anzeigen',
  'app-editor-no-modules': 'Keine passenden Module gefunden',
  'app-editor-responsive-image-description': 'Das Hauptbild wird für die mobile Variante genutzt. Es kann für Tablet, Desktop und Widescreen jeweils ein passenden Bild ausgewaehlt werden.',
  'app-editor-select-confirm': 'Auswahl bestätigen (%s)',
  'app-editor-select-cancel': 'abbrechen',
  'app-editor-select-data-title': 'Datensatz auswählen',
  'app-editor-select-data-title-multiple': 'Datensätze auswählen',
  'app-editor-select-partial-title': 'Partial auswählen',
  'app-editor-select-partial-title-multiple': 'Partials auswählen',
  'app-editor-select-layout-title': 'Layout auswählen',
  'app-editor-select-layout-title-multiple': 'Layouts auswählen',
  'app-editor-select-button-title': 'Datensatz auswählen',
  'app-editor-select-button-title-multiple': 'Datensätze auswählen',
  'app-editor-select-open': 'Datensatz öffnen',
  'app-editor-select-selected-overview': '%s ausgewählt',
  'app-editor-select-no-select': 'Kein Datensatz ausgewahlt',
  'app-editor-contact-module-category-textblock': 'Kategorie Text',
  'app-editor-menu-categories': 'Kategorien',
  'app-editor-menu-title': 'Titel',
  'app-editor-menu-items': 'Gerichte',
  'app-editor-menu-allergens': 'Allergene',
  'app-editor-menu-properties': 'Eigenschaften',
  'app-editor-menu-details': 'Beschreibung & Preis',
  'app-editor-new': 'Datensatz anlegen',
  'app-editor-target': 'Anwendung',
  'app-editor-target-description': 'Je nach Anwendungsfall stehen unterschiedliche Module zur Verfügung.',
  'app-editor-target-browser': 'Browser',
  'app-editor-target-browser-description': 'Inhalte werden im Browser dargestellt.',
  'app-editor-target-mail': 'E-Mail',
  'app-editor-target-mail-description': 'Inhalte werden via E-Mail versendet.',
  'app-editor-clone-of': 'Kopie von %s',
  'MODULE_VIEW_MODES': 'Anzeigemodus',
  'gluten-containing-grains': 'Glutenhaltiges Getreide',
  'crustaceans': 'Krebstiere',
  'eggs': 'Eier',
  'fish': 'Fische',
  'peanuts': 'Erdnüsse',
  'soybeans': 'Sojabohnen',
  'milk': 'Milch',
  'nuts': 'Nüsse',
  'celery': 'Sellerie',
  'mustard': 'Senf',
  'sesame-seeds': 'Sesamsamen',
  'sulfur-dioxide-and-sulphites': 'Schwefeldioxid und Sulfite',
  'lupin': 'Lupinen',
  'molluscs': 'Weichtiere',
  'vegetarian': 'Vegetarisch',
  'organic': 'Biologisch',
  'gluten-free': 'Glutenfrei',
  'lactose-free': 'Laktosefrei',
  'gmo-free': 'GMO-frei',
  'fair-trade': 'Fairer Handel',
  'paleo': 'Paleo',
  'low-carb/ketogenic': 'Low-Carb/Ketogen',
  'sugar-free': 'Zuckerfrei',
  'raw-food': 'Rohkost',
  'whole30': 'Whole30',
  'clean-label': 'Clean Label',
  'vegan': 'Vegan',
  'kosher': 'Kosher',
  'halal': 'Halal',
};