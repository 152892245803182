import GenericModel from '@interfaces/GenericModel.interface';
import Models, { ModelDeclarationExtended, ModelHooks, ModelOptions } from '@libs/Models.class';
import ListLink from '@models/ListLink.model';
import ListString from '@models/ListString.model';
import Module from '@models/Module.model';

export default interface TextModule extends GenericModel {
  type: 'text-module'
  headline: string
  subline: string
  intro: string
  text: string[]
  list: ListString
  links: ListLink
  variant: string[]
}

const declaration: ModelDeclarationExtended<TextModule, Module> = {
  headline: { type: 'string' },
  subline: { type: 'string' },
  intro: { type: 'string' },
  text: { type: 'array', contains: { type: 'string' } },
  variant: { type: 'array', contains: { type: 'string' } },
  list: { model: 'list-string' },
  links: { model: 'list-link' },
};
const functions: ModelHooks<TextModule> = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('text-module', declaration, functions, options);