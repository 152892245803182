var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: [_vm.$enviroment], attrs: { id: "app" } },
    [
      _c("router-view", { key: `module-${_vm.$route.params.link}` }),
      _vm._v(" "),
      _c("portal-target", { attrs: { name: "overlay", multiple: "" } }),
      _vm._v(" "),
      _c("portal-target", { attrs: { name: "util", multiple: "" } }),
      _vm._v(" "),
      _vm.loading ? _c("loading-partial") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }