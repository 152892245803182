// import PartialModel from '@libs/lila-model';

// export default class SelectedFileModel extends PartialModel {

//   id?: string = '';

//   filename?: string = '';

//   src?: string = '';

//   thumb?: string = '';

// }

import Models, { ModelDeclaration, ModelFunctions } from '@libs/Models.class';

export default interface SelectedFile {
  id: string
  filename: string
  src: string
  thumb: string
}

const declaration: ModelDeclaration<SelectedFile> = {
  id: { type: 'string' },
  filename: { type: 'string' },
  src: { type: 'string' },
  thumb: { type: 'string' },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};

Models.register('selected-file', declaration, functions);