import { UnifiedRouteGuard } from '@libs/lila-auth';

const loadScreen = (screen: string) => () => import(
  /* webpackChunkName: "app-hosting" */
  `./screens/${screen}`
).then((m) => m.default);

export default [
  {
    path: '/:company/:project/a/hosting',
    name: 'app-hosting-project-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home.screen.vue'),
    meta: {
      animationGroup: 'hosting',
      app: 'hosting',
      parent: true,
    },
    children: [
      {
        path: 'domains',
        component: loadScreen('domains.screen.vue'),
        meta: {
          sidescreen: true,
          defaultRoute: 'app-hosting-project-domains',
          app: 'hosting',
        },
        children: [
          {
            path: 'add',
            name: 'app-hosting-project-domains-single-add',
            component: loadScreen('domains-single.screen.vue'),
            meta: {
              sidescreen: true,
              name: 'app-hosting-project-domains-single-add',
              app: 'hosting',
            },
          },
          {
            path: ':id',
            name: 'app-hosting-project-domains-single-edit',
            component: loadScreen('domains-single.screen.vue'),
            meta: {
              sidescreen: true,
              name: 'app-hosting-project-domains-single-edit',
              app: 'hosting',
            },
          },
          {
            path: '',
            name: 'app-hosting-project-domains',
            component: loadScreen('domains-home.screen.vue'),
            meta: {
              sidescreen: true,
              name: 'app-hosting-project-domains',
              app: 'hosting',
            },
          },
        ],
      },
      {
        path: 'settings',
        component: loadScreen('settings.screen.vue'),
        meta: {
          sidescreen: true,
          defaultRoute: 'app-hosting-project-settings-overview',
          app: 'hosting',
        },
        children: [
          {
            path: 'base',
            name: 'app-hosting-project-settings-base',
            component: loadScreen('settings-base.screen.vue'),
            meta: {
              sidescreen: true,
              name: 'app-hosting-project-settings-base',
              app: 'hosting',
            },
          },
          {
            path: 'main',
            name: 'app-hosting-project-settings-main',
            component: loadScreen('settings-main.screen.vue'),
            meta: {
              sidescreen: true,
              name: 'app-hosting-project-settings-main',
              app: 'hosting',
            },
          },
          {
            path: 'nginx',
            name: 'app-hosting-project-settings-nginx',
            component: loadScreen('settings-nginx.screen.vue'),
            meta: {
              sidescreen: true,
              name: 'app-hosting-project-settings-nginx',
              app: 'hosting',
            },
          },
          {
            path: '',
            name: 'app-hosting-project-settings-overview',
            component: loadScreen('settings-overview.screen.vue'),
            meta: {
              sidescreen: true,
              name: 'app-hosting-project-settings-overview',
              app: 'hosting',
            },
          },
        ],
      },
    ],
  },
];
