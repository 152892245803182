// import PartialModel from '@libs/lila-model';
// import LinkPartialModel from './Link.model';

// export default class FooterContactModel extends PartialModel {

//   title = '';

//   link = LinkPartialModel;

// }
import Models, { ModelDeclaration, ModelFunctions } from '@libs/Models.class';
import Link from './Link.model';

export default interface FooterContact {
  title: string
  link: Link
}

const declaration: ModelDeclaration<FooterContact> = {
  title: { type: 'string' },
  link: { model: 'link' },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};

Models.register('footer-contact', declaration, functions);