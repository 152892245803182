// import modules from '@apps/editor/editor-modules';
// import ListLinkModel from '@models/ListLink.model';
// import ListStringModel from '@models/ListString.model';
// import ModuleModel from '@models/Module.model';
// import PicturePartialModel from '@models/PicturePartialModel';
// import TextblockModel from '@models/Textblock.model';

// export default class PictureAndTextModuleModel extends ModuleModel {

//   type: keyof typeof modules = 'pictureandtext-module';

//   uuid = true;

//   picture = PicturePartialModel;

//   textblock = TextblockModel;

//   title = TextblockModel;

//   list = ListStringModel;

//   links = ListLinkModel;

//   // eslint-disable-next-line class-methods-use-this
//   legacy = (data: any) => {

//     console.info(JSON.parse(JSON.stringify(data)));

//     if (data.textblock?.links && !data.textblock.links?.title && !data.textblock.link?.value) {

//       data.links = {
//         title: '',
//         value: data.textblock.links,
//       };
//       // delete data.textblock.links;

//     }

//     if (data.textblock?.list && !data.textblock.list?.title && !data.textblock.list?.value) {

//       data.list = {
//         title: '',
//         value: data.textblock.list,
//       };
//       // delete data.textblock.list;

//     }

//     console.info(JSON.parse(JSON.stringify(data)));
//     return data;

//   };

// }


import Models, { ModelDeclarationExtended } from '@libs/Models.class';
import ListLink from '@models/ListLink.model';
import ListString from '@models/ListString.model';
import Module from '@models/Module.model';
import PicturePartial from '@models/PicturePartial.model';
import Textblock from '@models/Textblock.model';

export default interface PictureAndTextModule extends Module {
  picture: PicturePartial
  textblock: Textblock
  title: Textblock
  list: ListString
  links: ListLink
}

const declaration: ModelDeclarationExtended<PictureAndTextModule, Module> = {
  picture: { model: 'picture-partial' },
  textblock: { model: 'textblock' },
  title: { model: 'textblock' },
  list: { model: 'list-string' },
  links: { model: 'list-link' },
};
const options = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('pictureandtext-module', declaration, {}, options);