// import PartialModel from '@libs/lila-model';
// import log from 'loglevel';
// import LinkPartialModel from './Link.model';

// export default class ListLinkModel extends PartialModel {

//   title: string = '';

//   value = [LinkPartialModel];

// }

import Models, { ModelDeclaration, ModelFunctions } from '@libs/Models.class';
import Link from './Link.model';

export default interface ListLink {
  title: string
  value: Link[]
}

const declaration: ModelDeclaration<ListLink> = {
  title: { type: 'string' },
  value: { type: 'array', contains: { model: 'link' } },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};

Models.register('list-link', declaration, functions);
