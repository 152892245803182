import { IDBPDatabase } from 'idb';

export default (db: IDBPDatabase<any>) => {

  if (db.objectStoreNames.contains('trackers')) {

    db.deleteObjectStore('trackers');

  }

  const store = db.createObjectStore('trackers', { autoIncrement: true });

  store.createIndex('startTime', 'startTime');

};
