import { UnifiedRouteGuard } from '@libs/lila-auth';

const loadScreen = (screen: string) => () => import(
  /* webpackChunkName: "app-appstore" */
  `./screens/${screen}`
).then((m) => m.default);

export default [
  {
    path: '/:company/a/appstore',
    name: 'app-appstore-company-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home.screen.vue'),
    meta: {
      parent: true,
      app: 'appstore',
      rememberSettings: true,
    },
    children: [
      {
        path: '/:company/a/appstore/add',
        name: 'app-appstore-company-single-add',
        component: loadScreen('single.screen.vue'),
        meta: {
          sidescreen: true,
          name: 'company-settings-sidescreen',
          defaultRoute: 'app-appstore-company-single-add',
          app: 'appstore',
        },
      },
      {
        path: '/:company/a/appstore/:app',
        name: 'app-appstore-company-single',
        component: loadScreen('single.screen.vue'),
        meta: {
          sidescreen: true,
          name: 'company-settings-sidescreen',
          defaultRoute: 'app-appstore-company-single',
          app: 'appstore',
        },
      },
    ],
  },
  {
    path: '/:company/:project/a/appstore',
    name: 'app-appstore-project-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home.screen.vue'),
    meta: {
      parent: true,
      app: 'appstore',
      rememberSettings: true,
    },
    children: [
      {
        path: '/:company/:project/a/appstore/add',
        name: 'app-appstore-project-single-add',
        component: loadScreen('single.screen.vue'),
        meta: {
          sidescreen: true,
          name: 'company-settings-sidescreen',
          defaultRoute: 'app-appstore-project-single-add',
          app: 'appstore',
        },
      },
      {
        path: '/:company/:project/a/appstore/:app',
        name: 'app-appstore-project-single',
        component: loadScreen('single.screen.vue'),
        meta: {
          sidescreen: true,
          name: 'company-settings-sidescreen',
          defaultRoute: 'app-appstore-project-single',
          app: 'appstore',
        },
      },
    ],
  },
];
