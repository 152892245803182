// import PartialModel from '@libs/lila-model';

// export default class VideoSourceModel extends PartialModel {

//     media: string = '';

//     source: string = '';

// }

import Models, { ModelDeclaration, ModelFunctions } from '@libs/Models.class';

export default interface VideoSource {
  media: string
  source: string
}

const declaration: ModelDeclaration<VideoSource> = {
  media: { type: 'string' },
  source: { type: 'string' },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};

Models.register('video-source', declaration, functions);