import { UnifiedRouteGuard } from '@libs/lila-auth';

const loadScreen = (screen: string) => () => import(
  /* webpackChunkName: "app-learn" */
  `./screens/${screen}`
).then((m) => m.default);

export default [
  {
    path: '/:company/:project/a/learn/:site?',
    name: 'app-learn-project-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home.screen.vue'),
    meta: {
      app: 'learn',
    },
  },
  {
    path: '/:company/:project/a/learn/c/:link+',
    name: 'app-learn-project-content',
    pathToRegexpOptions: { start: true, end: false },
    component: loadScreen('single.screen.vue'),
    meta: {
      app: 'learn',
    },
  },
];
