// import PartialModel from '@libs/lila-model';

// export default class SiteSettings extends PartialModel {

//   tags: string[] = [];

//   id = '';

//   _id = '';

//   state = '';

//   description = '';

//   language = '';

//   partial = false;

//   layout = false;

// }

import Models, { ModelDeclaration } from '@libs/Models.class';

export default interface EditorSiteSettings {
  id: string
  _id: string
  state: string
  description: string
  language: string
  tags: string[]
  partial: boolean
  layout: boolean
}

const declaration: ModelDeclaration<EditorSiteSettings> = {
  id: { type: 'string' },
  _id: { type: 'string' },
  state: { type: 'string' },
  description: { type: 'string' },
  language: { type: 'string' },
  partial: { type: 'boolean', default: false },
  layout: { type: 'boolean', default: false },
  tags: { type: 'array', contains: { type: 'string' } },
};

Models.register('editor-site-settings', declaration);