export default class Endpoints {

  endpoints: Record<string, Endpoint['url']> = {};

  addEndpoints(endpoints: Endpoint[]) {

    if (!endpoints) return;

    endpoints.forEach((endpoint) => {

      const url = endpoint.context
        ? `${window.location.protocol}//${window.location.host}${endpoint.url}`
        : endpoint.url;

      this.endpoints[endpoint.name] = url;

    });

  }

}
