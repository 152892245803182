import MainStore, { state } from '@store/main.store';
import GenericStore from '@interfaces/GenericStore.interface';
import { vue } from '@libs/lila-partial';
import StudioSDK from '@libs/StudioSDK';
import SettingsStoreState from './SettingsStoreState.interface';

const SettingsStore: GenericStore<SettingsStoreState> = {
  namespaced: true,
  strict: true,

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },


  state: {
    settings: {},
  },

  mutations: {

    update(localState, setting: { [key: string]: string }) {

      Object.keys(setting).map((key) => {

        const value = setting[key];

        vue.set(localState.settings, key, value);

        return setting;

      });

    },

    remove(localState, key: string) {

      vue.delete(localState.settings, key);

    },

    removeMulti(localState, keys: string[]) {

      keys.map((key) => {

        vue.delete(localState.settings, key);

        return key;

      });

    },


    clean(localState) {

      localState.settings = {};

    },

    init(localState, settings: any) {

      localState.settings = settings;

    }

  },

  actions: {

    sync(store) {

      const sdk = new StudioSDK('projects', SettingsStore.sdkOptions());

      return sdk.me.settings.update( store.state.settings);

    },

  },

};

export default SettingsStore;
