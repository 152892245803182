import { Call } from '@libs/lila-call';
import { Auth } from '@libs/lila-auth';
import MainStore from '@store/main.store';
import ContentStoreState from '@store/ContentStoreState';
import { Editor } from '@lilaquadrat/studio/lib/interfaces';
import GenericStore from '@interfaces/GenericStore.interface';

const ContentStore: GenericStore<ContentStoreState> = {
  namespaced: true,
  strict: true,

  state: {
    cache: {},
  },

  mutations: {

    addCache(state, data) {

      state.cache[data.cacheKey] = data.cache;

    },

  },

  actions: {

    get(store, data: {id: string, category?: string[], type?: string, latest?: boolean, bypassCache?: boolean}) {

      const cacheKey = data.latest ? ['latest', data.type, ...data.category].join('-') : data.id;

      if (!data?.bypassCache) {

        if (store.state.cache[cacheKey]) {

          return new Promise((resolve) => resolve(store.state.cache[cacheKey]));

        }

      }

      const urlArray = ['content', data.type, data.latest ? 'latest' : null, data.id];
      const call = new Call<Editor>('editor', MainStore.getters.endpoints);
      const query: Record<string, string|string[]|boolean> = {};

      if (data.category) query.category = data.category;

      return call.get('api', `/${urlArray.filter((single) => single).join('/')}`, query, Auth.getAuthHeader())
        .then((single) => {

          store.commit(
            'addCache',
            {
              data,
              cache: single.r,
              cacheKey,
            },
          );

          return single.r;

        });


    },

  },

};

export default ContentStore;
