// import PartialModel from '@libs/lila-model';
// import LinkPartialModel from './Link.model';

// export default class LinkGroupElementModel extends PartialModel {

//   text: string = '';

//   link: string = '';

//   classes: string[] = [];

//   attributes: string[] = [];

//   external: boolean = false;

//   links = [LinkPartialModel];

//   icon: string = '';

//   legacy = (data: any) => {

//     if (data.link?.link !== undefined && typeof data.link?.link !== 'function') {

//       data.text = data.link.text;
//       data.link = data.link.link.link;
//       data.classes = data.link.classes;
//       data.attributes = data.link.attributes;

//     }

//     return data;

//   }

// }

import Models, { ModelDeclaration, ModelFunctions } from '@libs/Models.class';
import Link from './Link.model';

export default interface LinkGroupElement {
  text: string
  link: string
  icon: string
  classes: string[]
  attributes: string[]
  external: boolean
  links: Link[]
}

const declaration: ModelDeclaration<LinkGroupElement> = {
  text: { type: 'string' },
  link: { type: 'string' },
  icon: { type: 'string' },
  links: { type: 'array', contains: { model: 'link' } },
  classes: { type: 'array', contains: { type: 'string' } },
  attributes: { type: 'array', contains: { type: 'string' } },
  external: { type: 'boolean', default: false },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};

Models.register('linkgroup-element', declaration, functions);
