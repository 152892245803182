// import PartialModel from '@libs/lila-model';
// import ListLinkModel from './ListLink.model';
// import ListStringModel from './ListString.model';
// import TextblockModel from './Textblock.model';

// export default class AccordionElementModel extends PartialModel {
//   textblock = TextblockModel;

//   list = ListStringModel;

//   links = ListLinkModel;
// }

import Models, { ModelDeclaration, ModelFunctions } from '@libs/Models.class';
import ListString from './ListString.model';
import ListLink from './ListLink.model';
import Textblock from './Textblock.model';

export default interface AccordionElement {
  textblock: Textblock
  list: ListString
  links: ListLink
}

const declaration: ModelDeclaration<AccordionElement> = {
  textblock: { model: 'textblock' },
  list: { model: 'list-string' },
  links: { model: 'list-link' },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};

Models.register('accordion-element', declaration, functions);