// import PartialModel from '@libs/lila-model';

// export default class MeModel extends PartialModel {

//   prename: string = '';

//   lastname: string = '';

//   termsAccepted: boolean = false;

//   // email: string = '';

// }

import Models, { ModelDeclaration } from '@libs/Models.class';

export default interface Me {
  prename: string
  lastname: string
  termsAccepted: boolean
}

const declaration: ModelDeclaration<Me> = {
  prename: { type: 'string' },
  lastname: { type: 'string' },
  termsAccepted: { type: 'boolean', default: false },
};

Models.register('me', declaration);
