export default {
  'app-publishadmin': 'Publish Admin',
  'app-publishadmin-add-title': 'Publishadmin erstellen',
  'app-publishadmin-title-placeholder': 'Platzhalter',
  'app-publishadmin-email-label': 'E-Mail',
  'app-publishadmin-displayName-label': 'Displayname',
  'app-publishadmin-select-data-title-multiple': 'Projekte auswählen',
  'app-publishadmin-select-button-title-multiple': 'Projekte auswählen',
  'app-publishadmin-select-confirm': 'Auswahl bestätigen (%s)',
  'app-publishadmin-select-selected-overview': '%s ausgewählt',
  'app-publishadmin-select-cancel': 'abbrechen',
  'app-publishadmin-edit-button': 'Änderungen speichern',
  'app-publishadmin-create-button': 'speichern',
  'app-publishadmin-remove-button': 'Entfernen',
};
