// import ModuleModel from '@models/Module.model';
// import TextblockModel from '@models/Textblock.model';
// import modules from '@apps/editor/editor-modules';
// import PricesElementModel from '@models/PricesElement.model';

// export default class PricesModuleModel extends ModuleModel {

//   type: keyof typeof modules = 'prices-module';

//   uuid = true;

//   textblock = TextblockModel;

//   elements = [PricesElementModel];

// }

import Models, { ModelDeclarationExtended, ModelFunctions, ModelOptions } from '@libs/Models.class';
import Module from '@models/Module.model';
import PricesElement from '@models/PricesElement.model';
import Textblock from '@models/Textblock.model';

export default interface PricesModule extends Module {
  textblock: Textblock
  elements: PricesElement[]
}

const declaration: ModelDeclarationExtended<PricesModule, Module> = {
  textblock: { model: 'textblock' },
  elements: { type: 'array', contains: { model: 'prices-element' } },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('prices-module', declaration, functions, options);