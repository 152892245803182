// import PartialModel from '@libs/lila-model';
// import LinkPartialModel from './Link.model';

// export default class FooterSitemapModel extends PartialModel {

//   title = '';

//   elements = [LinkPartialModel];

// }

import Models, { ModelDeclaration, ModelFunctions } from '@libs/Models.class';
import Link from './Link.model';

export default interface FooterSitemap {
  title: string
  elements: Link[]
}

const declaration: ModelDeclaration<FooterSitemap> = {
  title: { type: 'string' },
  elements: { type: 'array', contains: { model: 'link' } },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};

Models.register('footer-sitemap', declaration, functions);