// import modules from '@apps/editor/editor-modules';
// import ModuleModel from '@models/Module.model';

// export default class QuellcodeModuleModel extends ModuleModel {

//   type: keyof typeof modules = 'quellcode-module';

//   uuid = true;

//   headline: string = '';

//   subline: string = '';

//   intro: string = '';

//   code: string = '';

//   text: string[] = [];

// }

import Models, { ModelDeclarationExtended, ModelFunctions, ModelOptions } from '@libs/Models.class';
import Module from '@models/Module.model';

export default interface QuellcodeModule extends Module {
  headline: string
  subline: string
  intro: string
  code: string
  text: string[]
}


const declaration: ModelDeclarationExtended<QuellcodeModule, Module> = {
  headline: { type: 'string' },
  subline: { type: 'string' },
  intro: { type: 'string' },
  code: { type: 'string' },
  text: { type: 'array', contains: { type: 'string' } },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('quellcode-module', declaration, functions, options);