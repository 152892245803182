import { Media } from '@lilaquadrat/interfaces';

export default (file: Media, cdn: string) => {

  file.view = {};

  file.view.width = (400 * 200) / 400;
  file.view.padding = (400 / 400) * 100;

  if (file.image || file.video) {

    file.view.width = ((file.info?.width || 200) * 200) / (file.info?.height || 200);
    file.view.padding = ((file.info?.height || 200) / (file.info?.width || 200)) * 100;
    file.view.src = `${cdn}/${file.company}/${file.project}/${file.id}`;
    file.view.thumb = `${cdn}/${file.company}/${file.project}/250_${file.id}`;
    file.view.loading = true;

    if (file.video) {

      file.view.src = `${cdn}/${file.company}/${file.project}/${file.id}`;

    }

  }

  file.download = `${cdn}/${file.company}/${file.project}/${file.id}`;
  file.basePath = `${cdn}/${file.company}/${file.project}/`;

};
