import LoadElements from '@mixins/loadElements';
import InstallableApp from '../../interfaces/InstallableApp.interface';
import routes from './routes';
import translations from './translations/translations';

import './models/ftp.model';
import './models/internal.model';
import './models/pdf.model';
import './models/ProjectPublish.model';
import './models/publish.model';
import './models/webhook.model';
import './models/email.model';

LoadElements.appComponents(require.context('./partials', true, /[a-z]\w+\.partial\.(ts|vue)$/, 'lazy'), 'partials', 'publish');
LoadElements.extensions(require.context('./extensions', true, /[a-z-]\w+\.extension\.(ts|vue)$/, 'lazy'), 'publish');

const app: InstallableApp = {
  name: 'publish',
  publisher: 'lilaquadrat',
  translations,
  routes,
  store: 'AppPublish',
};

export default app;
